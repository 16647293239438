const ISCLICKEDDELETEWINDOW = "ISCLICKEDDELETEWINDOW";

const isClickedDeleteWindow = (check) => {
  return {
    type: ISCLICKEDDELETEWINDOW,
    payload: check,
  };
};

export const isClickedDeleteWindowAsync = (check) => (dispatch) => {
  dispatch(isClickedDeleteWindow(check));
};
