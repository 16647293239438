import React, { useEffect, useState } from "react";
import "./SupportOasis.css";
import ClickSound from "../../../../MusicAndSounds/ClickSound";
import supportOasisButtonSound from "../../../../../Audio/supportOasisButton.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - RoomGameNumbers
// style - RoomGameNumbers.css

function SupportOasis({
  roomData,
  oasisHelpCondition,
  isOasisHelpClickedAsync,
}) {
  // roomData is coming from parent component - RoomGameNumbers
  const [disableLink, thisRoomWhosTurn, indexOfPlayer] = roomData;

  const [activeDoubleBtn, setActiveDoubleBtn] = useState("SupportOasisBtn");
  const [activeExtraBtn, setActiveExtraBtn] = useState("SupportOasisBtn");
  const [activeMinusBtn, setActiveMinusBtn] = useState("SupportOasisBtn");
  const [activeAddedBtn, setActiveAddedBtn] = useState("SupportOasisBtn");
  const [activeBonusBtn, setActiveBonusBtn] = useState("SupportOasisBtn");

  const [oasisDouble, setOasisDouble] = useState(null);
  const [oasisExtra, setOasisExtra] = useState(null);
  const [oasisMinus, setOasisMinus] = useState(null);
  const [oasisAdded, setOasisAdded] = useState(null);
  const [oasisBonus, setOasisBonus] = useState(null);
  const [oasisMatched, setOasisMatched] = useState(null);

  const [showDouble, setShowDouble] = useState("");
  const [showExtra, setShowExtra] = useState("");
  const [showMinus, setShowMinus] = useState("");
  const [showAdded, setShowAdded] = useState("");
  const [showBonus, setShowBonus] = useState("");

  const [helpSign, setHelpSign] = useState("+");

  useEffect(() => {
    const indexOfOasis = "oasis" + (indexOfPlayer + 1);

    if (
      thisRoomWhosTurn !== undefined &&
      indexOfOasis !== "oasisNaN" &&
      indexOfOasis !== "oasis0"
    ) {
      setOasisDouble(thisRoomWhosTurn.helpOasis[indexOfOasis].double);

      setOasisExtra(thisRoomWhosTurn.helpOasis[indexOfOasis].extra);

      setOasisMinus(thisRoomWhosTurn.helpOasis[indexOfOasis].minus);

      setOasisAdded(thisRoomWhosTurn.helpOasis[indexOfOasis].added);

      setOasisBonus(thisRoomWhosTurn.helpOasis[indexOfOasis].bonus);

      setOasisMatched(thisRoomWhosTurn.helpOasis[indexOfOasis].matched);
    } else {
      setOasisDouble(null);

      setOasisExtra(null);

      setOasisMinus(null);

      setOasisAdded(null);

      setOasisBonus(null);

      setOasisMatched(null);
    }
  }, [indexOfPlayer, thisRoomWhosTurn]);

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      const onGoingNumber = thisRoomWhosTurn.currentNumber;

      if (onGoingNumber < 73) {
        setShowDouble(disableLink);
        setShowExtra(disableLink);
        setShowMinus("hidden");
        setShowAdded(disableLink);
        setShowBonus("hidden");
      } else if (onGoingNumber >= 73 && onGoingNumber < 161) {
        setShowDouble("hidden");
        setShowExtra(disableLink);
        setShowMinus("hidden");
        setShowAdded(disableLink);
        setShowBonus(disableLink);
      } else if (onGoingNumber >= 161 && onGoingNumber < 198) {
        setShowDouble("hidden");
        setShowExtra("hidden");
        setShowMinus(disableLink);
        setShowAdded(disableLink);
        setShowBonus(disableLink);
      } else {
        setShowDouble("hidden");
        setShowExtra("hidden");
        setShowMinus("hidden");
        setShowAdded("hidden");
        setShowBonus("hidden");
      }
    } else {
      setShowDouble("hidden");
      setShowExtra("hidden");
      setShowMinus("hidden");
      setShowAdded("hidden");
      setShowBonus("hidden");
    }
  }, [thisRoomWhosTurn, disableLink]);

  useEffect(() => {
    if (oasisHelpCondition === "plus") {
      setHelpSign("+");

      setActiveDoubleBtn("SupportOasisBtn");
      setActiveExtraBtn("SupportOasisBtn");
      setActiveMinusBtn("SupportOasisBtn");
      setActiveAddedBtn("SupportOasisBtn");
      setActiveBonusBtn("SupportOasisBtn");
    } else if (oasisHelpCondition === "double") {
      setHelpSign("2 X");

      setActiveDoubleBtn("SupportOasisBtn OasisBtnActive");
      setActiveExtraBtn("SupportOasisBtn");
      setActiveMinusBtn("SupportOasisBtn");
      setActiveAddedBtn("SupportOasisBtn");
      setActiveBonusBtn("SupportOasisBtn");
    } else if (oasisHelpCondition === "extra") {
      setHelpSign("+ 7");

      setActiveDoubleBtn("SupportOasisBtn");
      setActiveExtraBtn("SupportOasisBtn OasisBtnActive");
      setActiveMinusBtn("SupportOasisBtn");
      setActiveAddedBtn("SupportOasisBtn");
      setActiveBonusBtn("SupportOasisBtn");
    } else if (oasisHelpCondition === "minus") {
      setHelpSign("- 2 X");

      setActiveDoubleBtn("SupportOasisBtn");
      setActiveExtraBtn("SupportOasisBtn");
      setActiveMinusBtn("SupportOasisBtn OasisBtnActive");
      setActiveAddedBtn("SupportOasisBtn");
      setActiveBonusBtn("SupportOasisBtn");
    } else if (oasisHelpCondition === "added") {
      setHelpSign("+ 4");

      setActiveDoubleBtn("SupportOasisBtn");
      setActiveExtraBtn("SupportOasisBtn");
      setActiveMinusBtn("SupportOasisBtn");
      setActiveAddedBtn("SupportOasisBtn OasisBtnActive");
      setActiveBonusBtn("SupportOasisBtn");
    } else if (oasisHelpCondition === "bonus") {
      setHelpSign("+ 1");

      setActiveDoubleBtn("SupportOasisBtn");
      setActiveExtraBtn("SupportOasisBtn");
      setActiveMinusBtn("SupportOasisBtn");
      setActiveAddedBtn("SupportOasisBtn");
      setActiveBonusBtn("SupportOasisBtn OasisBtnActive");
    } else {
      setHelpSign("+");

      setActiveDoubleBtn("SupportOasisBtn");
      setActiveExtraBtn("SupportOasisBtn");
      setActiveMinusBtn("SupportOasisBtn");
      setActiveAddedBtn("SupportOasisBtn");
      setActiveBonusBtn("SupportOasisBtn");
    }
  }, [oasisHelpCondition]);

  const handleOasisHelp = (e) => {
    //play click
    ClickSound(supportOasisButtonSound);

    const clicked = e.target.name;

    if (clicked === "double" && oasisDouble + oasisMatched > 0) {
      if (activeDoubleBtn === "SupportOasisBtn") {
        isOasisHelpClickedAsync("double");
      } else {
        isOasisHelpClickedAsync("plus");
      }
    } else if (clicked === "extra" && oasisExtra + oasisMatched > 0) {
      if (activeExtraBtn === "SupportOasisBtn") {
        isOasisHelpClickedAsync("extra");
      } else {
        isOasisHelpClickedAsync("plus");
      }
    } else if (clicked === "minus" && oasisMinus + oasisMatched > 0) {
      if (activeMinusBtn === "SupportOasisBtn") {
        isOasisHelpClickedAsync("minus");
      } else {
        isOasisHelpClickedAsync("plus");
      }
    } else if (clicked === "added" && oasisAdded + oasisMatched > 0) {
      if (activeAddedBtn === "SupportOasisBtn") {
        isOasisHelpClickedAsync("added");
      } else {
        isOasisHelpClickedAsync("plus");
      }
    } else if (clicked === "bonus" && oasisBonus + oasisMatched > 0) {
      if (activeBonusBtn === "SupportOasisBtn") {
        isOasisHelpClickedAsync("bonus");
      } else {
        isOasisHelpClickedAsync("plus");
      }
    }
  };

  return (
    // className "disableLink" are "RoomGameNumbersHelp" , "RoomGameNumbersHelp disable-link"
    <div
      className="SupportOasis"
      data-oaishelp={helpSign}
      data-oasismatched={oasisMatched}
    >
      {/* double button */}
      <div className={showDouble} id="firstOasisBtn" data={`X ${oasisDouble}`}>
        <button
          className={activeDoubleBtn}
          name="double"
          title="Double your number"
          onClick={handleOasisHelp}
        >
          {" "}
          Double
        </button>

        <div id="firstOasis">
          <h4>Double your number</h4>
        </div>
      </div>
      {/* double button */}

      {/* bonus button */}
      <div className={showBonus} id="fifthOasisBtn" data={`X ${oasisBonus}`}>
        <button
          className={activeBonusBtn}
          name="bonus"
          title="Add extra 1 to your number"
          onClick={handleOasisHelp}
        >
          {" "}
          Add extra 1
        </button>

        <div id="fifthOasis">
          <h4>Add extra 1 to your number</h4>
        </div>
      </div>
      {/* bonus button */}

      {/* added button */}
      <div className={showAdded} id="forthOasisBtn" data={`X ${oasisAdded}`}>
        <button
          className={activeAddedBtn}
          name="added"
          title="Add extra 4 to your number"
          onClick={handleOasisHelp}
        >
          {" "}
          Add extra 4
        </button>

        <div id="forthOasis">
          <h4>Add extra 4 to your number</h4>
        </div>
      </div>
      {/* added button */}

      {/* extra button */}
      <div className={showExtra} id="secondOasisBtn" data={`X ${oasisExtra}`}>
        <button
          className={activeExtraBtn}
          name="extra"
          title="Add extra 7 to your number"
          onClick={handleOasisHelp}
        >
          {" "}
          Add extra 7
        </button>

        <div id="secondOasis">
          <h4>Add extra 7 to your number</h4>
        </div>
      </div>
      {/* extra button */}

      {/* minus button */}
      <div className={showMinus} id="thirdOasisBtn" data={`X ${oasisMinus}`}>
        <button
          className={activeMinusBtn}
          name="minus"
          title="Minus your number"
          onClick={handleOasisHelp}
        >
          {" "}
          Negative 2 X
        </button>

        <div id="thirdOasis">
          <h4>Selected number will be doubled and then become minus</h4>
        </div>
      </div>
      {/* minus button */}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportOasis);
