import React, { useState, useEffect } from "react";
import "./UserPlace.css";
import unknownUser from "../../../Images/unknownUser.png";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import { Link, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - HeaderGeneral

function UserPlace({
  isClickedHamburgerAsync,
  hamburgerCondition,
  isClickedLogoutWindowAsync,
  isClickedDeleteWindowAsync,
  selectedClickedAvatarFeaturesAsync,
  isSelectedEditTypeAsync,
  isEditCompletedAsync,
  resetRewardAsync,
  authStateCondition,
}) {
  const location = useLocation();
  const exactLocation = location.pathname.slice(1);

  const { user } = authStateCondition;

  // hide user when clicked hamburger icon or when opened profile page
  const [hidden, setHidden] = useState("userOrGuest");
  const [hideUserIcon, setHideUserIcon] = useState(false);

  useEffect(() => {
    hamburgerCondition || exactLocation === "profile"
      ? setHidden("hidden")
      : setHidden("userOrGuest");
  }, [hamburgerCondition, exactLocation]);
  // hide user when clicked hamburger icon or when opened profile page

  // if user logged in show user's name and link to profile
  const [url, setUrl] = useState("/login");
  const [background, setBackgroundl] = useState("");
  const [showName, setShowName] = useState("");
  const [profileTitle, setprofileTitel] = useState("Sign in");

  useEffect(() => {
    if (user === undefined || user === null || user === "") {
      setUrl("/login");
      setBackgroundl("");
      setShowName("");
      setprofileTitel("Sign in");
      setHideUserIcon(false);
    } else {
      setUrl("/profile");
      setBackgroundl("show_loggedUser_username");
      setShowName(user.name);
      setprofileTitel("user profile");
      setHideUserIcon(true);
    }
  }, [user]);
  // if user logged in show user's name and link to profile

  // is clicked signin
  const clickedSignin = () => {
    //play click
    ClickSound(popUpSound);

    isClickedHamburgerAsync(false);

    isClickedLogoutWindowAsync(false);

    isClickedDeleteWindowAsync(false);

    selectedClickedAvatarFeaturesAsync("coin");

    isSelectedEditTypeAsync("");

    isEditCompletedAsync("");

    resetRewardAsync();
  };
  // is clicked signin

  return (
    // User or Guest Image Place
    <section className={hidden}>
      <Link
        to={url}
        onClick={clickedSignin}
        className={background}
        data={
          showName !== undefined && showName !== "" && showName !== null
            ? showName.slice(0, 8)
            : null
        }
      >
        {!hideUserIcon ? (
          <img
            id="userPlace"
            src={unknownUser}
            alt={profileTitle}
            title={profileTitle}
          />
        ) : null}
      </Link>
    </section>
    // User or Guest Image Place
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPlace);
