import React, { useState, useEffect } from "react";
import "./EditForms.css";
import "./EditFormsAnimation.css";
import LoaderSimple from "../../Loading/LoaderSimple";
import EditUserName from "./EditUserName";
import EditEmail from "./EditEmail";
import EditPassword from "./EditPassword";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function EditFormGeneral({ isEditTypeCondition, user }) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    isEditTypeCondition !== ""
      ? setHidden("EditFormGeneral")
      : setHidden("hidden");
  }, [isEditTypeCondition]);

  return (
    <div className={hidden}>
      <div className="EditFormGeneral-LoaderSimple">
        <LoaderSimple />
      </div>

      <EditUserName user={user} />
      <EditEmail user={user} />
      <EditPassword user={user} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFormGeneral);
