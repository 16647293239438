import axios from "axios";
const serverURL = process.env.REACT_APP_API_URL;

const REGISTER_REQUEST = "REGISTER_REQUEST";
const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const REGISTER_FAILURE = "REGISTER_FAILURE";

const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

const LOGOUT_REQUEST = "LOGOUT_REQUEST";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

const RESET = "RESET";

// Action Creators
const registerRequest = () => ({
  type: REGISTER_REQUEST,
  payload: "Register Request",
});

const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

const loginRequest = (user) => ({
  type: LOGIN_REQUEST,
  payload: user,
});

const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
  payload: "Log Out Request",
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
  payload: "Log Out SuccessFully",
});

export const editProfileRequest = () => ({
  type: EDIT_PROFILE_REQUEST,
  payload: "Edit Profile Request",
});

export const editProfileSuccess = (userData) => ({
  type: EDIT_PROFILE_SUCCESS,
  payload: userData,
});

export const editProfileFailure = (error) => ({
  type: EDIT_PROFILE_FAILURE,
  payload: error,
});

export const deleteUserRequest = () => ({
  type: DELETE_USER_REQUEST,
  payload: "Delete Request",
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
  payload: "Deleted SuccessFully",
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

const reset = () => {
  return {
    type: RESET,
  };
};

export const registerAsync = (user) => async (dispatch) => {
  try {
    dispatch(registerRequest(user));

    const userData = await axios.post(`${serverURL}/api/users/`, user);

    if (userData.data) {
      localStorage.setItem("user", JSON.stringify(userData.data));

      dispatch(registerSuccess(userData.data));
    }

    return userData.data;
  } catch (error) {
    const message =
      (error.userData && error.userData.data && error.userData.data.message) ||
      error.message ||
      error.toString();

    return dispatch(registerFailure(message));
  }
};

export const userLoginAsync = (userCredentials) => async (dispatch) => {
  try {
    dispatch(loginRequest(userCredentials));

    const userData = await axios.post(
      `${serverURL}/api/users/login`,
      userCredentials,
    );

    dispatch(loginSuccess(userData.data));

    localStorage.setItem("user", JSON.stringify(userData.data));

    return true;
  } catch (err) {
    dispatch(loginFailure(err.message));

    return false;
  }
};

export const logoutAsync = () => (dispatch) => {
  localStorage.removeItem("user");

  dispatch(logoutRequest());

  dispatch(logoutSuccess());
};

export const editProfileAsync = (token, userData) => async (dispatch) => {
  try {
    dispatch(editProfileRequest());

    const response = await axios.patch(
      `${serverURL}/api/users/edit`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const responseFromServer = response.data.user;

    const updatedUser = {
      ...responseFromServer,
      token,
    };

    dispatch(editProfileSuccess(updatedUser));

    localStorage.setItem("user", JSON.stringify(updatedUser));

    return true;
  } catch (err) {
    dispatch(editProfileFailure(err.message));

    return false;
  }
};

export const deleteUserAsync = (userToken) => async (dispatch) => {
  try {
    dispatch(deleteUserRequest());

    await axios.delete(`${serverURL}/api/users/delete`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    dispatch(deleteUserSuccess());

    localStorage.removeItem("user");

    return true;
  } catch (err) {
    dispatch(deleteUserFailure(err.message));

    return false;
  }
};

export const resetAsync = () => (dispatch) => {
  dispatch(reset());
};
