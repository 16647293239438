import React, { useEffect, useState } from "react";
import "./ExitRoomConfirm.css";
import popUpSound from "../../../../Audio/popUp.mp3";
import logOutSound from "../../../../Audio/logOutSound.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - RoomGameNumbers, GameWatchingContainer, GameOver

function ExitRoomConfirm({
  newRoomData,
  resetRoomsAsync,
  resetRoomAsync,
  editRoomAsync,
  exitRoomCondition,
  isExitRoomAsync,
  deleteRoomAsync,
  editRewardAsync,
}) {
  // newRoomData is coming from parent component - RoomGameNumbers
  const [
    roomNumber,
    exactLocation,
    roomId,
    userId,
    userName,
    thisRoomWhosTurn,
    thisRoomPlayerIds,
  ] = newRoomData;
  // newRoomData is coming from parent component - RoomGameNumbers

  const navigate = useNavigate();

  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    if (!exitRoomCondition[0]) {
      setHidden("hidden");
    } else {
      setHidden("exitRoom-container");
    }

    return;
  }, [exitRoomCondition]);

  const handleClick = async (e) => {
    if (exitRoomCondition[1] === "player") {
      const removePlayer = {
        players: {
          playerId: userId,
          playerName: userName,
        },
        removePlayer: true,
      };

      const rewardWinner = {
        championStatus: {
          roomId: roomId + thisRoomWhosTurn.specialRandom,
          gameType: "blitz",
          gameOver: "win",
        },
      };

      const indexOfUser =
        thisRoomPlayerIds !== undefined
          ? thisRoomPlayerIds.indexOf(userId)
          : null;

      if (e.target.name === "exit" && thisRoomWhosTurn !== undefined) {
        if (!thisRoomWhosTurn.gameStarted) {
          editRoomAsync(roomId, removePlayer);
        } else {
          if (exactLocation === "single-player" && indexOfUser !== null) {
            if (indexOfUser === 0) {
              const successPlayer1 = await editRewardAsync(
                thisRoomPlayerIds[1],
                rewardWinner,
              );

              successPlayer1 && deleteRoomAsync(roomId);
            } else if (indexOfUser === 1) {
              const successPlayer2 = await editRewardAsync(
                thisRoomPlayerIds[0],
                rewardWinner,
              );

              successPlayer2 && deleteRoomAsync(roomId);
            }
          } else if (exactLocation === "multi-player" && indexOfUser !== null) {
            if (indexOfUser === 0 || indexOfUser === 2) {
              const successPlayers2 = await editRewardAsync(
                thisRoomPlayerIds[1],
                rewardWinner,
              );
              const successPlayers4 = await editRewardAsync(
                thisRoomPlayerIds[3],
                rewardWinner,
              );

              successPlayers2 && successPlayers4 && deleteRoomAsync(roomId);
            } else if (indexOfUser === 1 || indexOfUser === 3) {
              const successPlayers1 = await editRewardAsync(
                thisRoomPlayerIds[0],
                rewardWinner,
              );
              const successPlayers3 = await editRewardAsync(
                thisRoomPlayerIds[2],
                rewardWinner,
              );

              successPlayers1 && successPlayers3 && deleteRoomAsync(roomId);
            }
          } else {
            deleteRoomAsync(roomId);
          }
        }

        //play click
        ClickSound(logOutSound);

        resetRoomAsync();

        resetRoomsAsync();

        isExitRoomAsync([false, ""]);

        navigate(`/${exactLocation}/rooms`);
      } else {
        //play click
        ClickSound(popUpSound);

        setHidden("hidden");

        isExitRoomAsync([false, ""]);
      }
    } else if (
      exitRoomCondition[1] === "watcher" &&
      thisRoomWhosTurn !== undefined
    ) {
      if (e.target.name === "exit") {
        //play click
        ClickSound(logOutSound);

        resetRoomsAsync();

        isExitRoomAsync([false, ""]);

        navigate(`/${exactLocation}/rooms`);
      } else {
        //play click
        ClickSound(popUpSound);

        setHidden("hidden");

        isExitRoomAsync([false, ""]);
      }
    }
  };

  return (
    <div className={hidden}>
      <h2>Sure you want to leave the room {roomNumber}?</h2>

      <div className="exitRoom-container_Btn_Place">
        <button
          className="exitRoom-container_Btn_Place_button"
          name="cancel"
          onClick={handleClick}
        >
          <div className="exitRoom-container_Btn_Place_text">Cancel</div>
        </button>

        <button
          className="exitRoom-container_Btn_Place_button"
          name="exit"
          onClick={handleClick}
        >
          <div className="exitRoom-container_Btn_Place_text">Exit</div>
        </button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitRoomConfirm);
