const ISCLICKEDHAMBURGERON = "ISCLICKEDHAMBURGERON";

const isClickedHamburger = (check) => {
  return {
    type: ISCLICKEDHAMBURGERON,
    payload: check,
  };
};

export const isClickedHamburgerAsync = (check) => (dispatch) => {
  dispatch(isClickedHamburger(check));
};
