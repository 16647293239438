const initialSocialState = {
  isFeaturesOn: "coin",
};

const avatarFeaturesReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISCLICKEDAVATARFEATURES":
      return {
        ...state,
        isFeaturesOn: action.payload,
      };

    default:
      return state;
  }
};

export default avatarFeaturesReducer;
