import React from "react";
import "./Explanation.css";
import questionMark from "../../../../../Images/QuestionMarkIcon.png";

// parent component is - PlayOrWatchArea

function Explanation() {
  return (
    <div className="GameOverExplanation">
      <div className="GameOverExplanationTextContainer">
        <p className="GameOverExplanationText">
          The game is over, and you have the option to play again with the same
          opponent(s) if they agree to continue in this room. If they choose not
          to play with you, this room will be deleted. You can then create a new
          room to play with others or join existing rooms. Additionally, you can
          join any room as a spectator to watch other games. Enjoy!
        </p>
      </div>
      <div className="GameOverExplanationIcon">
        <img
          className="GameOverExplanationIconImg"
          src={questionMark}
          title="Description"
          alt="Description"
        />
      </div>
    </div>
  );
}

export default Explanation;
