import React, { useEffect, useState } from "react";
import "./LockedRoom.css";
import lock from "../../../../../Images/lock.png";
import ClickSound from "../../../../MusicAndSounds/ClickSound";
import popUpSound from "../../../../../Audio/popUp.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - PlayOrWatchArea

function LockedRoom({ handlePassCode }) {
  const [selectPassCode, setSelectPassCode] = useState("");

  const onChange = (e) => {
    // play click for input fields
    ClickSound(popUpSound);

    const value = e.target.value;

    setSelectPassCode(value);
  };

  // Send Data to PlayOrWatchArea
  useEffect(() => {
    handlePassCode(selectPassCode);
  }, [selectPassCode, handlePassCode]);
  // Send Data to PlayOrWatchArea

  return (
    <div className="LockedRoom">
      <div className="LockedRoom-lock">
        <img
          className="LockedRoom-lock-logo"
          src={lock}
          title="This room is locked, type the passcode to unlock"
          alt="This room is locked, type the passcode to unlock"
        />
      </div>

      <div className="LockedRoom-passCodeContainer">
        <div className="LockedRoom-passCode">
          <h3>Enter a passcode to begin playing</h3>

          <input
            type="text"
            className="LockedRoom-passCode_input"
            name="passCode"
            value={selectPassCode}
            onChange={onChange}
            maxLength="4"
            placeholder="xxxx"
            autoFocus
          />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedRoom);
