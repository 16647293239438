const initialSocialState = {
  isForgetPasswordCompleted: false,
};

const isForgetPasswordCompletedReducer = (
  state = initialSocialState,
  action,
) => {
  switch (action.type) {
    case "FORGETPASSWORDCOMPLETED":
      return {
        ...state,
        isLogoutCompleted: action.payload,
      };

    default:
      return state;
  }
};

export default isForgetPasswordCompletedReducer;
