import React from "react";

// parent component is - WaitingForOtherPlayers

function WaitingClock() {
  return (
    <div className="WaitingClock">
      <svg height="240" width="240">
        <line
          className="WaitingClock-loader-pointer"
          x1="120"
          y1="120"
          x2="120"
          y2="97"
        />
        <line
          className="WaitingClock-loader-line"
          x1="120"
          y1="120"
          x2="135"
          y2="120"
        />
        <circle
          className="WaitingClock-loader-circle"
          cx="120"
          cy="120"
          r="30"
        />
        <circle
          className="WaitingClock-loader-center"
          cx="120"
          cy="120"
          r="5"
        />
      </svg>
    </div>
  );
}

export default WaitingClock;
