import React, { useEffect, useState } from "react";
import "./Runners.css";
import "./RunnersAnimation.css";
import LoadingBigHexagon from "../../Loading/LoadingBigHexagon";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App
// path="/198Runners"
// Haven't use

function Runners({ hamburgerCondition }) {
  const [isHidden, setIsHidden] = useState("Runners_container");

  //is clicked hamburger
  useEffect(() => {
    !hamburgerCondition
      ? setIsHidden("Runners_container")
      : setIsHidden("hidden");
  }, [hamburgerCondition]);
  //is clicked hamburger

  return (
    <section className={isHidden}>
      <div className="Runners_item">
        <section>
          <div className="content">
            <h2>198RUNNERS</h2>
            <h2>198RUNNERS</h2>
          </div>
        </section>
      </div>

      <LoadingBigHexagon />
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Runners);
