import axios from "axios";
const serverURL = process.env.REACT_APP_API_URL;

const GET_REWARD_REQUEST = "GET_REWARD_REQUEST";
const GET_REWARD_SUCCESS = "GET_REWARD_SUCCESS";
const GET_REWARD_FAILURE = "GET_REWARD_FAILURE";

const RESETREWARD = "RESETREWARD";

export const getRewardRequest = () => ({
  type: GET_REWARD_REQUEST,
  payload: "Get a Reward Request",
});

export const getRewardSuccess = (rewardData) => ({
  type: GET_REWARD_SUCCESS,
  payload: rewardData,
});

export const getRewardFailure = (error) => ({
  type: GET_REWARD_FAILURE,
  payload: error,
});

const reset = () => {
  return {
    type: RESETREWARD,
  };
};

export const getRewardByUserIdAsync = (userId) => async (dispatch) => {
  try {
    dispatch(getRewardRequest());

    const rewardData = await axios.get(`${serverURL}/api/rewards/${userId}`);

    if (rewardData.data) {
      dispatch(getRewardSuccess(rewardData.data));
    }

    return rewardData.data;
  } catch (error) {
    const message =
      (error.rewardData &&
        error.rewardData.data &&
        error.rewardData.data.message) ||
      error.message ||
      error.toString();

    dispatch(getRewardFailure(message));

    // throw error;
  }
};

export const resetRewardAsync = () => (dispatch) => {
  dispatch(reset());
};
