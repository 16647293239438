import React from "react";
import "./CityCup.css";
import popUpSound from "../../../../Audio/popUp.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - CityCup

function CityList({ isSpecifiedTourTypeAsync }) {
  const navigate = useNavigate();

  const getCityType = (e) => {
    const selectedCity = e.target.value;

    isSpecifiedTourTypeAsync(selectedCity);

    navigate(`/tournaments/city-cup/${selectedCity}`);
  };

  const clickedOption = () => {
    //play click
    ClickSound(popUpSound);
  };

  return (
    <div className="CityListSelect">
      <select name="city" onChange={getCityType} onClick={clickedOption}>
        <option value="">Select City...</option>
        <option value="buenos-aires">Buenos Aires</option>
        <option value="são-paulo" disabled>
          São Paulo
        </option>
        <option value="Rio de Janeiro" disabled>
          Rio de Janeiro
        </option>
        <option value="Toronto" disabled>
          Toronto
        </option>
        <option value="Shanghai" disabled>
          Shanghai
        </option>
        <option value="Cairo" disabled>
          Cairo
        </option>
        <option value="paris">Paris</option>
        <option value="Hamburg" disabled>
          Hamburg
        </option>
        <option value="Mumbai" disabled>
          Mumbai{" "}
        </option>
        <option value="Jakarta" disabled>
          Jakarta
        </option>
        <option value="Tehran" disabled>
          Tehran
        </option>
        <option value="milan">Milan</option>
        <option value="Tokyo" disabled>
          Tokyo
        </option>
        <option value="Seoul" disabled>
          Seoul
        </option>
        <option value="Kuala Lumpur" disabled>
          Kuala Lumpur
        </option>
        <option value="Cancún" disabled>
          Cancún
        </option>
        <option value="lagos">Lagos</option>
        <option value="Karachi" disabled>
          Karachi
        </option>
        <option value="Manila" disabled>
          Manila
        </option>
        <option value="Moscow" disabled>
          Moscow
        </option>
        <option value="Saint Petersburg" disabled>
          Saint Petersburg
        </option>
        <option value="Riyadh" disabled>
          Riyadh
        </option>
        <option value="Johannesburg" disabled>
          Johannesburg
        </option>
        <option value="Madrid" disabled>
          Madrid
        </option>
        <option value="Barcelona" disabled>
          Barcelona
        </option>
        <option value="Bangkok" disabled>
          Bangkok
        </option>
        <option value="Liverpool" disabled>
          Liverpool{" "}
        </option>
        <option value="London" disabled>
          London
        </option>
        <option value="Glasgow" disabled>
          Glasgow
        </option>
        <option value="Cardiff" disabled>
          Cardiff
        </option>
        <option value="New York" disabled>
          New York
        </option>
        <option value="Chicago" disabled>
          Chicago
        </option>
        <option value="Los Angeles" disabled>
          Los Angeles
        </option>
        <option value="Miami" disabled>
          Miami
        </option>
        <option value="Detroit" disabled>
          Detroit
        </option>
        <option value="Salt Lake City" disabled>
          Salt Lake City
        </option>
      </select>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CityList);
