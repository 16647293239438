import React from "react";
import "./AllSingleRoomsContainer.css";
import "./AllSingleRoomsContainerAnimation.css";
import AllSingleRooms from "../AllSingleRooms/AllSingleRooms";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AllSingleRoomsContainerArea

function AllSingleRoomsContainer() {
  return (
    <div className="AllSingleRoomsContainer">
      <AllSingleRooms />
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllSingleRoomsContainer);
