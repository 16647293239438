import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const usePageAccessType = () => {
  const [isPageRefreshed, setIsPageRefreshed] = useState(undefined);
  const [isDirectAccess, setIsDirectAccess] = useState(undefined);
  const [isExternalNavigation, setIsExternalNavigation] = useState(undefined);
  const location = useLocation();

  useEffect(() => {
    const previousUrl = sessionStorage.getItem("previousUrl");
    const currentUrl = window.location.href;
    const pageRefreshed = sessionStorage.getItem("pageRefreshed");

    if (pageRefreshed) {
      setIsPageRefreshed(true);
      sessionStorage.removeItem("pageRefreshed");
    } else {
      setIsPageRefreshed(false);
    }

    // Determine direct access
    if (!previousUrl) {
      setIsDirectAccess(true);
    } else {
      setIsDirectAccess(false);
    }

    // Determine external navigation
    if (previousUrl && !previousUrl.startsWith(window.location.origin)) {
      setIsExternalNavigation(true);
    } else {
      setIsExternalNavigation(false);
    }

    // Update previous URL in session storage
    sessionStorage.setItem("previousUrl", currentUrl);

    const handleBeforeUnload = () => {
      sessionStorage.setItem("pageRefreshed", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [location]);

  return { isPageRefreshed, isDirectAccess, isExternalNavigation };
};

export default usePageAccessType;
