import React, { useEffect, useState } from "react";
import "./AvatarEditCard.css";
import "./AvatarEditCardAnimation.css";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import userProfileFeaturesSound from "../../../../Audio/userProfileFeatures.mp3";
import editEnterSound from "../../../../Audio/createGame.mp3";
import LoaderCircle from "../../../Loading/LoaderCircle";
import deleteAcc from "../../../../Images/deleteAcc.png";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarEditCard({
  avatarFeatureCondition,
  isClickedDeleteWindowAsync,
  isSelectedEditTypeAsync,
  isEditTypeCondition,
  isEditCompletedCondition,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    setHidden(
      deleteWindowCondition
        ? "hidden"
        : avatarFeatureCondition === "edit" && !logOutWindowCondition
          ? "AvatarEditCard"
          : "hidden",
    );
  }, [avatarFeatureCondition, logOutWindowCondition, deleteWindowCondition]);

  const handleClick = () => {
    isClickedDeleteWindowAsync(true);

    //play click
    ClickSound(editEnterSound);
  };

  const getValue = (e) => {
    let editType = e.target.value;

    //play click
    ClickSound(userProfileFeaturesSound);

    isSelectedEditTypeAsync(editType);
  };

  const [myValue, setMyValue] = useState("");

  useEffect(() => {
    if (isEditTypeCondition === "username") {
      setMyValue("username");
    } else if (isEditTypeCondition === "email") {
      setMyValue("email");
    } else if (isEditTypeCondition === "password") {
      setMyValue("password");
    } else {
      setMyValue("");
    }
  }, [isEditTypeCondition]);

  return (
    <div className={hidden}>
      <div className="AvatarEditCard-loading-circle-place">
        <LoaderCircle />
      </div>
      {/* <form className="radio-input-wrapper" onChange={getValue}> */}
      <form
        className={
          isEditCompletedCondition === ""
            ? "radio-input-wrapper"
            : "radio-input-wrapper disable-link darkOpacity"
        }
        onChange={getValue}
      >
        <label className="label">
          <input
            value="username"
            name="value-radio"
            id="value-2"
            className="radio-input"
            type="radio"
            defaultChecked={myValue === "username"}
          />
          <div className="radio-design"></div>
          <div className="label-text">Edit Username</div>
        </label>
        <label className="label">
          <input
            value="email"
            name="value-radio"
            id="value-3"
            className="radio-input"
            type="radio"
            defaultChecked={myValue === "email"}
          />
          <div className="radio-design"></div>
          <div className="label-text">Edit E-mail</div>
        </label>
        <label className="label">
          <input
            value="password"
            name="value-radio"
            id="value-4"
            className="radio-input"
            type="radio"
            defaultChecked={myValue === "password"}
          />
          <div className="radio-design"></div>
          <div className="label-text">Edit Password</div>
        </label>
      </form>
      <div className="AvatarEditCard-deleteAcc-place">
        <img
          className="AvatarEditCard-deleteAcc-place-img"
          src={deleteAcc}
          title="Delete Account"
          alt="Delete Account"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarEditCard);
