import React from "react";
import "./ContinentalCup.css";
import chooseContinentSound from "../../../../Audio/chooseContinent.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import { useNavigate } from "react-router-dom";
import worldmap from "../../../../Images/worldmap.png";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - ContinentCup

function ContinentList() {
  const navigate = useNavigate();

  const getContinentType = (e) => {
    const selectedContinent = e.target.name;

    //play click
    ClickSound(chooseContinentSound);

    navigate(`/tournaments/coninental-cup/${selectedContinent}`);
  };

  return (
    <div className="ContinentList-map">
      <img src={worldmap} title="world map" alt="world map" />

      <button
        className="ContinentList-map-point ContinentList-map-point_northAmerica"
        name="north-america"
        onClick={getContinentType}
      >
        <div className="ContinentList-map-point_tooltip">
          <h4>Cup of North America</h4>
        </div>
      </button>

      <button
        className="ContinentList-map-point ContinentList-map-point_southAmerica"
        name="south-america"
        onClick={getContinentType}
      >
        <div className="ContinentList-map-point_tooltip">
          <h4>Cup of South America</h4>
        </div>
      </button>

      <button
        className="ContinentList-map-point ContinentList-map-point_europe"
        name="europe"
        onClick={getContinentType}
      >
        <div className="ContinentList-map-point_tooltip">
          <h4>Cup of Europe</h4>
        </div>
      </button>

      <button
        className="ContinentList-map-point ContinentList-map-point_africa"
        name="africa"
        onClick={getContinentType}
      >
        <div className="ContinentList-map-point_tooltip">
          <h4>Cup of Africa</h4>
        </div>
      </button>

      <button
        className="ContinentList-map-point ContinentList-map-point_asia"
        name="asia"
        onClick={getContinentType}
      >
        <div className="ContinentList-map-point_tooltip">
          <h4>Cup of Asia</h4>
        </div>
      </button>

      <button
        className="ContinentList-map-point ContinentList-map-point_australia"
        name="australia"
        onClick={getContinentType}
      >
        <div className="ContinentList-map-point_tooltip">
          <h4>Cup of Australia</h4>
        </div>
      </button>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinentList);
