const initialSocialState = {
  isOasis: "plus",
};

const oasisReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "OASISHELPTYPE":
      return {
        ...state,
        isOasis: action.payload,
      };

    default:
      return state;
  }
};

export default oasisReducer;
