import React, { useEffect, useState } from "react";
import "./SinglePlayerGame.css";
import gameAreaLoadingSound from "../../../../Audio/gameAreaLoadingSound.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import backGroundSound from "../../../../Audio/backGroundMusic.mp3";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import LoadingBigHexagon from "../../../Loading/LoadingBigHexagon";
import AllSingleRoomsContainerArea from "../AllSingleRoomsContainerArea/AllSingleRoomsContainerArea";
import BtnContainerToGoCreateRoom from "../../CreateRoomArea/BtnContainerToGoCreateRoom/BtnContainerToGoCreateRoom";
import SwitchGameTypes from "../../SwitchGameTypes/SwitchGameTypes";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import { useLocation, Outlet } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function SinglePlayerGame({
  hamburgerCondition,
  isSingleUserAsync,
  singleCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const location = useLocation();
  const exactLocation = location.pathname.split("/").length;

  const [isHidden, setIsHidden] = useState("GeneralSingleGameArea");
  const [hideForRooms, setHideForRooms] = useState(
    "GeneralSingleGameArea_place",
  );

  // single player page requirements /// game type
  useEffect(() => {
    !singleCondition && isSingleUserAsync(true);
  }, [singleCondition, isSingleUserAsync]);
  // single player page requirements /// game type

  // hide when changed the link to in rooms
  useEffect(() => {
    exactLocation > 2
      ? setHideForRooms("hidden")
      : setHideForRooms("GeneralSingleGameArea_place");
  }, [exactLocation]);
  // hide when changed the link to in rooms

  // is clicked hamburger
  useEffect(() => {
    !hamburgerCondition
      ? setIsHidden("GeneralSingleGameArea")
      : setIsHidden("hidden");
  }, [hamburgerCondition]);
  // is clicked hamburger

  return (
    <section className={isHidden}>
      {!hamburgerCondition && exactLocation === 2 ? (
        <BackGroundMusic
          Audiodata={[
            backGroundSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className={hideForRooms}>
        <div className="GeneralSingleGameAreaBigHexPlace">
          {!hamburgerCondition && exactLocation === 2 ? (
            <LoadingSoundGameArea
              Audiodata={[
                gameAreaLoadingSound,
                isPageRefreshed,
                isDirectAccess,
                isExternalNavigation,
              ]}
            />
          ) : null}

          <LoadingBigHexagon />
        </div>

        <AllSingleRoomsContainerArea />

        <BtnContainerToGoCreateRoom />

        <SwitchGameTypes />
      </div>

      <Outlet />
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePlayerGame);
