import React from "react";
import "./Loading.css";
import "./LoadingAnimation.css";

function LoaderCircleNumbers() {
  return (
    <div className="LoaderCircleNumbers">
      <div className="LoaderCircleNumbers-intern"></div>
      <div className="LoaderCircleNumbers-external-shadow">
        <div className="LoaderCircleNumbers-central"></div>
      </div>
    </div>
  );
}

export default LoaderCircleNumbers;
