const ISCLICKEDNAV = "ISCLICKEDNAV";

const isClickedNav = (check) => {
  return {
    type: ISCLICKEDNAV,
    payload: check,
  };
};

export const isClickedNavAsync = (check) => (dispatch) => {
  dispatch(isClickedNav(check));
};
