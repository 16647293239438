import React, { useEffect, useState } from "react";
import "./CityCup.css";
import "./CityCupAnimation.css";
import Temporary from "../../Temporary/Temporary";
import LoaderDots from "../../../Loading/LoaderDots";
import { useParams } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function City() {
  const routeParams = useParams();

  const [cityType, setCityType] = useState("");

  useEffect(() => {
    if (routeParams.id === "buenos-aires") {
      setCityType("Buenos Aires");
    } else if (routeParams.id === "paris") {
      setCityType("Paris");
    } else if (routeParams.id === "milan") {
      setCityType("Milan");
    } else if (routeParams.id === "lagos") {
      setCityType("Lagos");
    } else {
      setCityType("Buenos Aires");
    }
  }, [cityType, routeParams.id]);

  return (
    <div className="singleCity">
      <div className="singleCity_selected">{cityType}</div>

      <div className="singleCity_selected_loading">
        <LoaderDots />
      </div>

      <Temporary />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(City);
