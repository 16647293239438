import React from "react";
import "./Loading.css";
import "./LoadingAnimation.css";

function LoaderDots() {
  return (
    <div className="Loading_dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
}

export default LoaderDots;
