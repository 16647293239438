import React, { useState, useEffect } from "react";
import "./AvatarTitlesCard.css";
import "./AvatarTitlesCardAnimation.css";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarTitlesCard({
  avatarFeatureCondition,
  championStatus,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  const [blitz, setBlitz] = useState("");
  const [events, setEvents] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [continent, setContinent] = useState("");
  const [world, setWorld] = useState("");

  useEffect(() => {
    if (championStatus !== null && championStatus !== undefined) {
      setBlitz(championStatus.blitzWinner);
      setEvents(championStatus.eventsWinner);
      setCity(championStatus.cityWinner);
      setCountry(championStatus.countryWinner);
      setContinent(championStatus.continentWinner);
      setWorld(championStatus.worldWinner);
    } else {
      setBlitz(null);
      setEvents(null);
      setCity(null);
      setCountry(null);
      setContinent(null);
      setWorld(null);
    }
  }, [championStatus]);
  // get user data

  // hidden, if clicked edit Profile
  const [hidden, setHidden] = useState("AvatarTitlesCard");

  useEffect(() => {
    avatarFeatureCondition === "edit" ||
    logOutWindowCondition ||
    deleteWindowCondition
      ? setHidden("hidden")
      : setHidden("AvatarTitlesCard");
  }, [avatarFeatureCondition, logOutWindowCondition, deleteWindowCondition]);
  // hidden, if clicked edit Profile

  return (
    <div className={hidden}>
      <table className="table_container">
        <tbody>
          <tr>
            <td>Blitz Games Winner</td>
            <td>{blitz}</td>
          </tr>
          <tr>
            <td>Events Champion</td>
            <td>{events}</td>
          </tr>
          <tr>
            <td>City Champion</td>
            <td>{city}</td>
          </tr>
          <tr>
            <td>Country Champion</td>
            <td>{country}</td>
          </tr>
          <tr>
            <td>Continent Champion</td>
            <td>{continent}</td>
          </tr>
          <tr>
            <td>World Champion</td>
            <td>{world}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarTitlesCard);
