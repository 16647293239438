import React from "react";
import "./EventCup.css";
import popUpSound from "../../../../Audio/popUp.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - EventCup

function EventList({ isSpecifiedTourTypeAsync }) {
  const navigate = useNavigate();

  const getEventType = (e) => {
    const selectedEvent = e.target.value;

    isSpecifiedTourTypeAsync(selectedEvent);

    navigate(`/tournaments/event-cup/${selectedEvent}`);
  };

  const clickedOption = () => {
    //play click
    ClickSound(popUpSound);
  };

  return (
    <div className="EventListSelect">
      <select name="country" onChange={getEventType} onClick={clickedOption}>
        <option value="">Select Event...</option>
        <option value="event-1" disabled>
          Event 1
        </option>
        <option value="event-2">Event 2</option>
        <option value="event-3" disabled>
          Event 3
        </option>
        <option value="event-4" disabled>
          Event 4
        </option>
        <option value="event-5" disabled>
          Event 5
        </option>
        <option value="event-6">Event 6</option>
        <option value="event-7" disabled>
          Event 7
        </option>
        <option value="event-8" disabled>
          Event 8
        </option>
        <option value="event-9">Event 9</option>
        <option value="event-10" disabled>
          Event 10
        </option>
      </select>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
