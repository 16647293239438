import React, { useState, useEffect, Fragment } from "react";
import "./UserProfile.css";
import close from "../../../../Images/close.png";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import popUpSound from "../../../../Audio/popUp.mp3";
import userPlaceBackground from "../../../../Audio/userPlaceBackground.mp3";
import AvatarImageCard from "../AvatarImageCard/AvatarImageCard";
import AvatarTitlesCard from "../AvatarTitlesCard/AvatarTitlesCard";
import AvatarEdittingPlace from "../AvatarEditCard/AvatarEdittingPlace";
import EditFormGeneral from "../../EditForms/EditFormGeneral";
import AvatarBonusCard from "../AvatarBonusCard/AvatarBonusCard";
import AvatarLevelCard from "../AvatarLevelCard/AvatarLevelCard";
import AvatarFeatures from "../AvatarFeatures/AvatarFeatures";
import LogOutWindow from "../../Logout/LogOutWindow";
import AvatarEditCard from "../AvatarEditCard/AvatarEditCard";
import DeleteAccountWindow from "../../DeleteAccount/DeleteAccountWindow";
import LoaderSimple from "../../../Loading/LoaderSimple";
import Error from "./Error/Error";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function UserProfile({
  hamburgerCondition,
  logOutWindowCondition,
  deleteWindowCondition,
  isSelectedEditTypeAsync,
  selectedClickedAvatarFeaturesAsync,
  isEditCompletedAsync,
  getRewardByUserIdAsync,
  getSingleRewardCondition,
  logoutAsync,
  isLogoutCompletedAsync,
  resetAsync,
  resetRewardAsync,
  authStateCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const navigate = useNavigate();

  const { user } = authStateCondition;

  // if user is not logged in, then navigate log in page
  useEffect(() => {
    user === null && navigate("/login");
  }, [user, navigate]);
  // if user is not logged in, then navigate log in page

  // is clicked hamburger
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    !hamburgerCondition ? setHidden(false) : setHidden(true);
  }, [hamburgerCondition]);
  // is clicked hamburger

  // Get rewards
  useEffect(() => {
    let restartNetwork;

    const { reward, isLoading, isError, isSuccess, message } =
      getSingleRewardCondition;

    if (
      reward === undefined &&
      !isSuccess &&
      !isLoading &&
      user !== undefined &&
      user !== null
    ) {
      if (isError && message === "Network Error") {
        restartNetwork = setTimeout(() => {
          getRewardByUserIdAsync(user._id);
        }, 10000);
      } else if (isError && message === "Request failed with status code 404") {
        logoutAsync();
        isLogoutCompletedAsync(true);
        resetAsync();
        resetRewardAsync();
      } else {
        getRewardByUserIdAsync(user._id);
      }
    }

    return () => clearTimeout(restartNetwork);
  }, [
    getRewardByUserIdAsync,
    getSingleRewardCondition,
    user,
    logoutAsync,
    resetAsync,
    resetRewardAsync,
    isLogoutCompletedAsync,
  ]);
  // Get rewards

  // Profile reward statuses
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const { reward, isLoading, isError, isSuccess, message } =
      getSingleRewardCondition;

    if (isLoading) {
      setLoading(true);
      setErr(false);
      setSuccess(false);
    } else if (isError && message !== "") {
      setLoading(false);
      setErr(true);
      setSuccess(false);
    } else if (isSuccess && reward !== undefined) {
      setLoading(false);
      setErr(false);
      setSuccess(true);
    }
  }, [getSingleRewardCondition]);
  // Profile reward statuses

  // hidden window
  const [hideClose, setHideClose] = useState("close");

  useEffect(() => {
    logOutWindowCondition || deleteWindowCondition
      ? setHideClose("hidden")
      : setHideClose("close");
  }, [logOutWindowCondition, deleteWindowCondition]);
  // hidden window

  // set user Rewards when page is opened
  const [playerName, setPlayerName] = useState(null);
  const [playedGames, setPlayedGames] = useState(null);
  const [assets, setAssets] = useState(null);
  const [categories, setCategories] = useState(null);
  const [championStatus, setChampionStatus] = useState(null);
  const [levelStatus, setLevelStatus] = useState(null);

  useEffect(() => {
    const { reward } = getSingleRewardCondition;

    if (reward !== undefined) {
      setPlayerName(reward.playerName);
      setPlayedGames(reward.playedGames);
      setAssets(reward.assets);
      setCategories(reward.categories);
      setChampionStatus(reward.championStatus);
      setLevelStatus(reward.levelStatus);
    } else {
      setPlayerName(null);
      setPlayedGames(null);
      setAssets(null);
      setCategories(null);
      setChampionStatus(null);
      setLevelStatus(null);
    }
  }, [getSingleRewardCondition]);
  // set user Rewards when page is opened

  // to close the window
  const handleClose = (e) => {
    e.preventDefault();

    //play click
    ClickSound(popUpSound);

    navigate(-1);

    selectedClickedAvatarFeaturesAsync("coin");

    isSelectedEditTypeAsync("");

    isEditCompletedAsync("");
  };
  // to close the window

  return !hidden ? (
    <section
      className={
        logOutWindowCondition || deleteWindowCondition
          ? "UserProfile-logout"
          : "UserProfile"
      }
    >
      {success ? (
        <Fragment>
          <BackGroundMusic
            Audiodata={[
              userPlaceBackground,
              isPageRefreshed,
              isDirectAccess,
              isExternalNavigation,
            ]}
          />
          <AvatarImageCard userData={[playerName, levelStatus, categories]} />
          <AvatarTitlesCard championStatus={championStatus} />
          <AvatarEdittingPlace />
          <AvatarBonusCard assets={assets} />
          <AvatarEditCard />
          <EditFormGeneral user={user} />
          <AvatarLevelCard userData={[playedGames, levelStatus]} />
          <AvatarFeatures />
          <LogOutWindow />
          <DeleteAccountWindow user={user} />
        </Fragment>
      ) : null}

      {loading ? <LoaderSimple /> : null}

      {err ? <Error /> : null}

      <div className={hideClose}>
        <a href="/" onClick={handleClose}>
          <img src={close} alt="close window" title="close window" />
        </a>
      </div>
    </section>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
