import React, { useEffect, useState } from "react";
import "./EventCup.css";
import "./EventCupAnimation.css";
import LoadingSoundTournamentAreaSound from "../../../../Audio/LoadingSoundTournamentArea.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import EventList from "./EventList";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import { Outlet } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function EventCup({ isEventAsync, eventCondition, hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  //event page requirements
  useEffect(() => {
    !eventCondition && isEventAsync(true);
  }, [isEventAsync, eventCondition]);
  //event page requirements

  return (
    <section className="EventCup">
      {!hamburgerCondition ? (
        <LoadingSoundGameArea
          Audiodata={[
            LoadingSoundTournamentAreaSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="EventCup_loading">
        <LoadingHexagon />
      </div>

      <div className="EventCup_events">
        <Outlet />

        <EventList />
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCup);
