import React from "react";
import "./MenuButtons.css";
import "./MenuButtonsAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import { Link } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AllButtons

function TournamentsButton({
  isSingleUserAsync,
  isMultiPlayerAsync,
  isTournamentAsync,
  isWorldAsync,
  isContinentalAsync,
  isCountryAsync,
  isCityAsync,
  isEventAsync,
}) {
  // clicked tournaments
  const handleClick = () => {
    isSingleUserAsync(false);
    isMultiPlayerAsync(false);
    isTournamentAsync(true);
    isWorldAsync(false);
    isContinentalAsync(false);
    isCountryAsync(false);
    isCityAsync(false);
    isEventAsync(false);

    //play click
    ClickSound(menuButtonsSound);
  };
  // clicked tournaments

  return (
    <div
      className="btn_tournaments"
      title="Play Tournaments"
      onClick={handleClick}
    >
      <div className="btn_general">
        <Link to="/tournaments">Tournaments</Link>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TournamentsButton);
