import React, { useEffect, useState } from "react";
import "./NavPages.css";
import "./NavPagesAnimation.css";
import LoadingHexagon from "../../Loading/LoadingHexagon";
import GameSettingsFeatures from "./GameSettingsFeatures";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - NavPlace

function GameSettings({ navCondition, isMutedMusicAsync }) {
  const gameSettings = "GAME SETTINGS";

  const [isHidden, setIsHidden] = useState("NavPage");

  useEffect(() => {
    navCondition === gameSettings
      ? setIsHidden("NavPage")
      : setIsHidden("hidden");
  }, [navCondition]);

  return (
    // className='NavPage'
    <section className={isHidden}>
      <div className="Nav_hide-hex">
        <LoadingHexagon />
      </div>

      <div className="NavHeaderPlace">
        <h3 className="h3_transform" title="Game settings | Topic">
          {gameSettings}
        </h3>
      </div>

      <GameSettingsFeatures isMutedMusicAsync={isMutedMusicAsync} />
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GameSettings);
