import React, { useEffect } from "react";
import "./ContinentalCup.css";
import chooseContinentSound from "../../../../Audio/chooseContinent.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - Continent

function ContinentButtons({
  isSpecifiedTourTypeAsync,
  specifiedTourTypeCondition,
}) {
  const navigate = useNavigate();

  const location = useLocation();
  const exactLocation = location.pathname.split("/")[3];

  //world page requirements
  useEffect(() => {
    specifiedTourTypeCondition !== exactLocation &&
      isSpecifiedTourTypeAsync(exactLocation);
  }, [isSpecifiedTourTypeAsync, specifiedTourTypeCondition, exactLocation]);
  //world page requirements

  const handleClick = (e) => {
    //play click
    ClickSound(chooseContinentSound);

    if (e.target.name === "continue") {
      console.log(specifiedTourTypeCondition);
    } else {
      navigate("/tournaments/coninental-cup");
    }
  };

  return (
    <div className="ContinentButtons">
      <button
        className="ContinentButtons_button"
        name="change"
        onClick={handleClick}
      >
        <div className="ContinentButtons_button_text">Change Continent</div>
      </button>
      <button
        className="ContinentButtons_button"
        name="continue"
        onClick={handleClick}
      >
        <div className="ContinentButtons_button_text">Continue</div>
      </button>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinentButtons);
