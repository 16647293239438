import React, { useEffect, useState } from "react";
import "./GameStatus.css";
import "./GameStatusAnimation.css";

// parent component is - RoomGameNumbers

function GameStatus({ whosTurnIs }) {
  const [hidden, setHidden] = useState("hidden");
  const [gameStarted, setGameStarted] = useState("hidden");
  const [gameContinue, setGameContinue] = useState("hidden");

  useEffect(() => {
    if (whosTurnIs !== undefined) {
      if (whosTurnIs.gameStarted === true && whosTurnIs.currentNumber < 9) {
        setHidden("RoomGameNumbers-gameStatus");
        setGameStarted("RoomGameNumbers-gameStatus-text");
        setGameContinue("hidden");
      } else if (
        whosTurnIs.gameStarted === true &&
        whosTurnIs.currentNumber >= 9 &&
        whosTurnIs.currentNumber < 198
      ) {
        setHidden("RoomGameNumbers-gameStatus");
        setGameStarted("hidden");
        setGameContinue("RoomGameNumbers-gameStatus-text");
      } else {
        setHidden("hidden");
        setGameStarted("hidden");
        setGameContinue("hidden");
      }
    } else {
      setHidden("hidden");
      setGameStarted("hidden");
      setGameContinue("hidden");
    }
  }, [whosTurnIs]);

  return (
    <div className={hidden}>
      <h1 className={gameStarted}>Game Started</h1>
      <h1 className={gameContinue}>Continuing</h1>
    </div>
  );
}

export default GameStatus;
