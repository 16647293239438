import React, { useState, useEffect } from "react";
import "./SingleRoomDisable.css";
import "./SingleRoomDisableAnimation.css";
import LoaderArrow from "../../../Loading/LoaderArrow";

// parent component is - AllSingleRooms

function SingleRoomDisable({ disableEnter }) {
  const [showDisable, setShowDisable] = useState("SingleRoomDisable");
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    if (disableEnter) {
      setShowDisable("SingleRoomDisable-notEnter");

      setShowArrow(true);
    } else {
      setShowDisable("SingleRoomDisable");

      setShowArrow(false);
    }
  }, [disableEnter]);

  return (
    <div
      className={showDisable}
      title="This room isn't accessible to you as you're currently occupying another room."
    >
      {showArrow ? <LoaderArrow /> : <></>}
    </div>
  );
}

export default SingleRoomDisable;
