import React from "react";
import "./GameWatchingLimitations.css";
import "./GameWatchingLimitationsAnimation.css";
import ClickSound from "../../../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../../../Audio/menuButtons.mp3";
import { useNavigate } from "react-router-dom";

// parent component is - GameWatchingContainer

function GameWatchingLimitations() {
  const navigate = useNavigate();

  const guestheader = "The limitations";
  const guestText =
    "Thanks for joining us. Guest users cannot play, but they can still watch the games, so don't worry. Log in if you want to play and join the fun! Whether you're here to play or just enjoy the games, we're glad you're a part of the action. Let the games begin!";

  const handleClick = () => {
    //play click continue
    ClickSound(menuButtonsSound);

    navigate("/login");
  };

  return (
    <div
      className="GameWatchingContainer-limitations"
      title="Guests can watch games but cannot play."
    >
      <p className="GameWatchingContainer-limitations_heading">{guestheader}</p>
      <p className="GameWatchingContainer-limitations_text">{guestText}</p>

      <div className="GameWatchingButtons">
        <button
          className="GameWatchingButtons_button"
          name="play"
          onClick={handleClick}
          title="Enter rooms area"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            ></path>
          </svg>
          <div className="GameWatchingButtons_text">Log in</div>
        </button>
      </div>
    </div>
  );
}

export default GameWatchingLimitations;
