import React, { useState, useEffect } from "react";
import "./HeaderGamePlayersData.css";
import close from "../../../../Images/close.png";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import popUpSound from "../../../../Audio/popUp.mp3";
import { useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

function HeaderGamePlayersData({ chosenPlayerData, exitRoomCondition }) {
  const location = useLocation();
  const newRoomId = location.pathname.split("/")[3];

  const chosenPlayer = chosenPlayerData[0];
  const gameStarted =
    chosenPlayerData[1] !== undefined
      ? chosenPlayerData[1].whosTurn.gameStarted
      : undefined;
  const oldRoomId =
    chosenPlayer !== undefined ? chosenPlayer.roomId : undefined;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (
      chosenPlayer &&
      "double" in chosenPlayer &&
      !exitRoomCondition[0] &&
      gameStarted &&
      newRoomId === oldRoomId
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [chosenPlayer, exitRoomCondition, oldRoomId, newRoomId]);

  // Close the component
  const handleClose = (e) => {
    e.preventDefault();
    ClickSound(popUpSound);
    setIsVisible(false);
  };

  return isVisible ? (
    <div className="HeaderGamePlayersData" title="Help Oasis data">
      <table className="HeaderGamePlayersDataTable">
        <caption className="HeaderGamePlayersDataTableName">
          {chosenPlayer.fifththChild.toUpperCase()}
        </caption>
        <tbody>
          <tr>
            <td>Double:</td>
            <td>X {chosenPlayer.double}</td>
          </tr>
          <tr>
            <td>Add extra 4:</td>
            <td>X {chosenPlayer.added}</td>
          </tr>
          <tr>
            <td>Add extra 7:</td>
            <td>X {chosenPlayer.extra}</td>
          </tr>
          <tr>
            <td>Add extra 1:</td>
            <td>X {chosenPlayer.bonus}</td>
          </tr>
          <tr>
            <td>Double Negative:</td>
            <td>X {chosenPlayer.minus}</td>
          </tr>
          <tr>
            <td>Matched:</td>
            <td>X {chosenPlayer.matched}</td>
          </tr>
          <tr>
            <td> </td>
            <td> </td>
          </tr>
          <tr className="HeaderGamePlayersDataTableRoom">
            <td>Room #:</td>
            <td>{chosenPlayer.roomId.slice(-6).toUpperCase()}</td>
          </tr>
        </tbody>
      </table>
      <div className="close">
        <a href="/" onClick={handleClose}>
          <img src={close} alt="close window" title="close window" />
        </a>
      </div>
    </div>
  ) : null;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderGamePlayersData);
