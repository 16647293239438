import React, { useState, useEffect } from "react";
import "./CountdownTimer.css";
import CountdownFive from "../../../../Audio/CountdownFive.mp3";
import GetTimedate from "./GetTimedate";
import { useNavigate } from "react-router-dom";
import CriticalSoundCountDownTimer from "../../../MusicAndSounds/CriticalSoundCountDownTimer";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - RoomGameNumbers

const Countdown = ({
  roomData,
  counDownCondition,
  isCountDownTimerZeroAsync,
  deleteRoomAsync,
  resetRoomAsync,
  resetRoomsAsync,
  isExitRoomAsync,
  hamburgerCondition,
}) => {
  const navigate = useNavigate();

  const [
    thisRoom,
    thisRoomWhosTurn,
    hideCountDown,
    onNumberClick,
    roomId,
    exactLocation,
    isPageRefreshed,
    isDirectAccess,
    isExternalNavigation,
    yourTurnShow,
  ] = roomData;
  const [showSeconds, setShowSeconds] = useState(null);

  const poorConnect = "Weak";

  useEffect(() => {
    isCountDownTimerZeroAsync(false);

    let interval;

    // If poor connection, delete the room or if the game is finished
    const ifPoorConnection = () => {
      deleteRoomAsync(roomId);

      resetRoomAsync();

      resetRoomsAsync();

      isExitRoomAsync([false, ""]);

      navigate(`/${exactLocation}/rooms`);
    };
    // If poor connection, delete the room or if the game is finished

    if (thisRoom !== undefined && thisRoomWhosTurn !== undefined) {
      const timeEnd = thisRoom.duration + thisRoomWhosTurn.turnStart + 4;

      if (!isNaN(timeEnd)) {
        interval = setInterval(() => {
          const currentSeconds = GetTimedate();

          const remainingSeconds = timeEnd - currentSeconds;

          setShowSeconds(remainingSeconds);

          if (remainingSeconds <= 0 && thisRoomWhosTurn.gameStarted) {
            clearInterval(interval);

            isCountDownTimerZeroAsync(true);

            if (remainingSeconds < -60) {
              ifPoorConnection();
            }
          }
        }, 1000);
      }
    }

    return () => clearInterval(interval);
  }, [
    thisRoom,
    thisRoomWhosTurn,
    isCountDownTimerZeroAsync,
    deleteRoomAsync,
    exactLocation,
    isExitRoomAsync,
    navigate,
    resetRoomAsync,
    resetRoomsAsync,
    roomId,
  ]);

  useEffect(() => {
    const runonNumberClick = () => {
      onNumberClick();
    };

    if (thisRoomWhosTurn !== undefined) {
      if (counDownCondition && !thisRoomWhosTurn.gameOver && showSeconds < -1) {
        runonNumberClick();
      }
    }
  }, [counDownCondition, thisRoomWhosTurn, onNumberClick, showSeconds]);

  return (
    <div
      className={hideCountDown}
      title="Remaining time for the active player to pick a number."
    >
      {!hamburgerCondition ? (
        <CriticalSoundCountDownTimer
          Audiodata={[
            CountdownFive,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
            showSeconds,
            yourTurnShow,
          ]}
        />
      ) : null}
      <div>
        {showSeconds !== null && showSeconds >= 0
          ? showSeconds
          : showSeconds !== null && showSeconds < -6
            ? poorConnect
            : null}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Countdown);
