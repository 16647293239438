const DELETECOMPLETED = "DELETECOMPLETED";

const isDeleteCompleted = (check) => {
  return {
    type: DELETECOMPLETED,
    payload: check,
  };
};

export const isDeleteCompletedAsync = (check) => (dispatch) => {
  dispatch(isDeleteCompleted(check));
};
