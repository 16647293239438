import React, { useEffect, useState } from "react";
import "./NavPlace.css";
import close from "../../../Images/close.png";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import navBackgroundMusic from "../../../Audio/navBackgroundMusic.mp3";
import NavBackgroundMusic from "../../MusicAndSounds/NavBackgroundMusic";
import NavButtons from "../NavButtons/NavButtons";
import HowToPlay from "../NavPages/HowToPlay";
import AboutUs from "../NavPages/AboutUs";
import GameSettings from "../NavPages/GameSettings";
import ContactUs from "../NavPages/ContactUs";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function NavPlace({
  isClickedHamburgerAsync,
  hamburgerCondition,
  isClickedDeleteWindowAsync,
  isClickedLogoutWindowAsync,
  selectedClickedAvatarFeaturesAsync,
  isSelectedEditTypeAsync,
}) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    hamburgerCondition ? setHidden(false) : setHidden(true);
  }, [hamburgerCondition]);

  // to close the window
  const handleClose = (e) => {
    e.preventDefault();

    //play click
    ClickSound(popUpSound);

    !hidden && isClickedHamburgerAsync(false);

    isClickedLogoutWindowAsync(false);

    isClickedDeleteWindowAsync(false);

    selectedClickedAvatarFeaturesAsync("coin");

    isSelectedEditTypeAsync("");
  };
  // to close the window

  return !hidden ? (
    <section className="NavPlace">
      <NavBackgroundMusic
        Audiodata={[navBackgroundMusic, hamburgerCondition]}
      />

      <NavButtons />

      <HowToPlay />

      <AboutUs />

      <GameSettings />

      <ContactUs />

      <div className="close">
        <a href="/" onClick={handleClose}>
          <img src={close} alt="close window" title="close window" />
        </a>
      </div>
    </section>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(NavPlace);
