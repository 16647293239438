const initialSocialState = {
  isRoomAvailable: false,
};

const isRoomAvailableReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISROOMAVAILABLE":
      return {
        ...state,
        isRoomAvailable: action.payload,
      };

    default:
      return state;
  }
};

export default isRoomAvailableReducer;
