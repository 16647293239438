const initialSocialState = {
  isLogOutWindowOn: false,
};

const logOutWindowReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISCLICKEDLOGOUTWINDOW":
      return {
        ...state,
        isLogOutWindowOn: action.payload,
      };

    default:
      return state;
  }
};

export default logOutWindowReducer;
