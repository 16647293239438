import React, { useState, useEffect } from "react";
import "./EditForms.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import loginSound from "../../../Audio/createGame.mp3";
import EditCompletedFeatures from "./EditCompletedFeatures";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - EditFormGeneral

function EditUserName({
  isEditTypeCondition,
  editProfileAsync,
  isEditCompletedAsync,
  user,
  editRewardAsync,
}) {
  const [hidden, setHidden] = useState("hidden");

  const [hideForConfirm, setHideForConfirm] = useState("EditUserName_content");

  useEffect(() => {
    isEditTypeCondition === "username"
      ? setHidden("EditUserName")
      : setHidden("hidden");
  }, [isEditTypeCondition]);

  // Form error messages
  const [errForm, setErrForm] = useState({
    userName_err_display: "hidden",
  });

  // Distracture Form error messages
  const { userName_err_display } = errForm;
  // Form error messages

  // Form Data
  const [formData, setFormData] = useState({
    userName: "",
  });

  // Distracture Form Data
  const { userName } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  // Get Form Values

  // Handle edit username
  const handleEdit = async (userName) => {
    const token = user.token;

    // Data for userController
    const userData = {
      name: userName,
    };

    const authSuccess = await editProfileAsync(token, userData);

    const userId = user._id;

    // Data for rewardController
    const rewardData = {
      playerName: userName,
    };

    if (authSuccess) {
      isEditCompletedAsync("username");
      editRewardAsync(userId, rewardData);
    } else {
      isEditCompletedAsync("error");
    }
  };
  // Handle edit username

  const onSubmit = (e) => {
    e.preventDefault();

    //play click
    ClickSound(loginSound);

    // validate username
    const validSymbols = /^[a-zA-Z0-9]+$/;
    // validate username

    // temporary errors in a function, at the end of function assign to useState
    const tempErrForm = {
      ...errForm,
    };
    // temporary errors in a function, at the end of function assign to useState

    // temporary user data, check if all fields are good to send to back side
    const newUser = {
      newUserName: "",
    };
    // temporary user data, check if all fields are good to send to back side

    // username validation //edit
    if (
      userName.length < 4 ||
      userName.length > 16 ||
      !userName.match(validSymbols)
    ) {
      Object.assign(tempErrForm, {
        userName_err_display: "edit-userName_err_display",
      });

      Object.assign(newUser, {
        newUserName: "",
      });
    } else {
      Object.assign(tempErrForm, {
        userName_err_display: "hidden",
      });

      Object.assign(newUser, {
        newUserName: userName,
      });
    }
    // username validation //edit

    // errors send to usestate to control classes //edit
    setErrForm(tempErrForm);
    // errors send to usestate to control classes //edit

    // temporary user object values, //edit
    const checkUserName = Object.values(newUser)[0];
    // temporary user object values, //edit

    // temporary user object values, to check if all fields are ok to send to back side while edit
    if (checkUserName !== null) {
      handleEdit(checkUserName);

      setHideForConfirm("hidden");

      setHidden("Edit-hide");
    }
    // temporary user object values, to check if all fields are ok to send to back side while edit
  };

  return (
    <section className={hidden}>
      <EditCompletedFeatures />

      <div className={hideForConfirm}>
        {/* <h2>Edit Username</h2>  */}
        <div className="edit-form-err">
          {/* error messages */}
          <div className={userName_err_display}>
            <p>
              !!! Please enter a username between 4 and 16 characters. Use only
              letters and numbers. Symbols are not allowed.
            </p>
          </div>
          {/* error messages */}
        </div>
        <form className="form" onSubmit={onSubmit}>
          <div className="inputBox">
            <input
              type="text"
              name="userName"
              id="edit-userName"
              value={userName}
              onChange={onChange}
              required
              maxLength="16"
              pattern="[a-zA-Z0-9]*"
              title="The username must be between 4 and 16 letters long and can only contain letters and numbers."
              autoFocus
              autoComplete="username"
            />
            <i>New Username</i>
          </div>
          <div
            className="inputBox"
            title="Go ahead and enter your username if it is correct."
          >
            <button className="submit_BTN" type="submit">
              Enter
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserName);
