const initialSocialState = {
  isEditComleted: "",
};

const isCompletedEditReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "EDITCOMPLETED":
      return {
        ...state,
        isEditComleted: action.payload,
      };

    default:
      return state;
  }
};

export default isCompletedEditReducer;
