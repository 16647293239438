import { isClickedHamburgerAsync } from "./isClickedHamburger";
import { isClickedNavAsync } from "./isClickedNav";

import { userLoginAsync } from "./userAuth";

import { registerAsync } from "./userAuth";
import { resetAsync } from "./userAuth";
import { deleteUserAsync } from "./userAuth";
import { editProfileAsync } from "./userAuth";
import { logoutAsync } from "./userAuth";

import { isLogoutCompletedAsync } from "./logoutCompleted";
import { isDeleteCompletedAsync } from "./deleteCompleted";

import { isClickedLogoutWindowAsync } from "./isClickedLogoutWindow";
import { isClickedDeleteWindowAsync } from "./isClickedDeleteWindow";
import { selectedClickedAvatarFeaturesAsync } from "./avatarFeatures";
import { isSelectedEditTypeAsync } from "./isSelectedEditType";
import { isEditCompletedAsync } from "./isCompletedEdit";
import { isPlayerRoomAvailableAsync } from "./isPlayerRoomsAvailable";
import { isWatchingTheGameAsync } from "./isWatchingTheGame";
import { isExitRoomAsync } from "./exitRoom";

import {
  createRoomAsync,
  editRoomAsync,
  resetRoomsAsync,
  deleteRoomAsync,
  getAllRoomsAsync,
} from "./roomAuth";

import { getRoomByIdAsync, resetRoomAsync } from "./getSingleRoomData";
import { isOasisHelpClickedAsync } from "./oasisHelp";

import { isCountDownTimerZeroAsync } from "./isCountDownZero";
import { isHideRestartGameAsync } from "./hideRestartGame";
import { isForgetPasswordCompletedAsync } from "./forgetPasswordCompleted";

import {
  isSingleUserAsync,
  isMultiPlayerAsync,
  isTournamentAsync,
  isWorldAsync,
  isContinentalAsync,
  isCountryAsync,
  isCityAsync,
  isEventAsync,
  isSpecifiedTourTypeAsync,
} from "./gameType";

import {
  createRewardAsync,
  editRewardAsync,
  deleteRewardAsync,
  getAllRewardsAsync,
  resetRewardsAsync,
} from "./rewardsAuth";

import {
  getRewardByUserIdAsync,
  resetRewardAsync,
} from "./getSingleRewardData";
import { isMutedMusicAsync } from "./isMutedMusic";

const actions = {
  isClickedHamburgerAsync,
  isClickedNavAsync,

  userLoginAsync,
  registerAsync,
  resetAsync,
  logoutAsync,
  editProfileAsync,
  deleteUserAsync,
  isClickedLogoutWindowAsync,
  isLogoutCompletedAsync,
  isDeleteCompletedAsync,
  isForgetPasswordCompletedAsync,
  isClickedDeleteWindowAsync,
  isSelectedEditTypeAsync,
  isEditCompletedAsync,
  isSingleUserAsync,
  isMultiPlayerAsync,
  isTournamentAsync,
  isWorldAsync,
  isContinentalAsync,
  isCountryAsync,
  isCityAsync,
  isEventAsync,
  isSpecifiedTourTypeAsync,

  selectedClickedAvatarFeaturesAsync,
  isPlayerRoomAvailableAsync,
  isWatchingTheGameAsync,

  createRoomAsync,
  editRoomAsync,
  resetRoomsAsync,
  deleteRoomAsync,
  getAllRoomsAsync,
  isExitRoomAsync,
  getRoomByIdAsync,
  resetRoomAsync,
  isOasisHelpClickedAsync,
  isCountDownTimerZeroAsync,
  isHideRestartGameAsync,

  createRewardAsync,
  editRewardAsync,
  deleteRewardAsync,
  getAllRewardsAsync,
  resetRewardsAsync,
  getRewardByUserIdAsync,
  resetRewardAsync,
  isMutedMusicAsync,
};

export default actions;
