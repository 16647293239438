import React from "react";
import "./Error.css";

// parent component is - UserProfile

function Error() {
  return (
    <div className="UserProfile-err">
      <h2 className="UserProfile-err-text">
        Network connection error try again later
      </h2>
    </div>
  );
}

export default Error;
