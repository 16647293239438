import React from "react";
import "./Explanation.css";
import questionMark from "../../../../../Images/QuestionMarkIcon.png";

// parent component is - GameWatchingContainer

function Explanation() {
  return (
    <div className="GameWatchingExplanation">
      <div className="GameWatchingExplanationTextContainer">
        <p className="GameWatchingExplanationText">
          Here, you can watch other players&apos; games. If a game hasn&apos;t
          started yet, you&apos;ll see a &apos;Waiting&apos; status. Once the
          game begins, you can view the score and the time elapsed. At any time,
          you can create a profile, set up your own room, and play with others.
        </p>
      </div>
      <div className="GameWatchingExplanationIcon">
        <img
          className="GameWatchingExplanationIconImg"
          src={questionMark}
          title="Description"
          alt="Description"
        />
      </div>
    </div>
  );
}

export default Explanation;
