import React, { useEffect, useState } from "react";
import "./SwitchGameTypes.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import { Link, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

function SwitchGameTypes({
  singleCondition,
  multiPlayerCondition,
  tournamentCondition,
  isSingleUserAsync,
  isMultiPlayerAsync,
  isTournamentAsync,
  isPlayerRoomAvailableAsync,
}) {
  const location = useLocation();
  const exactLocation = location.pathname.split("/")[1];

  // Disable click when selected
  const [selectDisableClick, setSelectDisableClick] = useState({
    single: "",
    multi: "",
    tournament: "",
  });

  const { single, multi, tournament } = selectDisableClick;
  // Disable click when selected

  // game type is selected , visually different
  const [selectedType, setSelectedTye] = useState({
    single: "",
    multi: "",
    tournament: "",
  });

  useEffect(() => {
    if (exactLocation === "single-player") {
      setSelectedTye({
        single: "SwitchGameTypes-selected",
        multi: "",
        tournament: "",
      });

      setSelectDisableClick({
        single: "disable-link",
        multi: "",
        tournament: "",
      });

      isPlayerRoomAvailableAsync(false);
    } else if (exactLocation === "multi-player") {
      setSelectedTye({
        single: "",
        multi: "SwitchGameTypes-selected",
        tournament: "",
      });

      setSelectDisableClick({
        single: "",
        multi: "disable-link",
        tournament: "",
      });

      isPlayerRoomAvailableAsync(false);
    } else if (exactLocation === "tournaments") {
      setSelectedTye({
        single: "",
        multi: "",
        tournament: "SwitchGameTypes-selected",
      });

      setSelectDisableClick({
        single: "",
        multi: "",
        tournament: "disable-link",
      });
    }
  }, [exactLocation, isPlayerRoomAvailableAsync]);
  // game type is selected , visually different

  // clicked switch menu
  const handleSwitch = (e) => {
    //play click
    ClickSound(menuButtonsSound);

    if (e.target.name === "single" && !singleCondition) {
      isSingleUserAsync(true);

      isMultiPlayerAsync(false);

      isTournamentAsync(false);
    } else if (e.target.name === "multi" && !multiPlayerCondition) {
      isSingleUserAsync(false);

      isMultiPlayerAsync(true);

      isTournamentAsync(false);
    } else if (e.target.name === "tournament" && !tournamentCondition) {
      isSingleUserAsync(false);

      isMultiPlayerAsync(false);

      isTournamentAsync(true);
    } else {
      isSingleUserAsync(false);

      isMultiPlayerAsync(false);

      isTournamentAsync(false);
    }
  };
  // clicked switch menu

  return (
    <div
      className="SwitchGameTypes"
      title="There are three ways to play: solo, with a team, or in a tournament."
    >
      <h4>
        <Link
          to="/single-player"
          className={`${selectedType.single} ${single}`}
          name="single"
          onClick={handleSwitch}
        >
          1 vs 1 Battle
        </Link>
      </h4>{" "}
      |
      <h4>
        <Link
          to="/multi-player"
          className={`${selectedType.multi} ${multi}`}
          name="multi"
          onClick={handleSwitch}
        >
          2 vs 2 Battle
        </Link>
      </h4>{" "}
      |
      <h4>
        <Link
          to="/tournaments"
          className={`${selectedType.tournament} ${tournament}`}
          name="tournament"
          onClick={handleSwitch}
        >
          Tournaments
        </Link>
      </h4>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchGameTypes);
