import React, { useEffect, useState } from "react";
import "./TournamentsGameAreaButtons.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - TournamentsAreaContainer

function TournamentsGameAreaButtons({
  isTournamentAsync,
  isWorldAsync,
  isContinentalAsync,
  isCountryAsync,
  isCityAsync,
  isEventAsync,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const exactLocation = location.pathname.split("/")[2];

  // Disable click when already selected
  const [disableClick, setDisableClick] = useState({
    worldCupClick: "",
    continentalCupClick: "",
    countryCupClick: "",
    cityCupClick: "",
    eventCuplick: "",
  });

  const {
    worldCupClick,
    continentalCupClick,
    countryCupClick,
    cityCupClick,
    eventCuplick,
  } = disableClick;
  // Disable click when already selected

  useEffect(() => {
    if (exactLocation === "world-cup") {
      setDisableClick({
        worldCupClick: "disable",
        continentalCupClick: "",
        countryCupClick: "",
        cityCupClick: "",
        eventCuplick: "",
      });
    } else if (exactLocation === "coninental-cup") {
      setDisableClick({
        worldCupClick: "",
        continentalCupClick: "disable",
        countryCupClick: "",
        cityCupClick: "",
        eventCuplick: "",
      });
    } else if (exactLocation === "country-cup") {
      setDisableClick({
        worldCupClick: "",
        continentalCupClick: "",
        countryCupClick: "disable",
        cityCupClick: "",
        eventCuplick: "",
      });
    } else if (exactLocation === "city-cup") {
      setDisableClick({
        worldCupClick: "",
        continentalCupClick: "",
        countryCupClick: "",
        cityCupClick: "disable",
        eventCuplick: "",
      });
    } else if (exactLocation === "event-cup") {
      setDisableClick({
        worldCupClick: "",
        continentalCupClick: "",
        countryCupClick: "",
        cityCupClick: "",
        eventCuplick: "disable",
      });
    } else {
      setDisableClick({
        worldCupClick: "",
        continentalCupClick: "",
        countryCupClick: "",
        cityCupClick: "",
        eventCuplick: "",
      });

      isTournamentAsync(true);
      isWorldAsync(false);
      isContinentalAsync(false);
      isCountryAsync(false);
      isCityAsync(false);
      isEventAsync(false);
    }
  }, [
    exactLocation,
    isTournamentAsync,
    isWorldAsync,
    isContinentalAsync,
    isCountryAsync,
    isCityAsync,
    isEventAsync,
  ]);

  const worldCup = "World Cup";
  const continentalCup = "Continental Cup";
  const countryCup = "Country Cup";
  const cityCup = "City Cup";
  const eventCup = "Event cup";

  // clicked cup type
  const handleNav = (e) => {
    const clicked = e.target.name;

    //play click
    // ClickSound(LoadingSoundTournamentAreaSound);

    if (clicked === worldCup) {
      navigate("/tournaments/world-cup");
    } else if (clicked === continentalCup) {
      navigate("/tournaments/coninental-cup");
    } else if (clicked === countryCup) {
      navigate("/tournaments/country-cup");
    } else if (clicked === cityCup) {
      navigate("/tournaments/city-cup");
    } else if (clicked === eventCup) {
      navigate("/tournaments/event-cup");
    } else {
      navigate("/tournaments");
    }
  };
  // clicked cup type

  return (
    <div className="TournamentsGameAreaButtonPlace">
      <button
        className={`TourBtn ${worldCupClick}`}
        name={worldCup}
        data={worldCup}
        onClick={handleNav}
        title="World Cup Games"
      ></button>

      <button
        className={`TourBtn ${continentalCupClick}`}
        name={continentalCup}
        data={continentalCup}
        onClick={handleNav}
        title="Continental Cup Games"
      ></button>

      <button
        className={`TourBtn ${countryCupClick}`}
        name={countryCup}
        data={countryCup}
        onClick={handleNav}
        title="Country Cup Games"
      ></button>

      <button
        className={`TourBtn ${cityCupClick}`}
        name={cityCup}
        data={cityCup}
        onClick={handleNav}
        title="City Cup Games"
      ></button>

      <button
        className={`TourBtn ${eventCuplick}`}
        name={eventCup}
        data={eventCup}
        onClick={handleNav}
        title="Event Cup Games"
      ></button>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentsGameAreaButtons);
