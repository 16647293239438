import React, { useState, useEffect } from "react";
import "./Footer.css";
import ContactUsAppDownload from "../Navigation/ContactUsAppDownload/ContactUsAppDownload";
import ContactUsSocial from "../Navigation/ContactUsSocial/ContactUsSocial";

function FooterGeneral() {
  const [year, setYear] = useState("2024");

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    setYear(year);
  }, []);

  return (
    <footer className="Footer">
      <div className="footer_top_left">
        <ContactUsAppDownload />
      </div>
      <div className="footer_top_center"></div>
      <div className="footer_top_right">
        <ContactUsSocial />
      </div>
      <div className="footer_bottom_left"></div>
      <div className="footer_bottom_center">
        <h4 className="copyRight" title="Owned By Tiegrun">
          <a href="https://www.tieg.run/" target="_blank" rel="noreferrer">
            &copy; Tiegrun - {year}
          </a>{" "}
        </h4>
      </div>
      <div className="footer_bottom_right"></div>
    </footer>
  );
}

export default FooterGeneral;
