const ISCOUNTDOWNZERO = "ISCOUNTDOWNZERO";

const isCountDownTimerZero = (check) => {
  return {
    type: ISCOUNTDOWNZERO,
    payload: check,
  };
};

export const isCountDownTimerZeroAsync = (check) => (dispatch) => {
  dispatch(isCountDownTimerZero(check));
};
