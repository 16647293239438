import React, { Fragment } from "react";
import "./ContactUsSocial.css";
import youtube from "../../../Images/youtube.png";
import instagram from "../../../Images/instagram.png";
import facebook from "../../../Images/facebook.png";
import tiktok from "../../../Images/tiktok.png";

// parent component is - ContactUs

function ContactUsSocial() {
  const socials = {
    facebook: {
      id: "facebook",
      URL: "https://www.facebook.com/profile.php?id=100095429027354",
    },
    instagram: {
      id: "instagram",
      URL: "https://www.instagram.com/198runners/",
    },
    youtube: {
      id: "youtube",
      URL: "https://www.youtube.com/@198runners",
    },
    tiktok: {
      id: "tiktok",
      URL: "https://www.tiktok.com/@198runners",
    },
  };

  return (
    <Fragment>
      <a
        href={socials.facebook.URL}
        target="_blank"
        rel="noreferrer"
        className="social_icons"
      >
        <img
          src={facebook}
          id={socials.facebook.id}
          title="facebook"
          alt="facebook"
        />
      </a>

      <a
        href={socials.instagram.URL}
        target="_blank"
        rel="noreferrer"
        className="social_icons"
      >
        <img
          src={instagram}
          id={socials.instagram.id}
          title="instagram"
          alt="instagram"
        />
      </a>

      <a
        href={socials.youtube.URL}
        target="_blank"
        rel="noreferrer"
        className="social_icons"
      >
        <img
          src={youtube}
          id={socials.youtube.id}
          title="youtube"
          alt="youtube"
        />
      </a>

      <a
        href={socials.tiktok.URL}
        target="_blank"
        rel="noreferrer"
        className="social_icons"
      >
        <img src={tiktok} id={socials.tiktok.id} title="tiktok" alt="tiktok" />
      </a>
    </Fragment>
  );
}

export default ContactUsSocial;
