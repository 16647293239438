import React from "react";
import "./AllSingleRoomsContainerArea.css";
import LoaderSimple from "../../../Loading/LoaderSimple";
import AllSingleRoomsContainer from "../AllSingleRoomsContainer/AllSingleRoomsContainer";

// parent component is - SinglePlayerGame

function AllSingleRoomsContainerArea() {
  return (
    <section className="AllSingleRoomsContainerArea">
      <div className="AllSingleRoomsContainerArea-loading">
        <LoaderSimple />
      </div>

      <AllSingleRoomsContainer />
    </section>
  );
}

export default AllSingleRoomsContainerArea;
