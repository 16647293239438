import React, { useEffect, useState } from "react";
import "./TournamentsAreaContainer.css";
import tournamentBackgroundSound from "../../../../Audio/tournamentBackground.mp3";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import TournamentsGameAreaButtons from "../TournamentsGameAreaButtons/TournamentsGameAreaButtons";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import TournamentsArea from "../TournamentsArea/TournamentsArea";
import SwitchGameTypes from "../../SwitchGameTypes/SwitchGameTypes";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import { Outlet, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function TournamentsAreaContainer({
  hamburgerCondition,
  tournamentCondition,
  isTournamentAsync,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const location = useLocation();
  const exactLocation = location.pathname.split("/").length;
  const [isHidden, setIsHidden] = useState("TournamentsAreaContainer");
  const [preventLoader, setPreventLoader] = useState("");

  //tournament page requirements
  useEffect(() => {
    !tournamentCondition && isTournamentAsync(true);
  });
  //tournament page requirements

  //prevent double loading loaders
  useEffect(() => {
    if (exactLocation === 2) {
      setPreventLoader("TournamentsAreaContainer_anime");
    } else {
      setPreventLoader("transparent");
    }
  }, [exactLocation]);
  //prevent double loading loaders

  //is clicked hamburger
  useEffect(() => {
    !hamburgerCondition
      ? setIsHidden("TournamentsAreaContainer")
      : setIsHidden("hidden");
  }, [hamburgerCondition]);
  //is clicked hamburger

  return (
    <section className={isHidden}>
      <div className="TournamentsAreaContainer_place">
        {!hamburgerCondition ? (
          <BackGroundMusic
            Audiodata={[
              tournamentBackgroundSound,
              isPageRefreshed,
              isDirectAccess,
              isExternalNavigation,
            ]}
          />
        ) : null}

        <TournamentsGameAreaButtons />

        <div className={preventLoader}>
          <LoadingHexagon />
        </div>

        <TournamentsArea />

        <Outlet />

        <SwitchGameTypes />
      </div>
    </section>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentsAreaContainer);
