import React, { useState, useEffect } from "react";
import "./ForgetPasswordForm.css";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SigninPlace

function ForgetPasswordCompletedMessage({
  isForgetPasswordCompletedAsync,
  isForgetPasswordCompletedCondition,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    isForgetPasswordCompletedCondition
      ? setHidden("ForgetPasswordCompletedMessage")
      : setHidden("hidden");

    return () => {
      isForgetPasswordCompletedAsync(false);
    };
  }, [isForgetPasswordCompletedCondition, isForgetPasswordCompletedAsync]);

  return (
    <div className={hidden}>
      <h2>Please, check your E-mail.</h2>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgetPasswordCompletedMessage);
