import React, { useState, useEffect } from "react";
import "./TournamentsArea.css";
import "./TournamentsAreaAnimation.css";
import LoadingSoundTournamentAreaSound from "../../../../Audio/LoadingSoundTournamentArea.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import cup from "../../../../Images/cup.png";
import { useLocation } from "react-router-dom";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - TournamentsAreaContainer

function TournamentsArea({ hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const location = useLocation();
  const exactLocation = location.pathname.split("/").length;

  const [isHidden, setIsHidden] = useState("TournamentsArea");

  //if clicked tournament type, hide quote page
  useEffect(() => {
    exactLocation > 2 ? setIsHidden("hidden") : setIsHidden("TournamentsArea");
  }, [exactLocation]);
  //if clicked tournament type, hide quote page

  return (
    <section className={isHidden}>
      {!hamburgerCondition && exactLocation === 2 ? (
        <LoadingSoundGameArea
          Audiodata={[
            LoadingSoundTournamentAreaSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <img
        className="TournamentsArea-img"
        src={cup}
        title="CUP | 198 Runners"
        alt="cup"
      />

      <div className="TournamentsArea-blob"></div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TournamentsArea);
