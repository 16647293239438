import React, { useEffect, useState } from "react";
import "./AvatarEditCard.css";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarEdittingPlace({
  avatarFeatureCondition,
  isEditTypeCondition,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    setHidden(
      deleteWindowCondition
        ? "hidden"
        : avatarFeatureCondition === "edit" && isEditTypeCondition === ""
          ? !logOutWindowCondition
            ? "AvatarEdittingPlace"
            : "hidden"
          : "hidden",
    );
  }, [
    avatarFeatureCondition,
    isEditTypeCondition,
    logOutWindowCondition,
    deleteWindowCondition,
  ]);

  return (
    <div className={hidden}>
      <div className="AvatarEdittingPlace-container">
        <div className="AvatarEdittingPlace-content">
          <h2 className="AvatarEdittingPlace-frame-1">EDIT USERNAME</h2>
          <h2 className="AvatarEdittingPlace-frame-2">EDIT E-MAIL</h2>
          <h2 className="AvatarEdittingPlace-frame-3">EDIT PASSWORD</h2>
          <h2 className="AvatarEdittingPlace-frame-4">TRY IT!</h2>
          <h2 className="AvatarEdittingPlace-frame-5">
            <span>KEEP THE </span>
            <span>CHANGES</span>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvatarEdittingPlace);
