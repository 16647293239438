import React, { useEffect, useState } from "react";
import "./EventCup.css";
import "./EventCupAnimation.css";
import Temporary from "../../Temporary/Temporary";
import LoaderDots from "../../../Loading/LoaderDots";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// parent component is - App

function Event() {
  const routeParams = useParams();

  const [eventType, setEvenetType] = useState("");

  useEffect(() => {
    if (routeParams.id === "event-2") {
      setEvenetType("Event #2");
    } else if (routeParams.id === "event-6") {
      setEvenetType("Event #6");
    } else if (routeParams.id === "event-9") {
      setEvenetType("Event #9");
    } else {
      setEvenetType("Event #2");
    }
  }, [eventType, routeParams.id]);

  return (
    <div className="singleEvent">
      <div className="singleEvent_selected">{eventType}</div>

      <div className="singleEvent_selected_loading">
        <LoaderDots />
      </div>

      <Temporary />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);
