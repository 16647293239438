import React from "react";
import "./UnAbleToCreate.css";

// parent component is - RedirectToCreatedRoom

function UnAbleToCreate() {
  return (
    <div className="UnAbleToCreate">
      <p className="UnAbleToCreate-text">An error occurred. Please try again</p>
    </div>
  );
}

export default UnAbleToCreate;
