const initialSocialState = {
  isMusicOff: false,
};

const mutedMusicReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISMUTEDMUSICS":
      return {
        ...state,
        isMusicoff: action.payload,
      };

    default:
      return state;
  }
};

export default mutedMusicReducer;
