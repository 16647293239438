import React, { useState, useEffect } from "react";
import "./Header.css";
import "./HeaderAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import logo from "../../../Images/logo.png";
import Hamburger from "../Hamburg/Hamburger";
import UserPlace from "../UserPlace/UserPlace";
import { Link, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function HeaderGeneral({
  isClickedHamburgerAsync,
  isClickedLogoutWindowAsync,
  isClickedDeleteWindowAsync,
  isSelectedEditTypeAsync,
  selectedClickedAvatarFeaturesAsync,
  isEditCompletedAsync,
}) {
  const location = useLocation();
  const exactLocations = location.pathname.split("/");

  const [hide, setHide] = useState(true);

  // STYLE --- Screen size/height applies for hamburger side
  const [smallScreen, setSmallScreen] = useState();

  useEffect(() => {
    const checkHeight = () => {
      if (window.innerHeight >= 751) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };

    // Initial check
    checkHeight();

    // Add an event listener to respond to changes in window height
    window.addEventListener("resize", checkHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);
  // STYLE --- Screen size/height applies for hamburger side

  // if selected rooms to play, hide the general header
  useEffect(() => {
    exactLocations[2] !== "rooms" || exactLocations[2] === "undefined"
      ? setHide(false)
      : setHide(true);
  }, [exactLocations]);
  // if selected rooms to play, hide the general header

  const handleHome = () => {
    //play click
    ClickSound(menuButtonsSound);

    // close the menu when clicked home
    isClickedHamburgerAsync(false);

    // user logout window disabled
    isClickedLogoutWindowAsync(false);

    // user delete window disabled
    isClickedDeleteWindowAsync(false);

    // user edit type window disabled
    isSelectedEditTypeAsync("");

    // user profile first page
    selectedClickedAvatarFeaturesAsync("coin");

    // user profile reset edits
    isEditCompletedAsync("");
  };

  return !hide ? (
    <header className="HeaderGeneral">
      <div className="HeaderGeneral_left">
        <div className="HeaderGeneral_left_hamburgerPlace">
          {smallScreen ? <Hamburger /> : null}
        </div>
      </div>

      <div className="HeaderGeneral_logo_place">
        <Link to="/" onClick={handleHome}>
          <img
            className="HeaderGeneral_logo_place_mainLogo_general"
            src={logo}
            title="Home | 198 Runners"
            alt="logo"
          />
        </Link>
      </div>

      <div className="HeaderGeneral_right">
        <div className="HeaderGeneral_right_hamburgerPlace">
          {!smallScreen ? <Hamburger /> : null}
        </div>
        <div className="HeaderGeneral_right_loginPlace">
          <UserPlace />
        </div>
      </div>
    </header>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderGeneral);
