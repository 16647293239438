import React, { Fragment } from "react";
import "./ContactUsAppDownload.css";
import playStore from "../../../Images/playStore.png";
import appleStore from "../../../Images/appleStore.png";

function ContactUsAppDownload() {
  const downLoadApps = {
    playStore: {
      id: "playStore",
      URL: "/",
    },
    appleStore: {
      id: "appleStore",
      URL: "/",
    },
  };

  return (
    <Fragment>
      <a
        href={downLoadApps.playStore.URL}
        target="_blank"
        rel="noreferrer"
        className="storeApp_icons"
      >
        <img
          src={playStore}
          id={downLoadApps.playStore.id}
          title="PlayStore"
          alt="playStore"
        />
      </a>

      <a
        href={downLoadApps.appleStore.URL}
        target="_blank"
        rel="noreferrer"
        className="storeApp_icons"
      >
        <img
          src={appleStore}
          id={downLoadApps.appleStore.id}
          title="AppleStore"
          alt="appleStore"
        />
      </a>
    </Fragment>
  );
}

export default ContactUsAppDownload;
