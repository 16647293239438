const initialSocialState = {
  isNav: "HOW TO PLAY",
};

const navReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISCLICKEDNAV":
      return {
        ...state,
        isNav: action.payload,
      };

    default:
      return state;
  }
};

export default navReducer;
