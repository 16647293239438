import React, { useEffect, useState } from "react";
import "./DeleteWindow.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import deleteAccountSound from "../../../Audio/deleteAccountSound.mp3";
import popUpSound from "../../../Audio/popUp.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function DeleteAccountWindow({
  user,
  isClickedDeleteWindowAsync,
  deleteWindowCondition,
  deleteUserAsync,
  isDeleteCompletedAsync,
  resetAsync,
  deleteRewardAsync,
  resetRewardsAsync,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    deleteWindowCondition ? setHidden("delete-container") : setHidden("hidden");

    return;
  }, [deleteWindowCondition]);

  const handleClick = async (e) => {
    if (e.target.name === "delete") {
      const userToken = user.token;

      //play click
      ClickSound(deleteAccountSound);

      try {
        // Attempt to delete user
        const authSuccess = await deleteUserAsync(userToken);

        if (authSuccess) {
          console.log("User deleted successfully");

          // Attempt to delete user's reward
          try {
            await deleteRewardAsync(user._id);

            console.log("Reward deleted successfully");

            // Reset rewards after deletion
            await resetRewardsAsync();

            console.log("Rewards reset successfully");

            // Mark delete completion and reset state
            isDeleteCompletedAsync(true);
            resetAsync();
          } catch (error) {
            console.error("Error deleting reward:", error);
            // Handle error deleting reward
          }
        } else {
          console.log("User deletion failed");
          // Optionally handle failure case
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        // Handle error deleting user
      }
    } else {
      // Handle other actions (if any)
      isClickedDeleteWindowAsync(false);

      //play click
      ClickSound(popUpSound);
    }
  };

  return (
    <div className={hidden}>
      <h2>Confirm to delete account?</h2>

      <div className="delete-container_Btn_Place">
        <button
          className="delete-container_Btn_Place_button"
          name="cancel"
          onClick={handleClick}
        >
          <div className="delete-container_Btn_Place_text">Cancel</div>
        </button>
        <button
          className="delete-container_Btn_Place_button"
          name="delete"
          onClick={handleClick}
        >
          <div className="delete-container_Btn_Place_text">Delete</div>
        </button>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteAccountWindow);
