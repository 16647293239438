import React from "react";
import "./Loading.css";
import "./LoadingAnimation.css";

function LoadingBigHexagon() {
  return (
    <div className="LoadingHexagonBig">
      <div className="bigHex center_bigHex">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn1 bigHexRow1">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn2 bigHexRow1">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn3 bigHexRow1">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn4 bigHexRow1">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn5 bigHexRow1">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn6 bigHexRow1">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn7 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn8 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn9 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn10 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn11 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn12 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn13 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn14 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn15 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn16 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn17 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn18 bigHexRow2">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn19 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn20 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn21 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn22 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn23 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn24 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn25 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn26 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn27 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn28 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn29 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn30 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn31 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn32 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn33 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn34 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn35 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>

      <div className="bigHex hexColumn36 bigHexRow3">
        <div className="LoadingHexagonBig_warm"></div>
        <div className="LoadingHexagonBig_warm hexHead1"></div>
        <div className="LoadingHexagonBig_warm hexHead2"></div>
      </div>
    </div>
  );
}

export default LoadingBigHexagon;
