const OASISHELPTYPE = "OASISHELPTYPE";

const isOasisHelpClicked = (check) => {
  return {
    type: OASISHELPTYPE,
    payload: check,
  };
};

export const isOasisHelpClickedAsync = (check) => (dispatch) => {
  dispatch(isOasisHelpClicked(check));
};
