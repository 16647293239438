import React, { useState, useEffect } from "react";
import "./CreateRoomContainer.css";
import "./CreateRoomContainerAnimation.css";
import close from "../../../../Images/close.png";
import createGameBackground from "../../../../Audio/createGameBackground.mp3";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import LoadingInfinity from "../../../Loading/LoadingInfinity";
import CreateRoom from "../CreateRoom/CreateRoom";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function CreateRoomContainer({ authStateCondition, hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const location = useLocation();
  const exactLocation = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const { user } = authStateCondition;

  const [userId, setUserId] = useState(undefined);
  const [userName, setUserName] = useState(undefined);

  // if user is not logged in, then navigate out
  useEffect(() => {
    if (user === null && user === undefined) {
      setUserId(undefined);

      navigate(`/${exactLocation}`);
    } else {
      setUserName(user.name);

      setUserId(user._id);
    }
  }, [user, navigate, exactLocation]);
  // if user is not logged in, then navigate out

  return (
    <div className="CreateRoomContainer-bgr">
      <div className="CreateRoomContainer">
        {!hamburgerCondition ? (
          <BackGroundMusic
            Audiodata={[
              createGameBackground,
              isPageRefreshed,
              isDirectAccess,
              isExternalNavigation,
            ]}
          />
        ) : null}
        <div className="CreateRoomContainer_infinityPlace">
          <LoadingInfinity />
        </div>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span> <span></span> <span></span>
        <span></span>
        <CreateRoom roomData={[userId, userName, exactLocation]} />
        <div className="close">
          <a href={`/${exactLocation}`}>
            <img src={close} alt="close window" title="close window" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateRoomContainer);
