import React, { useEffect, useState } from "react";
import "./WaitingForOtherPlayers.css";
import "./WaitingForOtherPlayersAnimation.css";
import waiting from "../../../../../Images/waiting.png";
import timerSound from "../../../../../Audio/timer.mp3";
import backGroundSound from "../../../../../Audio/backGroundMusic.mp3";
import BackGroundMusic from "../../../../MusicAndSounds/BackGroundMusic";
import LoaderSimple from "../../../../Loading/LoaderSimple";
import WaitingClock from "./WaitingClock";
import LoadingInfinity from "../../../../Loading/LoadingInfinity";
import GetTimedate from "../../CountdownTimer/GetTimedate";
import SoundCountDownTimer from "../../../../MusicAndSounds/SoundCountDownTimer";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - RoomGameNumbers

function WaitingForOtherPlayers({
  newRoomData,
  isWatchingTheGameAsync,
  editRoomAsync,
  getRoomByIdAsync,
  hamburgerCondition,
}) {
  // newRoomData is coming from parent component - RoomGameNumbers
  const [
    exactLocation,
    roomId,
    playersCount,
    indexOfPlayer,
    thisRoomWhosTurn,
    isPageRefreshed,
    isDirectAccess,
    isExternalNavigation,
  ] = newRoomData;
  // newRoomData is coming from parent component - RoomGameNumbers

  // for timer / in seconds
  const differenceSeconds = GetTimedate();
  // for timer / in seconds

  const [hidden, setHidden] = useState(false);

  // get room data when the page is opened
  useEffect(() => {
    getRoomByIdAsync(roomId);
  }, [getRoomByIdAsync, roomId]);
  // get room data when the page is opened

  // check if there are enough players to start the game
  useEffect(() => {
    // Game current score
    const gameCurrentNumber =
      thisRoomWhosTurn !== undefined
        ? thisRoomWhosTurn.currentNumber
        : undefined;
    // Game started
    const gameStarted =
      thisRoomWhosTurn !== undefined ? thisRoomWhosTurn.gameStarted : undefined;

    // when enough players, send this data to backend
    const startGame = {
      whosTurn: {
        gameStarted: true,
        currentNumber: 0,
        turnStart: differenceSeconds,
        gameOver: false,
        specialRandom: Math.floor(100000 + Math.random() * 900000),

        player1: {
          turn: true,
          restart: false,
        },

        player2: {
          turn: false,
          restart: false,
        },

        player3: {
          turn: false,
          restart: false,
        },

        player4: {
          turn: false,
          restart: false,
        },

        helpOasis: {
          oasis1: {
            double: 3,
            extra: 3,
            minus: 3,
            added: 3,
            bonus: 3,
            matched: 0,
          },
          oasis2: {
            double: 3,
            extra: 3,
            minus: 3,
            added: 3,
            bonus: 3,
            matched: 0,
          },
          oasis3: {
            double: 3,
            extra: 3,
            minus: 3,
            added: 3,
            bonus: 3,
            matched: 0,
          },
          oasis4: {
            double: 3,
            extra: 3,
            minus: 3,
            added: 3,
            bonus: 3,
            matched: 0,
          },
        },
      },
    };
    // when enough players, send this data to backend

    if (
      ((exactLocation === "single-player" && playersCount === 2) ||
        (exactLocation === "multi-player" && playersCount === 4)) &&
      gameCurrentNumber === 0
    ) {
      if (!gameStarted) {
        setHidden(true);

        editRoomAsync(roomId, startGame);

        // when the Game is over, starts again if all players want to
      } else {
        setHidden(false);
      }
    } else if (gameCurrentNumber !== 0) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [
    playersCount,
    exactLocation,
    roomId,
    indexOfPlayer,
    isWatchingTheGameAsync,
    editRoomAsync,
    thisRoomWhosTurn,
    differenceSeconds,
  ]);
  // check if there are enough players to start the game

  return !hidden ? (
    <div className="WaitingForOtherPlayers-container">
      {!hamburgerCondition ? (
        <SoundCountDownTimer
          Audiodata={[
            timerSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      {!hamburgerCondition ? (
        <BackGroundMusic
          Audiodata={[
            backGroundSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="WaitingForOtherPlayers-loading-first">
        <LoaderSimple />
      </div>

      <div className="WaitingForOtherPlayers-text"></div>

      <WaitingClock />

      <div className="WaitingForOtherPlayers-img-container">
        <img
          className="WaitingForOtherPlayers-img"
          src={waiting}
          name="waiting"
          title="Waiting for others"
          alt="Waiting for others"
        />
      </div>

      <div className="WaitingForOtherPlayers-loading">
        <LoadingInfinity />
      </div>
    </div>
  ) : null;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitingForOtherPlayers);
