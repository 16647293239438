import React from "react";
import "./NoAvailableRoom.css";
import LoaderSimple from "../../Loading/LoaderSimple";

// parent component is - AllSingleRooms

function NoAvailableRoom() {
  return (
    <div className="noEmptyRoom">
      <div className="noEmptyRoom-loading">
        <LoaderSimple />
      </div>

      <h5>There are no rooms available</h5>
    </div>
  );
}

export default NoAvailableRoom;
