// Initial State
const initialState = {
  reward: "",
  rewards: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const authRewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_REWARD_REQUEST":
    case "EDIT_REWARD_REQUEST":
    case "DELETE_REWARD_REQUEST":
    case "GET_ALL_REWARDS_REQUEST":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: "",
      };

    case "CREATED_REWARD_SUCCESS":
      return {
        ...state,
        reward: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "EDIT_REWARD_SUCCESS":
      return {
        ...state,
        reward: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "GET_ALL_REWARDS_SUCCESS":
      return {
        ...state,
        rewards: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "CREATE_REWARD_FAILURE":
    case "DELETE_REWARD_FAILURE":
    case "EDIT_REWARD_FAILURE":
    case "GET_ALL_REWARDS_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
      };

    case "DELETE_REWARD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "RESETREWARDS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: "",
      };

    default:
      return state;
  }
};

export default authRewardReducer;
