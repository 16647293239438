import React, { useRef, useEffect } from "react";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../State/Store/storeProps";
import { connect } from "react-redux";

const GameOverBackGroundMusic = ({ Audiodata, mutedMusicCondition }) => {
  // Page Access Type
  const [
    backGroundSound,
    isPageRefreshed,
    isDirectAccess,
    isExternalNavigation,
  ] = Audiodata;
  // Page Access Type

  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    // Check if any of the conditions are true
    if (
      isPageRefreshed ||
      isPageRefreshed === undefined ||
      isDirectAccess ||
      isDirectAccess === undefined ||
      isExternalNavigation ||
      isExternalNavigation === undefined
    ) {
      // Skip playing the sound

      return;
    }

    const handleEnded = () => {
      audio.currentTime = 0;
    };

    if (audio) {
      audio.play().catch((err) => console.log(err));
      audio.addEventListener("ended", handleEnded);
      // Mute or unmute the audio based on mutedMusicCondition
      audio.volume = mutedMusicCondition ? 0 : 1;
    }

    return () => {
      if (audio) {
        audio.pause();
        audio.removeEventListener("ended", handleEnded);
      }
    };
  }, [
    isPageRefreshed,
    isDirectAccess,
    isExternalNavigation,
    mutedMusicCondition,
  ]);

  return (
    <audio ref={audioRef}>
      <source src={backGroundSound} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GameOverBackGroundMusic);
