import React, { useEffect } from "react";
import "./WorldCup.css";
import "./WorldCupAnimation.css";
import LoadingSoundTournamentAreaSound from "../../../../Audio/LoadingSoundTournamentArea.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import worldmap from "../../../../Images/worldmap.png";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import Temporary from "../../Temporary/Temporary";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function WorldCup({ isWorldAsync, worldCondition, hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  //world page requirements
  useEffect(() => {
    !worldCondition && isWorldAsync(true);
  }, [isWorldAsync, worldCondition]);
  //world page requirements

  return (
    <section className="WorldCup">
      {!hamburgerCondition ? (
        <LoadingSoundGameArea
          Audiodata={[
            LoadingSoundTournamentAreaSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="WorldCup_loading">
        <LoadingHexagon />
      </div>

      <div className="WorldCup_map_place">
        <img
          id="WorldCup_map"
          src={worldmap}
          title="world map"
          alt="world map"
        />
      </div>

      <Temporary />
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(WorldCup);
