import React from "react";
import "./MenuButtons.css";
import "./MenuButtonsAnimation.css";
import SingleUserButton from "./SingleUserButton";
import MultiPlayerButton from "./MultiPlayerButton";
import TournamentsButton from "./TournamentsButton";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - General

function AllButtons() {
  return (
    <section className="AllButtons">
      <SingleUserButton />

      <MultiPlayerButton />

      <TournamentsButton />
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllButtons);
