import React, { useEffect, useState } from "react";
import "./CityCup.css";
import "./CityCupAnimation.css";
import LoadingSoundTournamentAreaSound from "../../../../Audio/LoadingSoundTournamentArea.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import CityList from "./CityList";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import { Outlet } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function CityCup({ isCityAsync, cityCondition, hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  //city page requirements
  useEffect(() => {
    !cityCondition && isCityAsync(true);
  }, [isCityAsync, cityCondition]);
  //city page requirements

  return (
    <section className="CityCup">
      {!hamburgerCondition ? (
        <LoadingSoundGameArea
          Audiodata={[
            LoadingSoundTournamentAreaSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="CityCup_loading">
        <LoadingHexagon />
      </div>

      <div className="CityCup_cities">
        <Outlet />

        <CityList />
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CityCup);
