import React, { useEffect } from "react";
import "./CountryCup.css";
import "./CountryCupAnimation.css";
import LoadingSoundTournamentAreaSound from "../../../../Audio/LoadingSoundTournamentArea.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import CountryList from "./CountryList";
import { Outlet } from "react-router-dom";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function CountryCup({ isCountryAsync, countryCondition, hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  //country page requirements
  useEffect(() => {
    !countryCondition && isCountryAsync(true);
  }, [isCountryAsync, countryCondition]);
  //country page requirements

  return (
    <section className="CountryCup">
      {!hamburgerCondition ? (
        <LoadingSoundGameArea
          Audiodata={[
            LoadingSoundTournamentAreaSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="CountryCup_loading">
        <LoadingHexagon />
      </div>

      <div className="CountryCup_countries">
        <Outlet />

        <CountryList />
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryCup);
