import React, { useState, useEffect } from "react";
import "./AvatarImageCard.css";
import "./AvatarImageCardAnimation.css";
import avatar from "../../../../Images/yesUser.png";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarImageCard({
  avatarFeatureCondition,
  userData,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  const [playerName, levelStatus, categories] = userData;

  // get user data
  const [userName, setUserName] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [userPremium, setUserPremium] = useState("");

  useEffect(() => {
    if (
      playerName !== null &&
      playerName !== undefined &&
      levelStatus !== null &&
      levelStatus !== undefined &&
      categories !== null &&
      categories !== null
    ) {
      let status = Object.keys(levelStatus).find(
        (key) => levelStatus[key] === true,
      );

      const findPremium = (categories) => {
        const premiumExist = Object.keys(categories).find(
          (key) => categories[key] === true,
        );

        return premiumExist || "none";
      };

      let premium = findPremium(categories);

      setUserName(playerName.charAt(0).toUpperCase() + playerName.slice(1));

      setUserStatus(status.charAt(0).toUpperCase() + status.slice(1));

      setUserPremium(premium.charAt(0).toUpperCase() + premium.slice(1));
    } else {
      setUserName(null);

      setUserStatus(null);

      setUserPremium(null);
    }
  }, [playerName, levelStatus, categories]);
  // get user data

  // hidden, if clicked edit Profile
  const [hidden, setHidden] = useState("AvatarImageCard");

  useEffect(() => {
    avatarFeatureCondition === "edit" ||
    logOutWindowCondition ||
    deleteWindowCondition
      ? setHidden("hidden")
      : setHidden("AvatarImageCard");
  }, [avatarFeatureCondition, logOutWindowCondition, deleteWindowCondition]);
  // hidden, if clicked edit Profile

  return (
    <div className={hidden}>
      <img id="avatar_image" src={avatar} alt="avatar" />
      <div className="avatar_userinfo_card">
        <h3>
          User - <span className="avatar_userinfo_cardEmph">{userName}</span>
        </h3>
        <h3>
          Status -{" "}
          <span className="avatar_userinfo_cardEmph">{userStatus}</span>
        </h3>
        <h3>
          Premium -{" "}
          <span className="avatar_userinfo_cardEmph">{userPremium}</span>
        </h3>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarImageCard);
