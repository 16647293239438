const FORGETPASSWORDCOMPLETED = "FORGETPASSWORDCOMPLETED";

const isForgetPasswordCompleted = (check) => {
  return {
    type: FORGETPASSWORDCOMPLETED,
    payload: check,
  };
};

export const isForgetPasswordCompletedAsync = (check) => (dispatch) => {
  dispatch(isForgetPasswordCompleted(check));
};
