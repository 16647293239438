import React, { useEffect, useState } from "react";
import "./RedirectToCreatedRoom.css";
import LoadingInfinity from "../../../Loading/LoadingInfinity";
import UnAbleToCreate from "../UnAbleToCreate/UnAbleToCreate";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - CreateRoom

function RedirectToCreatedRoom({ roomData }) {
  const [createdUserId, loadStatus, room] = roomData;

  const navigate = useNavigate();

  const [hidden, setHidden] = useState(true);
  const [hiddenLoader, setHiddenLoader] = useState(true);
  const [hiddenError, setHiddenError] = useState(true);

  useEffect(() => {
    let redirect;

    if (createdUserId !== undefined && room !== null && room !== "") {
      if (loadStatus === "loading") {
        setHidden(false);
        setHiddenLoader(false);
        setHiddenError(true);
      } else if (loadStatus === "success") {
        const navigateToURL = room._id;
        const gameType = room.gameType;

        setHidden(false);
        setHiddenLoader(false);
        setHiddenError(true);

        redirect = setTimeout(() => {
          navigate(`/${gameType}/rooms/${navigateToURL}`);
        }, 2000);
      }
    } else if (loadStatus === "error") {
      setHidden(false);

      setHiddenLoader(true);
      setHiddenError(false);

      redirect = setTimeout(() => {
        navigate("/single-player");
      }, 4000);
    }

    return () => clearTimeout(redirect);
  }, [createdUserId, loadStatus, navigate, room]);

  return !hidden ? (
    <div className="RedirectToCreatedRoom">
      {!hiddenLoader ? (
        <div className="RedirectToCreatedRoom-loading">
          <LoadingInfinity />
        </div>
      ) : null}

      {!hiddenError ? <UnAbleToCreate /> : null}
    </div>
  ) : null;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RedirectToCreatedRoom);
