import React from "react";
import "./ProfileBackground.css";
import "./ProfileBackgroundAnimation.css";
import close from "../../../../Images/close.png";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import popUpSound from "../../../../Audio/popUp.mp3";
import LoadingInfinity from "../../../Loading/LoadingInfinity";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SigninPlace
// parent component is - RegistrationPlace
// parent component is - ForgetPasswordPlace

function SigninBackground() {
  const navigate = useNavigate();

  // to close the window
  const handleClose = (e) => {
    e.preventDefault();

    //play click
    ClickSound(popUpSound);

    navigate("/");
  };
  // to close the window

  return (
    <div className="SigninBackground">
      <div className="SigninBackground_infinityPlace">
        <LoadingInfinity />
      </div>
      <span></span> <span></span> <span></span> <span></span>
      <span></span> <span></span> <span></span> <span></span>
      <span></span> <span></span> <span></span> <span></span>
      <span></span> <span></span> <span></span> <span></span>
      <span></span> <span></span> <span></span> <span></span>
      <div className="close">
        <a href="/" onClick={handleClose}>
          <img src={close} alt="close window" title="close window" />
        </a>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninBackground);
