import React, { useEffect, useState } from "react";
import "./GameScore.css";
import "./GameScoreAnimation.css";

// parent component is - RoomGameNumbers

function GameScore({ gameScoreData }) {
  const [thisRoomWhosTurn, hidenGameScore] = gameScoreData;

  const [currentScore, setCurrentScore] = useState("0");

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      if (thisRoomWhosTurn.currentNumber !== 0) {
        setCurrentScore(thisRoomWhosTurn.currentNumber);
      } else {
        setCurrentScore("0");
      }
    } else {
      setCurrentScore("0");
    }
  }, [thisRoomWhosTurn]);

  return (
    <div className={hidenGameScore} title="Current game score">
      <h3 className="RoomGameNumbers-GameScore-title">{currentScore}</h3>
    </div>
  );
}

export default GameScore;
