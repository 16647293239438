import React, { useEffect, useState, useRef } from "react";
import "./GameWatcherScoreArea.css";
import "./GameWatcherScoreAreaAnimation.css";
import gameWatcherScoreChangeSound from "../../../../../Audio/gameWatcherScoreChange.mp3";
import ClickSound from "../../../../MusicAndSounds/ClickSound";
import Explanation from "../Explanation/Explanation";
import GetTimedate from "../../CountdownTimer/GetTimedate";

// parent component is - GameWatchingContainer

function GameWatcherScoreArea({ roomData }) {
  const [thisRoom, thisRoomWhosTurn, thisRoomPlayers] = roomData;

  const [gameScore, SetGameScore] = useState("Waiting");
  const [showSeconds, setShowSeconds] = useState("14");
  const prevGameScoreRef = useRef();

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      thisRoomWhosTurn.gameStarted
        ? SetGameScore(thisRoomWhosTurn.currentNumber)
        : SetGameScore("Waiting");

      if (thisRoomWhosTurn.gameStarted && !thisRoomWhosTurn.gameOver) {
        SetGameScore(thisRoomWhosTurn.currentNumber);
      } else if (thisRoomWhosTurn.gameStarted && thisRoomWhosTurn.gameOver) {
        SetGameScore("Victory");
      } else {
        SetGameScore("Waiting");
      }
    }
  }, [thisRoomWhosTurn]);

  // play sound when score has changed
  useEffect(() => {
    const prevGameScore = prevGameScoreRef.current;

    if (prevGameScore !== undefined && prevGameScore !== gameScore) {
      //play click
      ClickSound(gameWatcherScoreChangeSound);
    }

    prevGameScoreRef.current = gameScore;
  }, [gameScore]);
  // play sound when score has changed

  useEffect(() => {
    let interval;

    if (thisRoom !== undefined && thisRoomWhosTurn !== undefined) {
      const timeEnd = thisRoom.duration + thisRoomWhosTurn.turnStart + 4;

      if (!isNaN(timeEnd)) {
        interval = setInterval(() => {
          const currentSeconds = GetTimedate();

          const remainingSeconds = timeEnd - currentSeconds;

          if (thisRoomWhosTurn.gameStarted && !thisRoomWhosTurn.gameOver) {
            remainingSeconds >= 0
              ? setShowSeconds(remainingSeconds)
              : setShowSeconds(0);
          } else if (
            thisRoomWhosTurn.gameStarted &&
            thisRoomWhosTurn.gameOver
          ) {
            if (
              thisRoomWhosTurn.player1.turn === false &&
              thisRoomWhosTurn.player3.turn === false
            ) {
              if (thisRoomPlayers.length === 2) {
                setShowSeconds(thisRoomPlayers[0].name);
              } else if (thisRoomPlayers.length === 4) {
                setShowSeconds(
                  `${thisRoomPlayers[0].name} and ${thisRoomPlayers[2].name} won`,
                );
              }
            } else if (
              thisRoomWhosTurn.player2.turn === false &&
              thisRoomWhosTurn.player4.turn === false
            ) {
              if (thisRoomPlayers.length === 2) {
                setShowSeconds(`${thisRoomPlayers[1].name} won`);
              } else if (thisRoomPlayers.length === 4) {
                setShowSeconds(
                  `${thisRoomPlayers[1].name} and ${thisRoomPlayers[3].name} won`,
                );
              }
            }
          } else {
            setShowSeconds("To Start The Game ...");
          }
        }, 1000);
      }
    }

    return () => clearInterval(interval);
  }, [thisRoom, thisRoomWhosTurn, thisRoomPlayers]);

  return (
    <div className="GameWatcherScoreArea">
      <div className="GameWatcherScoreAreaOuter">
        <div className="GameWatcherScoreAreaDot"></div>
        <div className="GameWatcherScoreAreaCard">
          <div className="GameWatcherScoreAreaLight"></div>
          <div className="GameWatcherScoreAreaScore">{gameScore}</div>
          <div className="GameWatcherScoreAreaTimer">{showSeconds}</div>
          <div className="GameWatcherScoreAreaLine GameWatcherScoreAreaTop"></div>
          <div className="GameWatcherScoreAreaLine GameWatcherScoreAreaLeft"></div>
          <div className="GameWatcherScoreAreaLine GameWatcherScoreAreaBottom"></div>
          <div className="GameWatcherScoreAreaLine GameWatcherScoreAreaRight"></div>
        </div>
      </div>

      <Explanation />
    </div>
  );
}

export default GameWatcherScoreArea;
