const initialSocialState = {
  isDeleteWindowOn: false,
};

const deleteWindowReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISCLICKEDDELETEWINDOW":
      return {
        ...state,
        isDeleteWindowOn: action.payload,
      };

    default:
      return state;
  }
};

export default deleteWindowReducer;
