import React from "react";
import "./NavButtons.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import navMenuSound from "../../../Audio/navMenuSound.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - NavPlace

function NavButtons({ isClickedNavAsync }) {
  const howToPlay = "HOW TO PLAY";
  const gameSettings = "GAME SETTINGS";
  const aboutUs = "ABOUT US";
  const contactUs = "CONTACT US";

  // clicked Nav Item
  const handleNav = (e) => {
    //play click
    ClickSound(navMenuSound);

    const clicked = e.target.name;

    isClickedNavAsync(clicked);
  };
  // clicked Nav Item

  return (
    <nav className="NavBtnPlace">
      <button
        className="NavBtn"
        name={howToPlay}
        data={howToPlay}
        onClick={handleNav}
        title="How to play | Menu"
      ></button>

      <button
        className="NavBtn"
        name={gameSettings}
        data={gameSettings}
        onClick={handleNav}
        title="Game Settings | Menu"
      ></button>

      <button
        className="NavBtn"
        name={aboutUs}
        data={aboutUs}
        onClick={handleNav}
        title="About us | Menu "
      ></button>

      <button
        className="NavBtn"
        name={contactUs}
        data={contactUs}
        onClick={handleNav}
        title="Contact us | Menu"
      ></button>
    </nav>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavButtons);
