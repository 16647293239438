import React, { useEffect, useState } from "react";
import "./AvatarLevelCard.css";
import levels from "../../../../Images/levels.png";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarLevelCard({
  isEditTypeCondition,
  userData,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  const [playedGames, levelStatus] = userData;

  // get user data
  const [userLevel, setUserLevel] = useState("");

  useEffect(() => {
    if (levelStatus !== null && levelStatus !== undefined) {
      setUserLevel(levelStatus.level);
    } else {
      setUserLevel(null);
    }
  }, [levelStatus]);
  // get user data

  // hidden, if clicked edit Profile
  const [hidden, setHidden] = useState("AvatarLevelCard");

  useEffect(() => {
    isEditTypeCondition !== "" || logOutWindowCondition || deleteWindowCondition
      ? setHidden("hidden")
      : setHidden("AvatarLevelCard");
  }, [isEditTypeCondition, logOutWindowCondition, deleteWindowCondition]);
  // hidden, if clicked edit Profile

  return (
    <div className={hidden}>
      <div className="AvatarLevelCard_place">
        <h4>Lev. {userLevel}</h4>
      </div>
      <div className="AvatarLevelCard_playedGames">
        <h4>Played Games: {playedGames}</h4>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarLevelCard);
