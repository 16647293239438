function GetTimedate() {
  // Get the current date and time in UTC
  const currentDateTimeUTC = new Date().getTime();

  // Specify the target date and time in UTC
  const targetDateTimeUTC = Date.UTC(2022, 10, 17, 0, 0, 0); // Note: months are 0-indexed (0 = January, 10 = November)

  // Calculate the difference in milliseconds
  const differenceMilliseconds = currentDateTimeUTC - targetDateTimeUTC;

  // Convert milliseconds to seconds and round down
  const differenceSeconds = Math.floor(differenceMilliseconds / 1000);

  return differenceSeconds;
}

export default GetTimedate;
