import React, { useEffect, useState } from "react";
import "./ContinentalCup.css";
import "./ContinentalCupAnimation.css";
import LoadingSoundTournamentAreaSound from "../../../../Audio/LoadingSoundTournamentArea.mp3";
import LoadingSoundGameArea from "../../../MusicAndSounds/LoadingSoundGameArea";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import ContinentList from "./ContinentList";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";
import { Outlet, useParams } from "react-router-dom";

// parent component is - App

function ContinentalCup({
  isContinentalAsync,
  continentalCondition,
  hamburgerCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const routeParams = useParams();

  const [isHidden, setIsHidden] = useState("ContinentalCup_ContinentList_show");

  const [reloadLoading, setReLoadLoading] = useState("ContinentalCup_loading");

  //continent page requirements
  useEffect(() => {
    !continentalCondition && isContinentalAsync(true);
  }, [isContinentalAsync, continentalCondition]);
  //continent page requirements

  //hide image, clicked continent from continent list
  useEffect(() => {
    if (routeParams.id === "" || routeParams.id === undefined) {
      setIsHidden("ContinentalCup_ContinentList_show");

      setReLoadLoading("ContinentalCup_loading");
    } else {
      setIsHidden("hidden");

      setReLoadLoading("hidden");
    }
  }, [routeParams.id]);
  //hide image, clicked continent from continent list

  return (
    <section className="ContinentalCup">
      {!hamburgerCondition ? (
        <LoadingSoundGameArea
          Audiodata={[
            LoadingSoundTournamentAreaSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className={reloadLoading}>
        <LoadingHexagon />
      </div>

      <Outlet />

      <div className={isHidden}>
        <ContinentList />
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinentalCup);
