import React, { useState } from "react";
import "./ToYourRoom.css";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import enterRoomSound from "../../../../Audio/enterRoomSound.mp3";
import { useEffect } from "react";

// parent component is - BtnContainerToGoCreateRoom

function ToYourRoom({ data }) {
  // data is coming from parent component
  const [navigate, toMyRoom] = data;

  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    toMyRoom !== undefined ? setHidden("ToYourRoom") : setHidden("hidden");
  }, [toMyRoom]);

  const handleToRoom = () => {
    //play click
    ClickSound(enterRoomSound);

    if (toMyRoom !== undefined) {
      const myRoomId = toMyRoom._id;
      const myRoomGameType = toMyRoom.gameType;

      navigate(`/${myRoomGameType}/rooms/${myRoomId}`);
    }
  };

  return (
    <button className={hidden} onClick={handleToRoom}>
      My Room
    </button>
  );
}

export default ToYourRoom;
