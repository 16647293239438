const HIDERESTARTTHEGAME = "HIDERESTARTTHEGAME";

const isHideRestartGame = (check) => {
  return {
    type: HIDERESTARTTHEGAME,
    payload: check,
  };
};

export const isHideRestartGameAsync = (check) => (dispatch) => {
  dispatch(isHideRestartGame(check));
};
