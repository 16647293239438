import React from "react";
import "./PlayOrWatchArea.css";
import popUpSound from "../../../../Audio/popUp.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import navMenuSound from "../../../../Audio/navMenuSound.mp3";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - PlayOrWatchArea
// style - PlayOrWatchArea.css

function PlayOrWatchSettingsAndExitBtns({
  exactLocation,
  isClickedHamburgerAsync,
  isClickedNavAsync,
}) {
  const navigate = useNavigate();

  const exitOrSettings = (e) => {
    const clickedBtn = e.target.name;

    if (clickedBtn === "settings") {
      //play click
      ClickSound(navMenuSound);

      isClickedHamburgerAsync(true);

      isClickedNavAsync("GAME SETTINGS");
    } else {
      //play click
      ClickSound(popUpSound);

      navigate(`/${exactLocation}`);
    }
  };

  return (
    <div className="PlayOrWatchArea-btn-settingsAndExit">
      <button
        className="PlayOrWatchArea-btn-settings"
        name="settings"
        onClick={exitOrSettings}
      >
        <div className="PlayOrWatchArea-btn-settings-sign">
          <svg version="1.1" viewBox="0 0 488.189 488.189">
            <g>
              <g>
                <path
                  d="M326.9,67.166c6.7-14.5,13.5-28.9,20.6-44.2c-78.6-33.1-155.2-31.5-228.4,12.1C48.3,77.166,9.9,141.166,0,223.566
            c16.8,1.5,32.7,2.9,48.6,4.2C57.3,103.266,194.9,7.766,326.9,67.166z"
                />
                <path
                  d="M484.7,202.466c-15.8,2.7-31.5,5.5-47.9,8.3c8.5,57-3.3,108.4-38.1,153.4c-34.6,44.8-81,69.4-137.7,75.7
            c1.4,16.1,2.8,31.8,4.1,47.5C383.8,483.166,511,363.966,484.7,202.466z"
                />
                <path
                  d="M47.9,262.066c-15.1,1.3-30.8,2.7-46.5,4c3.7,99.5,90.5,211.5,221.4,222.1c1.4-16.1,2.8-31.9,4.1-47.2
            C124.5,423.866,65.2,364.566,47.9,262.066z"
                />
                <path
                  d="M398.9,125.566c12.2,15.8,21.6,33.2,28.9,52.4c15.2-5.5,30.2-11,45.9-16.7c-18.1-48.2-47.6-86.6-89.5-116.7
            c-9.3,13.3-18.5,26.3-28,39.9C372.4,96.366,386.8,109.866,398.9,125.566z"
                />
                <path
                  d="M267.2,384.166v-19.1c16.3-3.1,32.1-9.6,46.3-19.4l13.6,13.6c5.1,5,13.2,5,18.3,0l14.1-14.1c5-5.1,5-13.2,0-18.3
            l-13.8-13.8c9.6-14.2,15.9-30,18.9-46.2h19.6c7.1,0,12.9-5.8,12.9-12.9v-20c0-7.1-5.8-12.9-12.9-12.9h-19.7
            c-3.1-16.2-9.5-31.8-19.1-45.9l14-14c5-5.1,5-13.2,0-18.3l-14.3-14.2c-5.1-5-13.2-5-18.3,0l-14,14c-14.1-9.6-29.8-16-46-19.1
            v-19.7c0-7.1-5.8-12.9-12.9-12.9h-20c-7.1,0-12.9,5.8-12.9,12.9v19.6c-16.2,3-32,9.4-46.2,19l-13.7-13.6c-5.1-5-13.2-5-18.3,0
            l-14.1,14.2c-5,5.1-5,13.2,0,18.3l13.6,13.6c-9.8,14.2-16.2,30-19.3,46.3h-19.1c-7.1,0-12.9,5.8-12.9,12.9v20
            c0,7.1,5.8,12.9,12.9,12.9h19c3.1,16.4,9.5,32.3,19.2,46.5l-13.2,13.5c-5,5.1-5,13.2,0,18.3l14.1,14.1c5.1,5,13.2,5,18.3,0
            l13.4-13.4c14.3,9.7,30.2,16.1,46.6,19.2v19c0,7.1,5.8,12.9,12.9,12.9h20C261.4,397.066,267.2,391.266,267.2,384.166z
            M197,291.266c-26-26-26.1-68.2-0.1-94.2s68.2-26.1,94.2-0.1s26.1,68.2,0.1,94.2C265.3,317.166,223.1,317.266,197,291.266z"
                />
              </g>
            </g>
          </svg>
        </div>

        <div className="PlayOrWatchArea-btn-settings-text">Settings</div>
      </button>

      <button
        className="PlayOrWatchArea-btn-exit"
        name="exit"
        onClick={exitOrSettings}
      >
        <div className="PlayOrWatchArea-btn-exit-sign">
          <svg viewBox="0 0 512 512">
            <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
          </svg>
        </div>

        <div className="PlayOrWatchArea-btn-exit-text">Exit</div>
      </button>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayOrWatchSettingsAndExitBtns);
