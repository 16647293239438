const initialState = {
  room: undefined,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

const singleRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROOM_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "GET_ROOM_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        room: action.payload,
      };

    case "GET_ROOM_FAILURE":
      return {
        ...state,
        room: undefined,
        isError: true,
        isSuccess: false,
        isLoading: false,
        message: action.payload,
      };

    case "RESETROOM":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: "",
      };

    default:
      return state;
  }
};

export default singleRoomReducer;
