import React, { Fragment, useEffect, useState } from "react";
import "./General.css";
import AllButtons from "../MenuButtons/AllButtons";
import usePageAccessType from "../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function General({ hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  // Without this, direct access is not working properly
  useEffect(() => {}, [isPageRefreshed, isDirectAccess, isExternalNavigation]);
  // Without this, direct access is not working properly

  const [isHidden, setIsHidden] = useState("General");

  //is clicked hamburger
  useEffect(() => {
    !hamburgerCondition ? setIsHidden("General") : setIsHidden("hidden");
  }, [hamburgerCondition]);
  //is clicked hamburger

  return (
    <main className={isHidden}>
      <Fragment>
        <AllButtons />
      </Fragment>
    </main>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(General);
