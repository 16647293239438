import React, { useState, useEffect } from "react";
import "./GameOver.css";
import "./GameOverAnimation.css";
import GetTimedate from "../CountdownTimer/GetTimedate";
import { useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - GameOver

function GameOverTimer({ newRoomData, isHideRestartGameAsync, editRoomAsync }) {
  const navigate = useNavigate();

  const [
    thisRoom,
    thisRoomWhosTurn,
    thisRoomPlayerIds,
    exactLocation,
    roomId,
    userId,
    deleteRoomAsync,
    resetRoomAsync,
    resetRoomsAsync,
    isExitRoomAsync,
  ] = newRoomData;

  const [showSeconds, setShowSeconds] = useState(null);

  useEffect(() => {
    let interval;

    // Delete the room when the game is finished
    const deleteRoom = () => {
      deleteRoomAsync(roomId);

      resetRoomAsync();

      resetRoomsAsync();

      isHideRestartGameAsync(false);

      isExitRoomAsync([false, ""]);

      navigate(`/${exactLocation}/rooms`);
    };
    // Delete the room when the game is finished

    // Restart Settings
    const RestartSettings = {
      whosTurn: {
        ...thisRoomWhosTurn,
        gameStarted: false,
        currentNumber: 0,
        gameOver: false,
      },
    };
    // Restart Settings

    if (thisRoom !== undefined && thisRoomWhosTurn !== undefined) {
      const timeEnd = thisRoomWhosTurn.turnStart + 50;

      if (!isNaN(timeEnd)) {
        interval = setInterval(() => {
          const currentSeconds = GetTimedate();

          const remainingSeconds = timeEnd - currentSeconds;

          setShowSeconds(remainingSeconds);

          isHideRestartGameAsync(false);

          if (remainingSeconds > 0 && thisRoomWhosTurn.gameOver) {
            // Different situations of ready players
            if (thisRoomWhosTurn !== undefined) {
              if (exactLocation === "single-player") {
                if (
                  thisRoomWhosTurn.player1.restart &&
                  thisRoomWhosTurn.player2.restart
                ) {
                  isHideRestartGameAsync(true);

                  editRoomAsync(roomId, RestartSettings);

                  clearInterval(interval);
                } else if (
                  (thisRoomWhosTurn.player1.restart &&
                    !thisRoomWhosTurn.player2.restart) ||
                  (!thisRoomWhosTurn.player1.restart &&
                    thisRoomWhosTurn.player2.restart)
                ) {
                  isHideRestartGameAsync(true);
                }
              } else if (exactLocation === "multi-player") {
                if (
                  thisRoomWhosTurn.player1.restart &&
                  thisRoomWhosTurn.player2.restart &&
                  thisRoomWhosTurn.player3.restart &&
                  thisRoomWhosTurn.player4.restart
                ) {
                  isHideRestartGameAsync(true);

                  editRoomAsync(roomId, RestartSettings);
                } else if (
                  thisRoomWhosTurn.player1.restart ||
                  thisRoomWhosTurn.player2.restart ||
                  thisRoomWhosTurn.player3.restart ||
                  thisRoomWhosTurn.player4.restart
                ) {
                  isHideRestartGameAsync(true);
                }
              }
            }
            // Different situations of ready players
          } else if (remainingSeconds <= -14 && thisRoomWhosTurn.gameOver) {
            isHideRestartGameAsync(false);

            clearInterval(interval);

            deleteRoom();
          }
        }, 1000);
      }
    }

    return () => clearInterval(interval);
  }, [
    thisRoom,
    thisRoomWhosTurn,
    deleteRoomAsync,
    exactLocation,
    isExitRoomAsync,
    navigate,
    resetRoomAsync,
    resetRoomsAsync,
    roomId,
    isHideRestartGameAsync,
    thisRoomPlayerIds,
    userId,
    editRoomAsync,
  ]);

  return (
    <div className="GameOverTimer">
      {showSeconds - 5 >= 0 ? showSeconds - 5 : 0}{" "}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GameOverTimer);
