import React from "react";
import "./Loading.css";
import "./LoadingAnimation.css";

function LoadingInfinity() {
  return (
    <div className="Loaing_infinity_center">
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
      <div className="Loaing_infinity_wave"></div>
    </div>
  );
}

export default LoadingInfinity;
