import React from "react";
import "./OpponentTurnUserPlace.css";
import { Link } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../../../State/Store/storeProps";
import { connect } from "react-redux";

function OpponentTurnUserPlace({ resetRewardAsync }) {
  const resetReward = () => {
    resetRewardAsync();
  };

  return (
    <div className="OpponentTurnPause-right-userPlace">
      <Link
        className="OpponentTurnPause-right-userPlaceLink"
        to="/profile"
        onClick={resetReward}
      >
        My Profile
      </Link>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpponentTurnUserPlace);
