const initialSocialState = {
  isDeleteCompleted: false,
};

const isDeleteCompletedReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "DELETECOMPLETED":
      return {
        ...state,
        isDeleteCompleted: action.payload,
      };

    default:
      return state;
  }
};

export default isDeleteCompletedReducer;
