import React, { useEffect, useState, Fragment } from "react";
import "./MileStones.css";

// parent component is - RoomGameNumbers

function MileStones({ thisRoomWhosTurn }) {
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      const onGoingNumber = thisRoomWhosTurn.currentNumber;

      if (onGoingNumber >= 73 && onGoingNumber < 161) {
        setFirst(true);
        setSecond(false);
        setThird(false);
      } else if (onGoingNumber >= 161 && onGoingNumber < 198) {
        setFirst(true);
        setSecond(true);
        setThird(false);
      } else if (onGoingNumber >= 198) {
        setFirst(true);
        setSecond(true);
        setThird(true);
      } else {
        setFirst(false);
        setSecond(false);
        setThird(false);
      }
    }
  }, [thisRoomWhosTurn]);

  return (
    <Fragment>
      <div
        className={!first ? "MileStones redBorder" : "MileStones greenBorder"}
        id="firstMile"
        title="First milestone"
      >
        <h4>73</h4>
        <div className="firstMilestoneWinner" id="firstMilestone">
          <h4>
            {!first ? "You're not here yet" : "You nailed this milestone"}
          </h4>
        </div>
      </div>

      <div
        className={!second ? "MileStones redBorder" : "MileStones greenBorder"}
        id="secondMile"
        title="Second milestone"
      >
        <h4>161</h4>
        <div className="secondMilestoneWinner" id="secondMilestone">
          <h4>
            {!second ? "You're not here yet" : "You nailed this milestone"}
          </h4>
        </div>
      </div>

      <div
        className={!third ? "MileStones redBorder" : "MileStones greenBorder"}
        id="thirdMile"
        title="Finish"
      >
        <h4>198</h4>
        <div className="thirdMilestoneWinner" id="thirdMilestone">
          <h4>
            {!third ? "You're not here yet" : "You nailed this milestone"}
          </h4>
        </div>
      </div>
    </Fragment>
  );
}

export default MileStones;
