import React, { useRef, useEffect, useState } from "react";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../State/Store/storeProps";
import { connect } from "react-redux";

const CriticalSoundCountDownTimer = ({ Audiodata, mutedMusicCondition }) => {
  const [
    CountdownFive,
    isPageRefreshed,
    isDirectAccess,
    isExternalNavigation,
    showSeconds,
    yourTurnShow,
  ] = Audiodata;

  const audioRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;

    // Check if any of the conditions are true
    if (
      isPageRefreshed ||
      isPageRefreshed === undefined ||
      isDirectAccess ||
      isDirectAccess === undefined ||
      isExternalNavigation ||
      isExternalNavigation === undefined
    ) {
      // Skip playing the sound

      return;
    }

    const handleEnded = () => {
      audio.currentTime = 0;
    };

    if (yourTurnShow && (showSeconds === 6 || showSeconds === 7)) {
      timeoutRef.current = setTimeout(() => {
        if (audio) {
          audio.currentTime = 0; // Ensure the audio is reset
          audio.play().catch((err) => console.log(err));
          audio.addEventListener("ended", handleEnded);
        }
      }, 3000);
    } else {
      if (audio) {
        audio.pause();
        audio.currentTime = 0; // Ensure the audio is reset
        audio.removeEventListener("ended", handleEnded);
        // Mute or unmute the audio based on mutedMusicCondition
        audio.volume = mutedMusicCondition ? 0 : 1;
      }
    }

    return () => {
      if (audio) {
        audio.pause();
        audio.removeEventListener("ended", handleEnded);
      }
    };
  }, [
    isPageRefreshed,
    isDirectAccess,
    isExternalNavigation,
    mutedMusicCondition,
    yourTurnShow,
    showSeconds,
  ]);

  return (
    <audio ref={audioRef} loop>
      <source src={CountdownFive} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CriticalSoundCountDownTimer);
