const ISMUTEDMUSICS = "ISMUTEDMUSICS";

const isMutedMusic = (check) => {
  return {
    type: ISMUTEDMUSICS,
    payload: check,
  };
};

export const isMutedMusicAsync = (check) => (dispatch) => {
  dispatch(isMutedMusic(check));
};
