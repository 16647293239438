import React, { useEffect, useState } from "react";
import "./GameWatchingContainer.css";
import gameWatcherBackgroundSound from "../../../../Audio/gameWatcherBackground.mp3";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import LoaderSimple from "../../../Loading/LoaderSimple";
import ExitRoomConfirm from "../ExitRoomConfirm/ExitRoomConfirm";
import GameWatchSettingsAndExitBtns from "./GameWatchSettingsAndExitBtns";
import GameWatcherScoreArea from "./GameWatcherScoreArea/GameWatcherScoreArea";
import GameWatchingLimitations from "./GameWatchingLimitations/GameWatchingLimitations";
import GameWatchingLoggedUsers from "./GameWatchingLoggedUsers/GameWatchingLoggedUsers";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SingleRoom

function GameWatchingContainer({
  roomData,
  isExitRoomAsync,
  isWatchingGameCondition,
  hamburgerCondition,
  exitRoomCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  // roomData is coming from parent component - SingleRoom
  const [
    roomNumber,
    roomId,
    userId,
    userName,
    exactLocation,
    thisRoomPlayersCount,
    thisRoomPlayers,
    thisRoomPlayerIds,
    thisRoomWhosTurn,
    thisRoom,
    user,
  ] = roomData;
  // roomData is coming from parent component - SingleRoom

  // user options, watch or play
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (thisRoom !== undefined) {
      if (user !== null && user !== undefined && user !== "") {
        if (
          (exactLocation === "single-player" && thisRoomPlayersCount === 2) ||
          (exactLocation === "multi-player" && thisRoomPlayersCount === 4)
        ) {
          if (!thisRoomPlayerIds.includes(userId)) {
            setHidden(false);
          } else {
            setHidden(true);
          }
        } else if (isWatchingGameCondition === "watch") {
          setHidden(false);
        } else {
          setHidden(true);
        }
      } else {
        setHidden(false);
      }
    }
  }, [
    user,
    userId,
    thisRoom,
    isWatchingGameCondition,
    exactLocation,
    thisRoomPlayerIds,
    thisRoomPlayersCount,
  ]);
  // user options, watch or play

  return !hidden ? (
    <div className="GameWatchingContainer">
      {!hamburgerCondition ? (
        <BackGroundMusic
          Audiodata={[
            gameWatcherBackgroundSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <ExitRoomConfirm
        newRoomData={[
          roomNumber,
          exactLocation,
          roomId,
          userId,
          userName,
          thisRoomWhosTurn,
        ]}
      />

      <div className="GameWatchingContainer-loading">
        <LoaderSimple />
      </div>

      {/* Watcher score area */}
      {!exitRoomCondition[0] ? (
        <GameWatcherScoreArea
          roomData={[thisRoom, thisRoomWhosTurn, thisRoomPlayers]}
        />
      ) : null}
      {/* Watcher score area */}

      {/* Feature */}
      {user === null || user === undefined || user === "" ? (
        !exitRoomCondition[0] ? (
          <GameWatchingLimitations />
        ) : null
      ) : !exitRoomCondition[0] ? (
        <GameWatchingLoggedUsers thisRoomWhosTurn={thisRoomWhosTurn} />
      ) : null}
      {/* Feature */}

      <GameWatchSettingsAndExitBtns isExitRoomAsync={isExitRoomAsync} />
    </div>
  ) : null;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GameWatchingContainer);
