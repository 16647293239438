import React from "react";
import "./AllMultiRoomsContainer.css";
import "./AllMultiRoomsContainerAnimation.css";
import AllMultiRooms from "../AllMultiRooms/AllMultiRooms";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AllMultiRoomsContainerArea

function AllMultiRoomsContainer() {
  return (
    <div className="AllMultiRoomsContainer">
      <AllMultiRooms />
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllMultiRoomsContainer);
