import React, { useState, useEffect } from "react";
import "./BtnContainerToGoCreateRoom.css";
import "./BtnContainerToGoCreateRoomAnimation.css";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import slideSound from "../../../../Audio/slideSound.mp3";
import popUpSound from "../../../../Audio/popUp.mp3";
import LoaderDots from "../../../Loading/LoaderDots";
import ToYourRoom from "../ToYourRoom/ToYourRoom";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SinglePlayerGame

function BtnContainerToGoCreateRoom({
  authStateCondition,
  authRoomStateCondition,
  isPlayerRoomAvailable,
  isPlayerRoomAvailableAsync,
}) {
  const location = useLocation();
  const exactLocation = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const { user } = authStateCondition;

  const { rooms } = authRoomStateCondition;

  const [showCreateBtn, setShowCreateBtn] = useState("");

  const [available, setAvailable] = useState();

  const [toMyRoom, setToMyRoom] = useState(undefined);

  useEffect(() => {
    !isPlayerRoomAvailable ? setAvailable(false) : setAvailable(true);
  }, [isPlayerRoomAvailable]);

  useEffect(() => {
    if (user === undefined || user === null) {
      setShowCreateBtn("hidden");
    } else {
      const userId = user._id;

      const roomsWithCurrentUser = !("message" in rooms)
        ? rooms.filter((room) =>
            room.players.some((player) => player.id === userId),
          )
        : null;
      const roomWithCurrentUser = !("message" in rooms)
        ? rooms.find((room) =>
            room.players.some((player) => player.id === userId),
          )
        : null;

      if (roomsWithCurrentUser !== null && roomWithCurrentUser !== null) {
        if (roomsWithCurrentUser.length === 0) {
          setShowCreateBtn("BtnContainerToGoCreateRoom-create");
          setToMyRoom(undefined);
        } else {
          setShowCreateBtn("hidden");
          setToMyRoom(roomWithCurrentUser);
        }
      } else {
        setShowCreateBtn("hidden");
      }
    }
  }, [user, rooms]);

  const handleSelected = () => {
    //play slideclick
    ClickSound(slideSound);

    if (!available) {
      isPlayerRoomAvailableAsync(true);
    } else {
      isPlayerRoomAvailableAsync(false);
    }
  };

  const handleCreateRoom = () => {
    //play click
    ClickSound(popUpSound);

    if (showCreateBtn === "BtnContainerToGoCreateRoom-create") {
      navigate(`/${exactLocation}/create`);
    } else {
      navigate(`/${exactLocation}`);
    }
  };

  return (
    <div className="BtnContainerToGoCreateRoom">
      <div className="BtnContainerToGoCreateRoom-loading">
        <LoaderDots />
      </div>

      <div className="BtnContainerToGoCreateRoom-triangle">
        <div
          id="BtnContainerToGoCreateRoom-triangle-one"
          className="color-triangle"
        ></div>
        <div
          id="BtnContainerToGoCreateRoom-triangle-two"
          className="color-triangle"
        ></div>
        <div
          id="BtnContainerToGoCreateRoom-triangle-three"
          className="color-triangle"
        ></div>
      </div>

      <div className="BtnContainerToGoCreateRoom-create-area">
        <button className={showCreateBtn} onClick={handleCreateRoom}>
          Create Room
        </button>

        <ToYourRoom data={[navigate, toMyRoom]} />

        <label className="BtnContainerToGoCreateRoom-switch">
          <input type="checkbox" onChange={handleSelected} />
          <span className="BtnContainerToGoCreateRoom-slider"></span>
        </label>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BtnContainerToGoCreateRoom);
