import React from "react";
import "./Loading.css";
import "./LoadingAnimation.css";

function LoaderSimple() {
  return (
    <div className="loader_simple_spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoaderSimple;
