const EDITCOMPLETED = "EDITCOMPLETED";

const isEditCompleted = (check) => {
  return {
    type: EDITCOMPLETED,
    payload: check,
  };
};

export const isEditCompletedAsync = (check) => (dispatch) => {
  dispatch(isEditCompleted(check));
};
