import React, { useState, useEffect } from "react";
import "./DeleteWindow.css";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SigninPlace

function DeleteCompletedMessage({
  isDeleteCompletedAsync,
  DeleteCompletedCondition,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    DeleteCompletedCondition
      ? setHidden("DeleteCompletedMessage")
      : setHidden("hidden");

    return () => {
      isDeleteCompletedAsync(false);
    };
  }, [DeleteCompletedCondition, isDeleteCompletedAsync]);

  return (
    <div className={hidden}>
      <h2>You have successfully deleted your account!</h2>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteCompletedMessage);
