import React, { useEffect, useState } from "react";
import "./Explanation.css";
import questionMark from "../../../../../Images/QuestionMarkIcon.png";

// parent component is - RoomGameNumbers

function Explanation({ thisRoomWhosTurn }) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined && thisRoomWhosTurn !== null) {
      !thisRoomWhosTurn.gameStarted
        ? setHidden("hidden")
        : setHidden("RoomGameNumbersExplanation");
    } else {
      setHidden("hidden");
    }
  }, [thisRoomWhosTurn]);

  return (
    <div className={hidden}>
      <div className="RoomGameNumbersExplanationTextContainer">
        <h4 className="RoomGameNumbersExplanationTextHeader">
          The goal of this game is to choose numbers and add them together each
          round until you achieve a total of 198 or more.
        </h4>
        <p className="RoomGameNumbersExplanationText">
          - After selecting your number, it is added to the total, and then
          it&apos;s your{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            opponent&apos;s turn.
          </span>
        </p>
        <p className="RoomGameNumbersExplanationText">
          - At the top left-hand side, there is{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            a timer.
          </span>{" "}
          You must pick your number before the timer runs out. If you do not
          make a selection in time, a number will be automatically chosen for
          you at random.
        </p>
        <p className="RoomGameNumbersExplanationText">
          - When it&apos;s your turn and the total is not zero, you can view it
          at the middle top. When it&apos;s your opponent&apos;s turn and the
          total is not zero, you can also see it at the middle.
        </p>
        <p className="RoomGameNumbersExplanationText">
          - In the bottom center or on the right-hand side, there is a{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            &apos;Support Oasis&apos;
          </span>{" "}
          where you can choose options like{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            &apos;Double&apos;
          </span>{" "}
          to double your number, or{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            &apos;Add extra 1&apos;, &apos;Add extra 4&apos;, &apos;Add extra
            7&apos;
          </span>{" "}
          to add the chosen extra number to your selected number. Choosing{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            &apos;Negative&apos;
          </span>{" "}
          will change your number in the negative direction. You must click this
          option
          <span className="RoomGameNumbersExplanationTextHighLight">
            {" "}
            before selecting your number.
          </span>{" "}
          Each option can only be selected{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            up to three times.
          </span>
        </p>
        <p className="RoomGameNumbersExplanationText">
          - When you choose your number, the AI picks its number{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            simultaneously,
          </span>{" "}
          so both of you play at the same time. If the numbers match,
          <span className="RoomGameNumbersExplanationTextHighLight">
            {" "}
            the &apos;Matched&apos; count increases by one.
          </span>{" "}
          Using &apos;support oasis&apos; will{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            decrease{" "}
          </span>{" "}
          the match count, and if it reaches zero, it will then{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            decrease the &apos;support oasis&apos; limit.
          </span>
        </p>
        <p className="RoomGameNumbersExplanationText">
          - In the top center or on the left-hand side are{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            &apos;Milestones&apos;. The &apos;Support Oasis&apos;
          </span>{" "}
          options differ based on the total score:{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            from 0 to 72, from 73 to 160, and when you reach 161 or higher,
          </span>{" "}
          you gain the ability to go{" "}
          <span className="RoomGameNumbersExplanationTextHighLight">
            &apos;negative&apos;.
          </span>
        </p>
      </div>
      <div className="RoomGameNumbersExplanationIcon">
        <img
          className="RoomGameNumbersExplanationIconImg"
          src={questionMark}
          title="Description"
          alt="Description"
        />
      </div>
    </div>
  );
}

export default Explanation;
