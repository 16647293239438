const ISSELECTEDEDITTYPE = "ISSELECTEDEDITTYPE";

const isSelectedEditType = (check) => {
  return {
    type: ISSELECTEDEDITTYPE,
    payload: check,
  };
};

export const isSelectedEditTypeAsync = (check) => (dispatch) => {
  dispatch(isSelectedEditType(check));
};
