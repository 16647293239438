import React from "react";
import "./OpponentTurnPause.css";
import "./OpponentTurnPauseAnimation.css";

// parent component is - RoomGameNumbers

function OpponentTurnPause() {
  return <div className="OpponentTurnPause"></div>;
}

export default OpponentTurnPause;
