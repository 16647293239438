const user = JSON.parse(localStorage.getItem("user"));

// Initial State
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTER_REQUEST":
    case "LOGIN_REQUEST":
    case "DELETE_USER_REQUEST":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: "",
      };

    case "REGISTER_SUCCESS":
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "REGISTER_FAILURE":
    case "LOGIN_FAILURE":
    case "DELETE_USER_FAILURE":
      return {
        ...state,
        user: null,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
      };

    case "LOGOUT_REQUEST":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: "",
      };

    case "LOGOUT_SUCCESS":
      return {
        ...state,
        user: null,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: "",
      };

    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        user: null,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "RESET":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: "",
      };

    default:
      return state;
  }
};

export default authReducer;
