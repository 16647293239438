import React, { useState, useEffect } from "react";
import "./MultiRoomDisable.css";
import "./MultiRoomDisableAnimation.css";
import LoaderArrow from "../../../Loading/LoaderArrow";

// parent component is - AllMultiRooms

function MultiRoomDisable({ disableEnter }) {
  const [showDisable, setShowDisable] = useState("MultiRoomDisable");
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    if (disableEnter) {
      setShowDisable("MultiRoomDisable-notEnter");

      setShowArrow(true);
    } else {
      setShowDisable("MultiRoomDisable");

      setShowArrow(false);
    }
  }, [disableEnter]);

  return (
    <div
      className={showDisable}
      title="This room isn't accessible to you as you're currently occupying another room."
    >
      {showArrow ? <LoaderArrow /> : <></>}
    </div>
  );
}

export default MultiRoomDisable;
