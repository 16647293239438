import React, { useEffect, useState } from "react";
import "./Digits.css";
import ClickSound from "../../../../MusicAndSounds/ClickSound";
import numberClickedSound from "../../../../../Audio/numberClicked.mp3";
import numbersMatchedSound from "../../../../../Audio/numbersMatched.mp3";

// parent component is - RoomGameNumbers

function Digits({ roomData }) {
  const [myDigit, hideDigits, youAreGuessed] = roomData;

  const [hidden, setHidden] = useState("hidden");
  const [currentDigit, setCurrentDigit] = useState(null);

  useEffect(() => {
    let makeHidden;

    if (!isNaN(myDigit) && hideDigits) {
      setCurrentDigit(myDigit);

      !youAreGuessed ? setHidden("RoomDigits") : setHidden("RoomDigitsGuessed");
    } else {
      setHidden("hidden");
      setCurrentDigit(0);
    }

    makeHidden = setTimeout(() => {
      setHidden("hidden");
    }, 2400);

    return () => clearTimeout(makeHidden);
  }, [myDigit, hideDigits, youAreGuessed]);

  useEffect(() => {
    // console.log(myDigit, currentDigit)

    if (!youAreGuessed && myDigit !== null) {
      //play click
      ClickSound(numberClickedSound);
    } else if (youAreGuessed && myDigit !== null) {
      //play click
      ClickSound(numbersMatchedSound);
    }
  }, [youAreGuessed, myDigit]);

  return <div className={hidden} data={currentDigit}></div>;
}

export default Digits;
