import React, { useState, useEffect } from "react";
import "./ForgetPasswordForm.css";
import "./ForgetPasswordFormAnimation.css";
import "../Form.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import forgetSound from "../../../Audio/createGame.mp3";
import { Link, useNavigate } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - ForgetPasswordPlace

function ForgetPasswordForm({
  isForgetPasswordCompletedAsync,
  resetAsync,
  authStateCondition,
}) {
  const { user } = authStateCondition;

  const navigate = useNavigate();

  const [loggedInUser, setLoggedInUser] = useState(false);

  // if user has already logged in, make unclickable
  useEffect(() => {
    if (user !== undefined && user !== null) {
      setLoggedInUser(true);
    } else {
      setLoggedInUser(false);
    }
  }, [user]);
  // if user has already logged in, make unclickable

  // Form Data
  const [formData, setFormData] = useState({ email: "" });

  // Distracture Form Data
  const { email } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  // Get Form Values

  // temporary solution, but it is not working
  const onSubmit = (e) => {
    e.preventDefault();

    //play click
    ClickSound(forgetSound);

    navigate("/login");

    !loggedInUser ? isForgetPasswordCompletedAsync(true) : null;

    resetAsync();
  };
  // temporary solution, but it is not working

  // handle Sign in click, just for sound
  const handleSignIn = () => {
    //play click
    ClickSound(popUpSound);
  };
  // handle Sign in click, just for sound

  return (
    <section className="forgetPassword">
      <div className="forgetPassword_content">
        <h2>Forget Password</h2>
        <form
          className={!loggedInUser ? "form" : "form disable-link"}
          onSubmit={onSubmit}
        >
          <div className="inputBox">
            <input
              type="email"
              name="email"
              id="forgetPassword-email"
              title="Enter your email address."
              value={email}
              onChange={onChange}
              required
              maxLength="32"
              autoFocus
            />
            <i>E-mail</i>
          </div>
          <div
            className="inputBox"
            title="If your email is right, please go ahead and enter it."
          >
            {/* <input type="submit" value="Enter" />  */}
            <button className="submit_BTN" type="submit">
              Enter
            </button>
          </div>
          <div
            className="input_links"
            title="Join now if you don't have an account!"
          >
            <p>Already have an account?</p>
            <Link to="/login" onClick={handleSignIn}>
              Sign in
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordForm);
