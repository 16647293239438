import React, { useEffect, useState } from "react";
import "./NavPages.css";
import "./NavPagesAnimation.css";
import LoadingHexagon from "../../Loading/LoadingHexagon";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - NavPlace

function HowToPlay({ navCondition }) {
  const howToPlay = "HOW TO PLAY";

  const [isHidden, setIsHidden] = useState("NavPage");

  useEffect(() => {
    navCondition === howToPlay ? setIsHidden("NavPage") : setIsHidden("hidden");
  }, [navCondition]);

  return (
    // className='NavPage'
    <section className={isHidden}>
      <div className="Nav_hide-hex">
        <LoadingHexagon />
      </div>

      <div className="NavHeaderPlace">
        <h3 className="h3_transform" title="How to play | Topic">
          {howToPlay}
        </h3>
      </div>

      <article className="NavText">
        <h4>How to Play: &apos;198 Runners&apos; Online</h4>
        <h5>The goal is to reach a total of 198 or more.</h5>
        <div className="NavText-modes">
          <div className="NavText-modes-single">
            <h6>Single Player Mode:</h6>
            <p>
              You need two players for this. It&apos;s just you and another
              person going head-to-head picking numbers. The first player starts
              by choosing a number from 1 to 9. Whatever number they pick is the
              starting total. Then, the second player picks a number from 1 to
              9, and adds it to the total. From there, you both keep taking
              turns picking numbers from 1 to 9 and adding them to the total.
              You keep going back and forth like this, adding to the total each
              time. The game keeps going until one of you picks a number that
              makes the total hit 198 or more. The person who does that wins!
              For example, if the first player picks 7, the total is 7. If the
              second player then picks 8, the total goes up to 15. Then maybe
              the first player picks 9, making it 24, and so on. You keep
              playing like this until the total reaches 198 or more. The player
              who makes that happen is the winner.
            </p>
          </div>
          <div className="NavText-modes-multi">
            <h6>Multiplayer Mode:</h6>
            <p>
              You need four players to start this one. You&apos;ll split into
              two teams of two. Each team competes against the other. The game
              starts with the first player of the first team choosing a number
              from 1 to 9. Whatever number they pick is the starting total.
              Then, it&apos;s the first player of the second team&apos;s turn to
              pick a number from 1 to 9 and add it to the total. Next, the
              second player of the first team picks a number and adds it to the
              total, followed by the second player of the second team doing the
              same. This back-and-forth turn-taking continues between the two
              teams, with each player picking a number and adding it to the
              total in sequence. The game keeps going until one of the teams
              picks a number that makes the total hit 198 or more. The team that
              achieves this is declared the winner! For example, if the first
              player of the first team picks 7, the total is 7. Then, if the
              first player of the second team picks 8, the total goes up to 15.
              Next, if the second player of the first team picks 9, making it
              24, and so on. You keep playing like this until the total reaches
              198 or more. The team that makes that happen is the winner.
            </p>
          </div>
          <div className="NavText-modes-tour">
            <h6>Tournament Mode:</h6>
            <p>
              In this mode, players can participate in various types of
              tournaments, including World Cups, Continental Cups, Country Cups,
              City Cups, and special event cups. Each tournament brings a unique
              competitive experience and the chance to rise through the ranks by
              competing against other skilled players. Just like in Single
              Player Mode, the objective in each tournament match is to be the
              player who makes the total reach or exceed 198. Players take turns
              choosing numbers from 1 to 9, starting with the first player. The
              chosen number is added to the running total. Then, the second
              player picks a number from 1 to 9, adding it to the total, and so
              on. The game continues back and forth in this manner, with each
              player&apos;s chosen number contributing to the accumulating
              total. The match proceeds until one of the players picks a number
              that makes the total hit 198 or more. The player who achieves this
              is declared the winner of that match. For example, if the first
              player picks 7, the total is 7. If the second player then picks 8,
              the total goes up to 15. Then, if the first player picks 9, the
              total becomes 24, and so on. The match continues until the total
              reaches 198 or more, and the player who makes that happen wins.
              However, please note that Tournament Mode is currently not
              available for play. Stay tuned for updates on when these exciting
              tournaments will be open for participation!
            </p>
          </div>
          <div className="NavText-modes-login">
            <h6>Create a Profile | Registration:</h6>
            <p>
              To play this game, users need to create a profile by registering
              with their name, a unique email, and a password. However, if a
              user doesn&apos;t want to create a profile, they can still watch
              other games but cannot participate in playing. Registered players
              also have the option to watch other games if they wish. For
              registered players, there are two options: they can join a game
              room and play if there are available spots, or they can simply
              watch the game. Registered users have the option to edit their
              name, password, or email later if they wish.
            </p>
          </div>
          <div className="NavText-modes-create">
            <h6>How to Create a Room:</h6>
            <p>
              To set up a game room, you need to follow a few steps and choose
              your preferred options. First, select the game mode, which can be
              either Single Player or Multiplayer. Next, choose how long each
              player has for their turn, with options of 14 seconds, 32 seconds,
              60 seconds, or 5 minutes. You can also decide whether to create a
              room with or without a passcode. If you choose to create a room
              without a passcode, anyone can join the game. However, if you opt
              for a room with a passcode, only players who know the code can
              join. The passcode should be a number between 1 and 4 digits, such
              as 3, 55, or 4444. By setting these options, you can customize the
              game room to suit your preferences and control who can join the
              game.
            </p>
          </div>
        </div>

        {/* Tournaments: This mode isn’t available right now. */}
      </article>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HowToPlay);
