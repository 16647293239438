const initialSocialState = {
  isSelectedEditOn: "",
};

const selectedEditTypeReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISSELECTEDEDITTYPE":
      return {
        ...state,
        isSelectedEditOn: action.payload,
      };

    default:
      return state;
  }
};

export default selectedEditTypeReducer;
