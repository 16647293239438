import React, { useEffect, useState } from "react";
import "./GameWatchingLoggedUsers.css";
import "./GameWatchingLoggedUsersAnimation.css";
import ClickSound from "../../../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../../../Audio/menuButtons.mp3";
import { useNavigate } from "react-router-dom";

// parent component is - GameWatchingContainer

function GameWatchingLoggedUsers({ thisRoomWhosTurn }) {
  const navigate = useNavigate();

  const [gameStarted, setGameStarted] = useState(false);

  const userHeader = "It's Your Time To Play";
  const userText =
    "You have entered this room to watch the game, but it hasn't started yet. Since you are already logged in, you can create your own room. Alternatively, if you'd rather just watch the game, you're in the right place. Have fun!";

  const userTextGameStarted =
    "You have entered this room to watch the game, which is really interesting and enjoyable. As a logged-in user, you have the option to create your own room and play with others. However, if you'd prefer to just watch the game, you're in the right place. Have fun!";

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      !thisRoomWhosTurn.gameStarted
        ? setGameStarted(false)
        : setGameStarted(true);
    } else {
      setGameStarted(false);
    }
  }, [thisRoomWhosTurn]);

  const handleClick = () => {
    //play click continue
    ClickSound(menuButtonsSound);

    navigate("/single-player");
  };

  return (
    <div className="GameWatchingLoggedUsers">
      <p className="GameWatchingContainer-loggedUsers_heading">{userHeader}</p>
      <p className="GameWatchingContainer-loggedUsers_text">
        {!gameStarted ? userText : userTextGameStarted}
      </p>

      <div className="GameWatchingLoggedUsersButtons">
        <button
          className="GameWatchingLoggedUsersButtons_button"
          name="play"
          onClick={handleClick}
          title="Enter rooms area"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            ></path>
          </svg>
          <div className="GameWatchingLoggedUsersButtons_text">Rooms</div>
        </button>
      </div>
    </div>
  );
}

export default GameWatchingLoggedUsers;
