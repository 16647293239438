import React, { useEffect, useState } from "react";
import "./ContinentalCup.css";
import "./ContinentalCupAnimation.css";
import ContinentButtons from "./ContinentButtons";
import LoadingHexagon from "../../../Loading/LoadingHexagon";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// parent component is - App

function Continent() {
  const routeParams = useParams();

  const [continentType, setContinentType] = useState("");

  useEffect(() => {
    if (routeParams.id === "north-america") {
      setContinentType("Cup of North America");
    } else if (routeParams.id === "south-america") {
      setContinentType("Cup of South America");
    } else if (routeParams.id === "europe") {
      setContinentType("Cup of Europe");
    } else if (routeParams.id === "africa") {
      setContinentType("Cup of Africa");
    } else if (routeParams.id === "asia") {
      setContinentType("Cup of Asia");
    } else if (routeParams.id === "australia") {
      setContinentType("Cup of Australia");
    } else {
      setContinentType("north-america");
    }
  }, [continentType, routeParams.id]);

  return (
    <div className="Continent">
      <div className="singleContinent_selected_loading">
        <LoadingHexagon />
      </div>

      <div className="singleContinent-container">
        <div className="singleContinent">
          <div
            className="singleContinent_content"
            title="Continental Tournament"
          >
            <p className="singleContinent_heading">{continentType}</p>
            <p>
              Explore our continent&apos;s gaming culture and discover the fun
              of playing.
            </p>

            <ContinentButtons />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Continent);
