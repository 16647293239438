import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./SingleRoomContainer.css";

// parent component is - App

function SingleRoomContainer() {
  const location = useLocation();
  const exactLocation = location.pathname.split("/").length;
  const navigate = useNavigate();

  useEffect(() => {
    exactLocation === 3 && navigate("/single-player");
  });

  return (
    <div className="InSingleRoomArea">
      <Outlet />
    </div>
  );
}

export default SingleRoomContainer;
