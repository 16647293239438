import React, { useEffect, useState } from "react";
import "./NavPages.css";
import "./NavPagesAnimation.css";
import LoadingHexagon from "../../Loading/LoadingHexagon";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - NavPlace

function AboutUs({ navCondition, isClickedNavAsync }) {
  const aboutUs = "ABOUT US";

  const [isHidden, setIsHidden] = useState("hidden");

  useEffect(() => {
    navCondition === aboutUs ? setIsHidden("NavPage") : setIsHidden("hidden");
  }, [navCondition]);

  const changeToContact = () => {
    isClickedNavAsync("CONTACT US");
  };

  return (
    // className='NavPage'
    <section className={isHidden}>
      <div className="Nav_hide-hex">
        <LoadingHexagon />
      </div>

      <div className="NavHeaderPlace">
        <h3 className="h3_transform" title="About us | Topic">
          {aboutUs}
        </h3>
      </div>

      <article className="NavText">
        <h4>You&apos;re welcome to &apos;198 Runners&apos;!</h4>
        <h5>An exciting game by Tiegrun, released on June 17, 2023.</h5>
        <div className="NavText-modes">
          <div className="NavText-modes-origins">
            <h6>Our Origins</h6>
            <p>
              How We Started: The idea for 198 Runners came to life on June
              17th, 2023, when a group of enthusiastic gamers, now known as
              Tiegrun, decided to create a game that would stand out in the
              world of online gaming. From the initial concept to the complete
              game, our journey has been one of creativity, collaboration, and
              relentless dedication.
            </p>
          </div>
          <div className="NavText-modes-mission">
            <h6>Our Mission</h6>
            <p>
              Our mission is to establish 198 Runners as a global gaming
              sensation. We aim to unite gamers from around the world through a
              series of diverse and exciting tournaments.
            </p>
          </div>
          <div className="NavText-modes-competition">
            <h6>Our Tournaments</h6>
            <p>
              At 198 Runners, competition is at the heart of the experience. We
              organize a variety of tournaments to cater to players of all skill
              levels and regions, including:
            </p>
            <div className="NavText-modes-text-emph">
              <p>
                <span className="NavText-modes-emph">World Championship:</span>{" "}
                The ultimate competition for the best of the best.
              </p>
              <p>
                <span className="NavText-modes-emph">
                  Continental Championships:
                </span>{" "}
                Regional showdowns highlighting top players from each continent.
              </p>
              <p>
                <span className="NavText-modes-emph">
                  Country Championships:
                </span>{" "}
                National competitions to crown the best players in each country.
              </p>
              <p>
                <span className="NavText-modes-emph">City Championships:</span>{" "}
                Local events to find the champions within cities.
              </p>
              <p>
                <span className="NavText-modes-emph">Event Championships:</span>{" "}
                Special themed tournaments with unique rules and rewards.
              </p>
            </div>
          </div>
          <div className="NavText-modes-rewards">
            <h6>Rewards and Recognition</h6>
            <p>
              Our tournaments offer not just the thrill of competition but also
              the chance for players to earn recognition and rewards. Winners
              are celebrated and awarded for their achievements, making every
              victory memorable.
            </p>
          </div>
          <div className="NavText-modes-community">
            <h6>Community Engagement</h6>
            <p>
              We are committed to being highly active on social media platforms,
              engaging with our community, and providing regular updates. This
              helps us stay connected with our players and keep them informed
              about the latest news and events.
            </p>
            <p>
              To reach a global audience, we are looking for ambassadors from
              every continent, country, city, and even small town. We also aim
              to collaborate with bloggers, TikTokers, and game influencers to
              spread the word about 198 Runners and engage a broader community.
            </p>
            <p>
              Whether you&apos;re a player, an influencer, or a gaming
              enthusiast, we invite you to become part of the 198 Runners
              community. Follow us on social media, participate in our
              tournaments, and join us on this exciting journey.
            </p>
            <p>
              For support, inquiries, or to become an ambassador,{" "}
              <span className="NavText-modes-click" onClick={changeToContact}>
                contact or follow
              </span>{" "}
              us to stay updated and join the conversation.
            </p>
          </div>
        </div>
      </article>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
