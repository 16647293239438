import React from "react";
import "./Explanation.css";
import questionMark from "../../../../../Images/QuestionMarkIcon.png";

// parent component is - PlayOrWatchArea

function Explanation() {
  return (
    <div className="PlayOrWatchExplanation">
      <div className="PlayOrWatchExplanationTextContainer">
        <p className="PlayOrWatchExplanationText">
          There is an available spot in this room, so you have two choices: you
          can either participate in the game as a palyer or watch.
        </p>
      </div>
      <div className="PlayOrWatchExplanationIcon">
        <img
          className="PlayOrWatchExplanationIconImg"
          src={questionMark}
          title="Description"
          alt="Description"
        />
      </div>
    </div>
  );
}

export default Explanation;
