import React, { useState, useEffect } from "react";
import "./EditForms.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import loginSound from "../../../Audio/createGame.mp3";
import EditCompletedFeatures from "./EditCompletedFeatures";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - EditFormGeneral

function EditPassword({
  isEditTypeCondition,
  editProfileAsync,
  isEditCompletedAsync,
  user,
}) {
  const [hidden, setHidden] = useState("hidden");

  const [hideForConfirm, setHideForConfirm] = useState("EditPassword_content");

  useEffect(() => {
    isEditTypeCondition === "password"
      ? setHidden("EditPassword")
      : setHidden("hidden");
  }, [isEditTypeCondition]);

  // Form error messages
  const [errForm, setErrForm] = useState({
    password_err_display: "hidden",
    confirmedPassword_err_display: "hidden",
  });

  // Distracture Form error messages
  const { password_err_display, confirmedPassword_err_display } = errForm;
  // Form error messages

  // Form Data
  const [formData, setFormData] = useState({
    password: "",
    confirmedPassword: "",
  });

  // Distracture Form Data
  const { password, confirmedPassword } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  // Get Form Values

  // Handle edit password
  const handleEdit = async (password) => {
    const token = user.token;

    const userData = {
      password: password,
      // confirmedPassword: confirmedPassword
    };

    const authSuccess = await editProfileAsync(token, userData);

    if (authSuccess) {
      isEditCompletedAsync("password");
    } else {
      isEditCompletedAsync("error");
    }
  };
  // Handle edit password

  const onSubmit = (e) => {
    e.preventDefault();

    //play click
    ClickSound(loginSound);

    // validate username, email and password fields
    const validSymbols = /^[a-zA-Z0-9]+$/;
    // validate username, email and password fields

    // temporary errors in a function, at the end of function assign to useState
    const tempErrForm = {
      ...errForm,
    };
    // temporary errors in a function, at the end of function assign to useState

    // temporary user data, check if all fields are good to send to back side
    const newUser = {
      newPassword: "",
      newConfirmedPassword: "",
    };
    // temporary user data, check if all fields are good to send to back side

    // password validation //edit
    if (
      password.length < 6 ||
      password.length > 16 ||
      !password.match(validSymbols)
    ) {
      Object.assign(tempErrForm, {
        password_err_display: "edit-password_err_display",
      });

      Object.assign(newUser, {
        newPassword: "",
      });
    } else {
      Object.assign(tempErrForm, {
        password_err_display: "hidden",
      });

      Object.assign(newUser, {
        newPassword: password,
      });
    }
    // password validation //edit

    // confirmed Password validation //edit
    if (confirmedPassword !== password) {
      Object.assign(tempErrForm, {
        confirmedPassword_err_display: "edit-confirmedPassword_err_display",
      });
      Object.assign(newUser, {
        newConfirmedPassword: "",
      });
    } else {
      Object.assign(tempErrForm, {
        confirmedPassword_err_display: "hidden",
      });

      Object.assign(newUser, {
        newConfirmedPassword: confirmedPassword,
      });
    }
    // confirmed Password validation //edit

    // errors send to usestate to control classes //edit
    setErrForm(tempErrForm);
    // errors send to usestate to control classes //edit

    // temporary user object values, //edit
    const checkPassword = Object.values(newUser)[0];
    const checkConfirmedPassword = Object.values(newUser)[1];
    // temporary user object values, //edit

    // temporary user object values, to check if all fields are ok to send to back side while edit
    if (checkPassword === "" || checkConfirmedPassword === "") {
      console.log("Failed");
    } else {
      handleEdit(checkConfirmedPassword);

      setHideForConfirm("hidden");

      setHidden("Edit-hide");
    }
    // temporary user object values, to check if all fields are ok to send to back side while edit
  };

  return (
    <section className={hidden}>
      <EditCompletedFeatures />

      <div className={hideForConfirm}>
        {/* <h2>Edit Password</h2>  */}
        <div className="edit-form-err">
          {/* error messages */}
          <div className={password_err_display}>
            <p>
              !!! The password should be between 6 and 16 characters long.
              Numbers and letters should be used only. Symbols are not allowed.
            </p>
          </div>
          <div className={confirmedPassword_err_display}>
            <p>!!! The passwords do not match.</p>
          </div>
          {/* error messages */}
        </div>
        <form className="form" onSubmit={onSubmit}>
          <div className="inputBox">
            <input
              type="password"
              id="edit-password"
              name="password"
              value={password}
              onChange={onChange}
              required
              maxLength="16"
              title="The password must be between 6 and 16 letters long and can only contain letters and numbers."
              autoFocus
              autoComplete="off"
            />
            <i>New Password</i>
          </div>
          <div className="inputBox">
            <input
              type="password"
              name="confirmedPassword"
              id="edit-confirmedPassword"
              value={confirmedPassword}
              onChange={onChange}
              required
              maxLength="16"
              title="Double-check the retyped password."
              autoComplete="off"
            />
            <i>Re-type New Password</i>
          </div>
          <div
            className="inputBox"
            title="If passwords are matched, please go ahead and enter it."
          >
            <button className="submit_BTN" type="submit">
              Enter
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
