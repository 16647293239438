import React, { useState, useEffect } from "react";
import "./LogOutWindow.css";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SigninPlace

function LogoutCompletedMessage({
  isLogoutCompletedAsync,
  LogoutCompletedCondition,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    LogoutCompletedCondition
      ? setHidden("LogoutCompletedMessage")
      : setHidden("hidden");

    return () => {
      isLogoutCompletedAsync(false);
    };
  }, [LogoutCompletedCondition, isLogoutCompletedAsync]);

  return (
    <div className={hidden}>
      <h2>You have successfully logged out!</h2>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutCompletedMessage);
