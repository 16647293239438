const initialSocialState = {
  singlePlayer: false,
  multiplayer: false,

  tournaments: {
    selected: false,

    worldCup: false,

    continental: {
      selected: false,
    },

    country: {
      selected: false,
    },

    city: {
      selected: false,
    },

    event: {
      selected: false,
    },
  },
  specifiedTourType: " ",
};

const gameTypeReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISSINGLEUSER":
      return {
        ...state,
        singlePlayer: action.payload,
      };

    case "ISMULTIPLAYER":
      return {
        ...state,
        multiplayer: action.payload,
      };

    case "ISTOURNAMENT":
      return {
        ...state,
        tournaments: {
          ...state.tournaments,
          selected: action.payload,
        },
      };

    case "ISSPECIFIEDTOURTYPE":
      return {
        ...state,
        specifiedTourType: action.payload,
      };

    case "ISWORLD":
      return {
        ...state,
        tournaments: {
          ...state.tournaments,
          worldCup: action.payload,
        },
      };

    case "ISCONTINENTAL":
      return {
        ...state,
        tournaments: {
          ...state.tournaments,
          continental: {
            ...state.tournaments.continental,
            selected: action.payload,
          },
        },
      };

    case "ISCOUNTRY":
      return {
        ...state,
        tournaments: {
          ...state.tournaments,
          country: {
            ...state.tournaments.country,
            selected: action.payload,
          },
        },
      };

    case "ISCITY":
      return {
        ...state,
        tournaments: {
          ...state.tournaments,
          city: {
            ...state.tournaments.city,
            selected: action.payload,
          },
        },
      };

    case "ISEVENT":
      return {
        ...state,
        tournaments: {
          ...state.tournaments,
          event: {
            ...state.tournaments.event,
            selected: action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default gameTypeReducer;
