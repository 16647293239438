import React, { useState, useEffect } from "react";
import "../CompletedFeatures.css";
import checked from "../../../Images/checedBtn.png";
import errorBtn from "../../../Images/errorBtn.png";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import LoaderSimple from "../../Loading/LoaderSimple";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// parent component is - EditUserName, EditEmail, EditPassword

function EditCompletedFeatures({
  isEditCompletedCondition,
  isEditCompletedAsync,
}) {
  const navigate = useNavigate();

  const [hidden, setHidden] = useState("hiiden");

  const [text, setText] = useState("");

  const [img, setImg] = useState();

  const [btnText, setBtnText] = useState();

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (isEditCompletedCondition === "username") {
      setText("Username changed successfully");

      setImg(checked);

      setBtnText("Ok");

      setHidden("completed_successfuly-editting");

      setTitle("successfully changed");
    } else if (isEditCompletedCondition === "email") {
      setText("E-mail changed successfully");

      setImg(checked);

      setBtnText("Ok");

      setHidden("completed_successfuly-editting");

      setTitle("successfully changed");
    } else if (isEditCompletedCondition === "password") {
      setText("Password changed successfully");

      setImg(checked);

      setBtnText("Ok");

      setHidden("completed_successfuly-editting");

      setTitle("successfully changed");
    } else if (isEditCompletedCondition === "error") {
      setText("Unfortunately, it didn't change ");

      setImg(errorBtn);

      setBtnText("Try Again");

      setHidden("completed_successfuly-editting");

      setTitle("oops - !!!");
    } else {
      setHidden("hidden");
    }
  }, [isEditCompletedCondition]);

  const handleSignin = (e) => {
    const btnName = e.target.name;

    //play click
    ClickSound(popUpSound);

    isEditCompletedAsync("");

    btnName === "Ok" ? navigate("/") : navigate(0);
  };

  return (
    <div className={hidden}>
      <div className="completed_successfuly-checked-editting">
        <img
          className="completed_successfuly_checked_img"
          src={img}
          title={title}
          alt="check"
        />
      </div>

      <div className="completed_successfuly_loading-editting">
        <LoaderSimple />
      </div>

      <div className="completed_successfuly_message-editting">{text}</div>

      <div className="completed_successfuly_btn-editting" title={title}>
        <button className="submit_BTN" onClick={handleSignin} name={btnText}>
          {btnText}
        </button>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditCompletedFeatures);
