import React from "react";
import "./RestartGame.css";

// parent component is - GameOver

function RestartGame({ thisRoom }) {
  return (
    <div className="RestartGame">
      {thisRoom.players.map((player, index) => (
        <div className="RestartGame-cardContainer" key={index}>
          <div className="RestartGame-card">
            <p className="RestartGame-cardTitle">{player.name.toUpperCase()}</p>
            <p className="RestartGame-Cardtext">
              {!thisRoom.whosTurn[`player${index + 1}`].restart
                ? "Is Not Ready"
                : "Ready To Play"}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RestartGame;
