// Initial State
const initialState = {
  room: "",
  rooms: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const authRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_ROOM_REQUEST":
    case "EDIT_ROOM_REQUEST":
    case "DELETE_ROOM_REQUEST":
    case "GET_ALL_ROOMS_REQUEST":
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: "",
      };

    case "CREATED_ROOM_SUCCESS":
      return {
        ...state,
        room: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "EDIT_ROOM_SUCCESS":
      return {
        ...state,
        room: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "GET_ALL_ROOMS_SUCCESS":
      return {
        ...state,
        rooms: action.payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "CREATE_ROOM_FAILURE":
    case "DELETE_ROOM_FAILURE":
    case "EDIT_ROOM_FAILURE":
    case "GET_ALL_ROOMS_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        message: action.payload,
      };

    case "DELETE_ROOM_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "",
      };

    case "RESETROOMS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: "",
      };

    default:
      return state;
  }
};

export default authRoomReducer;
