const ISCLICKEDAVATARFEATURES = "ISCLICKEDAVATARFEATURES";

const selectedClickedAvatarFeatures = (check) => {
  return {
    type: ISCLICKEDAVATARFEATURES,
    payload: check,
  };
};

export const selectedClickedAvatarFeaturesAsync = (check) => (dispatch) => {
  dispatch(selectedClickedAvatarFeatures(check));
};
