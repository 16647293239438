const initialSocialState = {
  exitRoom: [false, ""],
};

const isExitRoomReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "EXITROOM":
      return {
        ...state,
        exitRoom: action.payload,
      };

    default:
      return state;
  }
};

export default isExitRoomReducer;
