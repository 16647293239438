const ISWATCHING = "ISWATCHING";

const isWatchingTheGame = (check) => {
  return {
    type: ISWATCHING,
    payload: check,
  };
};

export const isWatchingTheGameAsync = (check) => (dispatch) => {
  dispatch(isWatchingTheGame(check));
};
