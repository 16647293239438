import axios from "axios";
const serverURL = process.env.REACT_APP_API_URL;

const CREATE_ROOM_REQUEST = "CREATE_ROOM_REQUEST";
const CREATED_ROOM_SUCCESS = "CREATED_ROOM_SUCCESS";
const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE";

const DELETE_ROOM_REQUEST = "DELETE_ROOM_REQUEST";
const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
const DELETE_ROOM_FAILURE = "DELETE_ROOM_FAILURE";

const EDIT_ROOM_REQUEST = "EDIT_ROOM_REQUEST";
const EDIT_ROOM_SUCCESS = "EDIT_ROOM_SUCCESS";
const EDIT_ROOM_FAILURE = "EDIT_ROOM_FAILURE";

const GET_ALL_ROOMS_REQUEST = "GET_ALL_ROOMS_REQUEST";
const GET_ALL_ROOMS_SUCCESS = "GET_ALL_ROOMS_SUCCESS";
const GET_ALL_ROOMS_FAILURE = "GET_ALL_ROOMS_FAILURE";

const RESETROOMS = "RESETROOMS";

// Action Creators
const createRequest = () => ({
  type: CREATE_ROOM_REQUEST,
  payload: "Create Request",
});

const createSuccess = (room) => ({
  type: CREATED_ROOM_SUCCESS,
  payload: room,
});

const createFailure = (error) => ({
  type: CREATE_ROOM_FAILURE,
  payload: error,
});

export const editRoomRequest = () => ({
  type: EDIT_ROOM_REQUEST,
  payload: "Edit Room Request",
});

export const editRoomSuccess = (roomData) => ({
  type: EDIT_ROOM_SUCCESS,
  payload: roomData,
});

export const editRoomFailure = (error) => ({
  type: EDIT_ROOM_FAILURE,
  payload: error,
});

export const deleteRoomRequest = () => ({
  type: DELETE_ROOM_REQUEST,
  payload: "Delete Request",
});

export const deleteRoomSuccess = () => ({
  type: DELETE_ROOM_SUCCESS,
  payload: "Deleted SuccessFully",
});

export const deleteRoomFailure = (error) => ({
  type: DELETE_ROOM_FAILURE,
  payload: error,
});

export const getAllRoomsRequest = () => ({
  type: GET_ALL_ROOMS_REQUEST,
  payload: "Get All Rooms Request",
});

export const getAllRoomsSuccess = (roomData) => ({
  type: GET_ALL_ROOMS_SUCCESS,
  payload: roomData,
});

export const getAllRoomsFailure = (error) => ({
  type: GET_ALL_ROOMS_FAILURE,
  payload: error,
});

const reset = () => {
  return {
    type: RESETROOMS,
  };
};

export const createRoomAsync = (room) => async (dispatch) => {
  try {
    dispatch(createRequest(room));

    const roomData = await axios.post(`${serverURL}/api/rooms/`, room);

    if (roomData.data) {
      // localStorage.setItem("room", JSON.stringify(roomData.data))

      dispatch(createSuccess(roomData.data));
    }

    return roomData.data;
  } catch (error) {
    const message =
      (error.roomData && error.roomData.data && error.roomData.data.message) ||
      error.message ||
      error.toString();

    return dispatch(createFailure(message));
  }
};

export const editRoomAsync = (roomId, roomData) => async (dispatch) => {
  try {
    dispatch(editRoomRequest());

    const response = await axios.patch(
      `${serverURL}/api/rooms/${roomId}/edit`,
      roomData,
    );

    const updatedRoom = response.data.room;

    dispatch(editRoomSuccess(updatedRoom));

    return true;
  } catch (err) {
    dispatch(editRoomFailure(err.message));

    return false;
  }
};

export const deleteRoomAsync = (roomId) => async (dispatch) => {
  try {
    dispatch(deleteRoomRequest());

    await axios.delete(`${serverURL}/api/rooms/${roomId}/delete`);

    dispatch(deleteRoomSuccess());

    localStorage.removeItem("room");

    return true;
  } catch (err) {
    dispatch(deleteRoomFailure(err.message));

    return false;
  }
};

export const getAllRoomsAsync = () => async (dispatch) => {
  try {
    dispatch(getAllRoomsRequest());

    const roomData = await axios.get(`${serverURL}/api/rooms/`);

    if (roomData.data) {
      dispatch(getAllRoomsSuccess(roomData.data));
    }

    return roomData.data;
  } catch (error) {
    const message =
      (error.roomData && error.roomData.data && error.roomData.data.message) ||
      error.message ||
      error.toString();

    return dispatch(getAllRoomsFailure(message));
  }
};

export const resetRoomsAsync = () => (dispatch) => {
  dispatch(reset());
};
