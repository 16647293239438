import React, { useEffect, useState } from "react";
import "./AvatarBonusCard.css";
import "./AvatarBonusCardAnimation.css";
import AvatarBonusDollarBag from "../AvatarBonusDollarBag/AvatarBonusDollarBag";
import LoaderCircle from "../../../Loading/LoaderCircle";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarBonusCard({
  avatarFeatureCondition,
  assets,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  // get user data
  const [userPoints, setUserPoints] = useState("");
  const [userCoins, setUserCoins] = useState("");

  useEffect(() => {
    if (assets !== null && assets !== undefined) {
      setUserPoints(assets.points);

      setUserCoins(assets.coins);
    } else {
      setUserPoints(null);

      setUserCoins(null);
    }
  }, [assets]);
  // get user data

  // hidden, if clicked edit Profile
  const [hidden, setHidden] = useState("AvatarBonusCard");

  useEffect(() => {
    setHidden(
      deleteWindowCondition
        ? "hidden"
        : avatarFeatureCondition === "coin" && !logOutWindowCondition
          ? "AvatarBonusCard"
          : "hidden",
    );
  }, [avatarFeatureCondition, logOutWindowCondition, deleteWindowCondition]);
  // hidden, if clicked edit Profile

  return (
    <div className={hidden}>
      <div className="AvatarBonusCard-loading-circle-place">
        <LoaderCircle />
      </div>
      <div className="AvatarBonusCard_points">
        <h2>Points</h2>
        <p>{userPoints}</p>
      </div>

      <AvatarBonusDollarBag />

      <div className="AvatarBonusCard_coins">
        <h2>Coins</h2>
        <p>{userCoins}</p>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarBonusCard);
