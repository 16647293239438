import axios from "axios";
const serverURL = process.env.REACT_APP_API_URL;

const GET_ROOM_REQUEST = "GET_ROOM_REQUEST";
const GET_ROOM_SUCCESS = "GET_ROOM_SUCCESS";
const GET_ROOM_FAILURE = "GET_ROOM_FAILURE";

const RESETROOM = "RESETROOM";

export const getRoomRequest = () => ({
  type: GET_ROOM_REQUEST,
  payload: "Get Single Room Request",
});

export const getRoomSuccess = (roomData) => ({
  type: GET_ROOM_SUCCESS,
  payload: roomData,
});

export const getRoomFailure = (error) => ({
  type: GET_ROOM_FAILURE,
  payload: error,
});

const reset = () => {
  return {
    type: RESETROOM,
  };
};

export const getRoomByIdAsync = (roomId) => async (dispatch) => {
  try {
    dispatch(getRoomRequest());

    const roomData = await axios.get(`${serverURL}/api/rooms/${roomId}`);

    if (roomData.data) {
      dispatch(getRoomSuccess(roomData.data));
    }

    return roomData.data;
  } catch (error) {
    const message =
      (error.roomData && error.roomData.data && error.roomData.data.message) ||
      error.message ||
      error.toString();

    dispatch(getRoomFailure(message));

    // throw error;
  }
};

export const resetRoomAsync = () => (dispatch) => {
  dispatch(reset());
};
