import React from "react";
import "./Temporary.css";

// parent component is - Tournaments

function Temporary() {
  return <div className="temporary">The Cup is currently unavailable</div>;
}

export default Temporary;
