import axios from "axios";
const serverURL = process.env.REACT_APP_API_URL;

const CREATE_REWARD_REQUEST = "CREATE_REWARD_REQUEST";
const CREATED_REWARD_SUCCESS = "CREATED_REWARD_SUCCESS";
const CREATE_REWARD_FAILURE = "CREATE_REWARD_FAILURE";

const DELETE_REWARD_REQUEST = "DELETE_REWARD_REQUEST";
const DELETE_REWARD_SUCCESS = "DELETE_REWARD_SUCCESS";
const DELETE_REWARD_FAILURE = "DELETE_REWARD_FAILURE";

const EDIT_REWARD_REQUEST = "EDIT_REWARD_REQUEST";
const EDIT_REWARD_SUCCESS = "EDIT_REWARD_SUCCESS";
const EDIT_REWARD_FAILURE = "EDIT_REWARD_FAILURE";

const GET_ALL_REWARDS_REQUEST = "GET_ALL_REWARDS_REQUEST";
const GET_ALL_REWARDS_SUCCESS = "GET_ALL_REWARDS_SUCCESS";
const GET_ALL_REWARDS_FAILURE = "GET_ALL_REWARDS_FAILURE";

const RESETREWARDS = "RESETREWARDS";

// Action Creators
const createRequest = () => ({
  type: CREATE_REWARD_REQUEST,
  payload: "Create Request",
});

const createSuccess = (reward) => ({
  type: CREATED_REWARD_SUCCESS,
  payload: reward,
});

const createFailure = (error) => ({
  type: CREATE_REWARD_FAILURE,
  payload: error,
});

export const editRewardsRequest = () => ({
  type: EDIT_REWARD_REQUEST,
  payload: "Edit Reward Request",
});

export const editRewardSuccess = (rewardData) => ({
  type: EDIT_REWARD_SUCCESS,
  payload: rewardData,
});

export const editRewardFailure = (error) => ({
  type: EDIT_REWARD_FAILURE,
  payload: error,
});

export const deleteRewardRequest = () => ({
  type: DELETE_REWARD_REQUEST,
  payload: "Delete Request",
});

export const deleteRewardSuccess = () => ({
  type: DELETE_REWARD_SUCCESS,
  payload: "Deleted SuccessFully",
});

export const deleteRewardFailure = (error) => ({
  type: DELETE_REWARD_FAILURE,
  payload: error,
});

export const getAllRewardsRequest = () => ({
  type: GET_ALL_REWARDS_REQUEST,
  payload: "Get All Rewards Request",
});

export const getAllRewardsSuccess = (rewardData) => ({
  type: GET_ALL_REWARDS_SUCCESS,
  payload: rewardData,
});

export const getAllRewardsFailure = (error) => ({
  type: GET_ALL_REWARDS_FAILURE,
  payload: error,
});

const reset = () => {
  return {
    type: RESETREWARDS,
  };
};

export const createRewardAsync = (reward) => async (dispatch) => {
  try {
    dispatch(createRequest(reward));

    const rewardData = await axios.post(`${serverURL}/api/rewards/`, reward);

    if (rewardData.data) {
      dispatch(createSuccess(rewardData.data));
    }

    return rewardData.data;
  } catch (error) {
    const message =
      (error.rewardData &&
        error.rewardData.data &&
        error.rewardData.data.message) ||
      error.message ||
      error.toString();

    return dispatch(createFailure(message));
  }
};

export const editRewardAsync = (rewardId, rewardData) => async (dispatch) => {
  try {
    dispatch(editRewardsRequest());

    const response = await axios.patch(
      `${serverURL}/api/rewards/${rewardId}/edit`,
      rewardData,
    );

    const updatedReward = response.data.reward;

    dispatch(editRewardSuccess(updatedReward));

    return true;
  } catch (err) {
    dispatch(editRewardFailure(err.message));

    return false;
  }
};

export const getAllRewardsAsync = () => async (dispatch) => {
  try {
    dispatch(getAllRewardsRequest());

    const rewardData = await axios.get(`${serverURL}/api/rewards/`);

    if (rewardData.data) {
      dispatch(getAllRewardsSuccess(rewardData.data));
    }

    return rewardData.data;
  } catch (error) {
    const message =
      (error.rewardData &&
        error.rewardData.data &&
        error.rewardData.data.message) ||
      error.message ||
      error.toString();

    return dispatch(getAllRewardsFailure(message));
  }
};

export const deleteRewardAsync = (rewardId) => async (dispatch) => {
  try {
    dispatch(deleteRewardRequest());

    await axios.delete(`${serverURL}/api/rewards/${rewardId}/delete`);

    dispatch(deleteRewardSuccess());

    return true;
  } catch (err) {
    dispatch(deleteRewardFailure(err.message));

    return false;
  }
};

export const resetRewardsAsync = () => (dispatch) => {
  dispatch(reset());
};
