const ISROOMAVAILABLE = "ISROOMAVAILABLE";

const isPlayerRoomAvailable = (check) => {
  return {
    type: ISROOMAVAILABLE,
    payload: check,
  };
};

export const isPlayerRoomAvailableAsync = (check) => (dispatch) => {
  dispatch(isPlayerRoomAvailable(check));
};
