import React, { useEffect, useState } from "react";
import "./LogOutWindow.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import logOutSound from "../../../Audio/logOutSound.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AvatarFeatures

function LogOutWindow({
  isClickedLogoutWindowAsync,
  logOutWindowCondition,
  logoutAsync,
  isLogoutCompletedAsync,
  resetAsync,
}) {
  const [hidden, setHidden] = useState("hidden");

  useEffect(() => {
    logOutWindowCondition ? setHidden("logout-container") : setHidden("hidden");

    return;
  }, [logOutWindowCondition]);

  const handleClick = (e) => {
    if (e.target.name === "exit") {
      //play click
      ClickSound(logOutSound);

      logoutAsync();

      isLogoutCompletedAsync(true);

      resetAsync();
    } else {
      //play click
      ClickSound(popUpSound);

      isClickedLogoutWindowAsync(false);
    }
  };

  return (
    <div className={hidden}>
      <h2>Are you sure you want to log out?</h2>

      <div className="logout-container_Btn_Place">
        <button
          className="logout-container_Btn_Place_button"
          name="cancel"
          onClick={handleClick}
        >
          <div className="logout-container_Btn_Place_text">Cancel</div>
        </button>
        <button
          className="logout-container_Btn_Place_button"
          name="exit"
          onClick={handleClick}
        >
          <div className="logout-container_Btn_Place_text">Exit</div>
        </button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LogOutWindow);
