import React, { useEffect, useState } from "react";
import "./NavPages.css";
import "./NavPagesAnimation.css";

// parent component is - GameSettings

const createFeature = (title, header) => {
  const [checked, setChecked] = useState(() => {
    const initialValue = localStorage.getItem("music");
    return initialValue !== null ? JSON.parse(initialValue) : false;
  });

  const handleClick = () => {
    if (JSON.parse(localStorage.getItem("music"))) {
      localStorage.setItem("music", false);
      setChecked(false);
    } else {
      localStorage.setItem("music", true);
      setChecked(true);
    }
  };

  return (
    <form
      className="GameSettings_items"
      title={title}
      name="GameSettings_items-form"
    >
      <h4>{header}</h4>
      <label className="items_switch">
        <input
          type="checkbox"
          name={title}
          onChange={handleClick}
          value={checked}
          defaultChecked={checked}
        />
        <div className="items_slider">
          <span>{checked ? "Off" : "Off"}</span>
          <span>{!checked ? "On" : "On"}</span>
        </div>
      </label>
    </form>
  );
};

function GameSettingsFeatures({ isMutedMusicAsync }) {
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("music"))) {
      isMutedMusicAsync(false);
    } else {
      isMutedMusicAsync(true);
    }
  }, [localStorage.getItem("music")]);

  return (
    <div className="NavFeatures">
      <h4>Make Changes: &apos;198 Runners&apos; Online</h4>
      <h5>All the settings for this game can be found here</h5>
      <div className="NavFeatures-content">
        {createFeature("music", "Music:")}
      </div>
    </div>
  );
}

export default GameSettingsFeatures;
