import React from "react";
import "./Loading.css";
import "./LoadingAnimation.css";

function LoadingHexagon() {
  return (
    <div className="LoadingHexagon">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingHexagon;
