const initialSocialState = {
  isWatchingOn: "",
};

const watchingTheGameReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISWATCHING":
      return {
        ...state,
        isWatchingOn: action.payload,
      };

    default:
      return state;
  }
};

export default watchingTheGameReducer;
