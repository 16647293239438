import React, { useEffect, useState } from "react";
import "./GameOver.css";
import "./GameOverAnimation.css";
import popUpSound from "../../../../Audio/popUp.mp3";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import wonTheGameSound from "../../../../Audio/wonTheGame.mp3";
import lostTheGameSound from "../../../../Audio/lostTheGame.mp3";
import GameOverBackGroundMusic from "../../../MusicAndSounds/GameOverBackGroundMusic";
import LoaderSimple from "../../../Loading/LoaderSimple";
import gameOverLogo from "../../../../Images/gameOverLogo.png";
import ExitRoomConfirm from "../ExitRoomConfirm/ExitRoomConfirm";
import GameOverTimer from "./GameOverTimer";
import RestartGame from "./RestartGame/RestartGame";
import Explanation from "./Explanation/Explanation";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SingleRoom

function GameOver({
  roomData,
  isExitRoomAsync,
  deleteRoomAsync,
  resetRoomAsync,
  resetRoomsAsync,
  hideRestartGameCondition,
  editRoomAsync,
  isHideRestartGameAsync,
  editRewardAsync,
  hamburgerCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  // roomData is coming from parent component - SingleRoom
  const [
    roomNumber,
    roomId,
    userId,
    userName,
    exactLocation,
    ,
    ,
    thisRoomPlayerIds,
    thisRoomWhosTurn,
    thisRoom,
    ,
  ] = roomData;
  // roomData is coming from parent component - SingleRoom

  const [hidden, setHidden] = useState(true);
  const [lostOrWin, setLostOrWin] = useState("");
  const [showSalute, setShowSalute] = useState("");
  const [hideBtn, setHideBtn] = useState("GameOver-btnsPlace_btn");

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      const indexOfUser = thisRoomPlayerIds.indexOf(userId);

      const rewardWinner = {
        championStatus: {
          roomId: roomId + thisRoomWhosTurn.specialRandom,
          gameType: "blitz",
          gameOver: "win",
        },
      };

      const rewardLoser = {
        championStatus: {
          roomId: roomId + thisRoomWhosTurn.specialRandom,
          gameType: "blitz",
          gameOver: "lost",
        },
      };

      if (exactLocation === "single-player") {
        if (thisRoomWhosTurn.gameOver && indexOfUser === 0) {
          setHidden(false);

          if (thisRoomWhosTurn.player1.turn === false) {
            setLostOrWin("GameOver-won");
            setShowSalute("hoorah");

            editRewardAsync(userId, rewardWinner);
          } else {
            setLostOrWin("GameOver-lost");
            setShowSalute("hidden");

            editRewardAsync(userId, rewardLoser);
          }
        } else if (thisRoomWhosTurn.gameOver && indexOfUser === 1) {
          setHidden(false);

          if (thisRoomWhosTurn.player2.turn === false) {
            setLostOrWin("GameOver-won");
            setShowSalute("hoorah");

            editRewardAsync(userId, rewardWinner);
          } else {
            setLostOrWin("GameOver-lost");
            setShowSalute("hidden");

            editRewardAsync(userId, rewardLoser);
          }
        } else {
          setHidden(true);
        }
      } else if (exactLocation === "multi-player") {
        if (
          thisRoomWhosTurn.gameOver &&
          (indexOfUser === 0 || indexOfUser === 2)
        ) {
          setHidden(false);

          if (
            thisRoomWhosTurn.player1.turn === false &&
            thisRoomWhosTurn.player3.turn === false
          ) {
            setLostOrWin("GameOver-won");
            setShowSalute("hoorah");

            editRewardAsync(userId, rewardWinner);
          } else {
            setLostOrWin("GameOver-lost");
            setShowSalute("hidden");

            editRewardAsync(userId, rewardLoser);
          }
        } else if (
          thisRoomWhosTurn.gameOver &&
          (indexOfUser === 1 || indexOfUser === 3)
        ) {
          setHidden(false);

          if (
            thisRoomWhosTurn.player2.turn === false &&
            thisRoomWhosTurn.player4.turn === false
          ) {
            setLostOrWin("GameOver-won");
            setShowSalute("hoorah");

            editRewardAsync(userId, rewardWinner);
          } else {
            setLostOrWin("GameOver-lost");
            setShowSalute("hidden");

            editRewardAsync(userId, rewardLoser);
          }
        } else {
          setHidden(true);
        }
      }
    }
  }, [
    thisRoomWhosTurn,
    thisRoomPlayerIds,
    userId,
    exactLocation,
    roomId,
    editRewardAsync,
  ]);

  // reset restart component
  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      if (!thisRoomWhosTurn.gameOver) {
        isHideRestartGameAsync(false);

        setHideBtn("GameOver-btnsPlace_btn");
      }
    }
  }, [isHideRestartGameAsync, thisRoomWhosTurn]);
  // reset restart component

  const handleGameOverBtn = (e) => {
    //play click
    ClickSound(popUpSound);

    const clicked = e.target.name;

    if (clicked === "exit") {
      isExitRoomAsync([true, "player"]);
    } else if (clicked === "play") {
      const playerIndex =
        thisRoomPlayerIds !== undefined
          ? thisRoomPlayerIds.indexOf(userId)
          : undefined;

      if (playerIndex !== -1) {
        const readyToPlay = {
          whosTurnReadyPlayer: {
            [`player${playerIndex + 1}`]: {
              turn: false,
              restart: true,
            },
          },
        };

        setHideBtn("hidden");

        editRoomAsync(roomId, readyToPlay);
      }
    }
  };

  return !hidden ? (
    <div className="GameOver">
      {!hamburgerCondition && lostOrWin === "GameOver-won" ? (
        <GameOverBackGroundMusic
          Audiodata={[
            wonTheGameSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      {!hamburgerCondition && lostOrWin === "GameOver-lost" ? (
        <GameOverBackGroundMusic
          Audiodata={[
            lostTheGameSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="GameOver-loading">
        <LoaderSimple />
      </div>

      <ExitRoomConfirm
        newRoomData={[
          roomNumber,
          exactLocation,
          roomId,
          userId,
          userName,
          thisRoomWhosTurn,
        ]}
      />

      <div className="GameOver-transparency">
        <div className="GameOver-transparency-logoPlace">
          <img
            className="GameOver-transparency-logo"
            src={gameOverLogo}
            title="Home | 198 Runners"
            alt="logo"
          />
        </div>

        {/* Salute, hide when countdown is 0 */}
        {!hideRestartGameCondition ? (
          <div className={showSalute}>
            <div className="before"></div>
            <div className="after"></div>
          </div>
        ) : null}
        {/* Salute, hide when countdown is 0 */}
      </div>

      {/* Lost or Win, hide when countdown is 0  */}
      {!hideRestartGameCondition ? <div className={lostOrWin}></div> : null}
      {/* Lost or Win, hide when countdown is 0  */}

      {/* Show ready players */}
      {hideRestartGameCondition ? <RestartGame thisRoom={thisRoom} /> : null}
      {/* Show ready players */}

      <div className="GameOver-btnsPlace">
        <button
          className={hideBtn}
          name="play"
          title="Play Again"
          onClick={handleGameOverBtn}
        >
          <div className="GameOver-btnsPlace_btn_text">Play Again</div>
        </button>

        <GameOverTimer
          newRoomData={[
            thisRoom,
            thisRoomWhosTurn,
            thisRoomPlayerIds,
            exactLocation,
            roomId,
            userId,
            deleteRoomAsync,
            resetRoomAsync,
            resetRoomsAsync,
            isExitRoomAsync,
          ]}
        />

        <button
          className="GameOver-btnsPlace_btn"
          name="exit"
          title="Exit Game"
          onClick={handleGameOverBtn}
        >
          <div className="GameOver-btnsPlace_btn_text">Exit Game</div>
        </button>

        <Explanation />
      </div>
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(GameOver);
