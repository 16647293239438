import React from "react";
import "./AllMultiRoomsContainerArea.css";
import LoaderSimple from "../../../Loading/LoaderSimple";
import AllMultiRoomsContainer from "../AllMultiRoomsContainer/AllMultiRoomsContainer";

// parent component is - MultiPlayerGame

function AllMultiRoomsContainerArea() {
  return (
    <section className="AllMultiRoomsContainerArea">
      <div className="AllMultiRoomsContainerArea-loading">
        <LoaderSimple />
      </div>

      <AllMultiRoomsContainer />
    </section>
  );
}

export default AllMultiRoomsContainerArea;
