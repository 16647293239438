import React from "react";
import "./Hamburger.css";
import "./HamburgerAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import popUpSound from "../../../Audio/popUp.mp3";
import navMenuSound from "../../../Audio/navMenuSound.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";
import { useEffect, useState } from "react";

// parent component is - HeaderGeneral

function Hamburger({
  isClickedHamburgerAsync,
  hamburgerCondition,
  isClickedLogoutWindowAsync,
  isClickedDeleteWindowAsync,
  isSelectedEditTypeAsync,
  selectedClickedAvatarFeaturesAsync,
  isEditCompletedAsync,
}) {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    hamburgerCondition ? setClicked(true) : setClicked(false);
  }, [hamburgerCondition]);

  // clicked hamburger
  const handleHamburgerClick = () => {
    if (!clicked) {
      isClickedHamburgerAsync(true);

      //play click
      ClickSound(navMenuSound);
    } else {
      //play click
      ClickSound(popUpSound);

      isClickedHamburgerAsync(false);

      isClickedLogoutWindowAsync(false);

      isClickedDeleteWindowAsync(false);

      selectedClickedAvatarFeaturesAsync("coin");

      isSelectedEditTypeAsync("");

      isEditCompletedAsync("");
    }
  };
  // clicked hamburger

  return (
    <section
      id="hamburger_button"
      title="Game Menu"
      onClick={handleHamburgerClick}
    >
      <input type="checkbox" id="hamburger_checkbox" />
      <label htmlFor="hamburger_checkbox" id="hamburger_label">
        <div id="hamburger_text_bar"></div>
      </label>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Hamburger);
