import React, { useState, useEffect } from "react";
import "./EditForms.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import loginSound from "../../../Audio/createGame.mp3";
import EditCompletedFeatures from "./EditCompletedFeatures";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - EditFormGeneral

function EditEmail({
  isEditTypeCondition,
  editProfileAsync,
  isEditCompletedAsync,
  user,
}) {
  const [hidden, setHidden] = useState("hidden");

  const [hideForConfirm, setHideForConfirm] = useState("EditEmail_content");

  useEffect(() => {
    isEditTypeCondition === "email"
      ? setHidden("EditEmail")
      : setHidden("hidden");
  }, [isEditTypeCondition]);

  // Form error messages
  const [errForm, setErrForm] = useState({
    email_err_display: "hidden",
  });

  // Distracture Form error messages
  const { email_err_display } = errForm;
  // Form error messages

  // Form Data
  const [formData, setFormData] = useState({
    email: "",
  });

  // Distracture Form Data
  const { email } = formData;

  // Form Data

  // Get Form Values
  const onChange = (e) => {
    setFormData((previousState) => ({
      ...previousState,
      [e.target.name]: e.target.value,
    }));
  };
  // Get Form Values

  // Handle edit email
  const handleEdit = async (email) => {
    const token = user.token;

    const userData = {
      email: email,
    };

    const authSuccess = await editProfileAsync(token, userData);

    if (authSuccess) {
      isEditCompletedAsync("email");
    } else {
      isEditCompletedAsync("error");
    }
  };
  // Handle edit email

  const onSubmit = (e) => {
    e.preventDefault();

    //play click
    ClickSound(loginSound);

    // validate email
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // validate email

    // temporary errors in a function, at the end of function assign to useState
    const tempErrForm = {
      ...errForm,
    };
    // temporary errors in a function, at the end of function assign to useState

    // temporary user data, check if all fields are good to send to back side
    const newUser = {
      newEmail: "",
    };
    // temporary user data, check if all fields are good to send to back side

    // email validation //edit
    if (email.length < 6 || email.length > 32 || !email.match(validEmail)) {
      Object.assign(tempErrForm, {
        email_err_display: "edit-email_err_display",
      });

      Object.assign(newUser, {
        newEmail: "",
      });
    } else {
      Object.assign(tempErrForm, {
        email_err_display: "hidden",
      });

      Object.assign(newUser, {
        newEmail: email,
      });
    }
    // email validation //edit

    // errors send to usestate to control classes //edit
    setErrForm(tempErrForm);
    // errors send to usestate to control classes //edit

    // temporary user object values, //edit
    const checkEmail = Object.values(newUser)[0];
    // temporary user object values, //edit

    // temporary user object values, to check if all fields are ok to send to back side while edit
    if (checkEmail !== null) {
      handleEdit(checkEmail);

      setHideForConfirm("hidden");

      setHidden("Edit-hide");
    }
    // temporary user object values, to check if all fields are ok to send to back side while edit
  };

  return (
    <section className={hidden}>
      <EditCompletedFeatures />

      <div className={hideForConfirm}>
        {/* <h2>Edit E-mail</h2>  */}
        <div className="edit-form-err">
          {/* error messages */}
          <div className={email_err_display}>
            <p>!!! Please enter a valid email address.</p>
          </div>
          {/* error messages */}
        </div>
        <form className="form" onSubmit={onSubmit}>
          <div className="inputBox">
            <input
              type="email"
              id="edit-email"
              name="email"
              required
              maxLength="32"
              title="The Email should be between 6 and 32 characters long."
              autoFocus
              autoComplete="email"
              value={email}
              onChange={onChange}
            />
            <i>New E-mail</i>
          </div>
          <div
            className="inputBox"
            title="If your new email address is correct, please enter it."
          >
            <button className="submit_BTN" type="submit">
              Enter
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmail);
