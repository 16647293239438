const initialState = {
  reward: undefined,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

const singleRewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REWARD_REQUEST":
      return {
        ...state,
        isLoading: true,
      };

    case "GET_REWARD_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        reward: action.payload,
      };

    case "GET_REWARD_FAILURE":
      return {
        ...state,
        reward: undefined,
        isError: true,
        isSuccess: false,
        isLoading: false,
        message: action.payload,
      };

    case "RESETREWARD":
      return {
        reward: undefined,
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: "",
      };

    default:
      return state;
  }
};

export default singleRewardReducer;
