const LOGOUTCOMPLETED = "LOGOUTCOMPLETED";

const isLogoutCompleted = (check) => {
  return {
    type: LOGOUTCOMPLETED,
    payload: check,
  };
};

export const isLogoutCompletedAsync = (check) => (dispatch) => {
  dispatch(isLogoutCompleted(check));
};
