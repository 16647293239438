// General // FIXED /////////////////////////////////////////
const ISSINGLEUSER = "ISSINGLEUSER";
const ISMULTIPLAYER = "ISMULTIPLAYER";
const ISTOURNAMENT = "ISTOURNAMENT";
const ISSPECIFIEDTOURTYPE = "ISSPECIFIEDTOURTYPE";

// Tournament World Cup // FIXED ////////////////////////////
const ISWORLD = "ISWORLD";

// Tournament Continental Cup // FIXED //////////////////////
const ISCONTINENTAL = "ISCONTINENTAL";

// Tournament Country Cup // Could Be Added \\\\\\\\\\\\\\\\\
const ISCOUNTRY = "ISCOUNTRY";

// Tournament City Cup // Could Be Added \\\\\\\\\\\\\\\\\\\\\
const ISCITY = "ISCITY";

// Tournament Event Cup // Could Be Added \\\\\\\\\\\\\\\\\\\\\
const ISEVENT = "ISEVENT";

const isSingleUser = (check) => {
  return {
    type: ISSINGLEUSER,
    payload: check,
  };
};

export const isSingleUserAsync = (check) => (dispatch) => {
  dispatch(isSingleUser(check));
};

const isMultiPlayer = (check) => {
  return {
    type: ISMULTIPLAYER,
    payload: check,
  };
};

export const isMultiPlayerAsync = (check) => (dispatch) => {
  dispatch(isMultiPlayer(check));
};

const isTournament = (check) => {
  return {
    type: ISTOURNAMENT,
    payload: check,
  };
};

export const isTournamentAsync = (check) => (dispatch) => {
  dispatch(isTournament(check));
};

const isWorld = (check) => {
  return {
    type: ISWORLD,
    payload: check,
  };
};

export const isWorldAsync = (check) => (dispatch) => {
  dispatch(isWorld(check));
};

const isContinental = (check) => {
  return {
    type: ISCONTINENTAL,
    payload: check,
  };
};

export const isContinentalAsync = (check) => (dispatch) => {
  dispatch(isContinental(check));
};

const isCountry = (check) => {
  return {
    type: ISCOUNTRY,
    payload: check,
  };
};

export const isCountryAsync = (check) => (dispatch) => {
  dispatch(isCountry(check));
};

const isCity = (check) => {
  return {
    type: ISCITY,
    payload: check,
  };
};

export const isCityAsync = (check) => (dispatch) => {
  dispatch(isCity(check));
};

const isEvent = (check) => {
  return {
    type: ISEVENT,
    payload: check,
  };
};

export const isEventAsync = (check) => (dispatch) => {
  dispatch(isEvent(check));
};

const isSpecifiedTourType = (check) => {
  return {
    type: ISSPECIFIEDTOURTYPE,
    payload: check,
  };
};

export const isSpecifiedTourTypeAsync = (check) => (dispatch) => {
  dispatch(isSpecifiedTourType(check));
};
