import React, { useState, useEffect } from "react";
import "./SigninPlace.css";
import createGameBackground from "../../../Audio/createGameBackground.mp3";
import BackGroundMusic from "../../MusicAndSounds/BackGroundMusic";
import ProfileBackground from "../User/ProfileBackground/ProfileBackground";
import SigninForm from "./SigninForm";
import LogoutCompletedMessage from "../Logout/LogoutCompletedMessage";
import DeleteCompletedMessage from "../DeleteAccount/DeleteCompletedMessage";
import ForgetPasswordCompletedMessage from "../ForgetPassword/ForgetPasswordCompletedMessage";
import usePageAccessType from "../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function SigninPlace({ hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const [isHidden, setIsHidden] = useState("SigninPlace");

  // is clicked hamburger
  useEffect(() => {
    !hamburgerCondition ? setIsHidden("SigninPlace") : setIsHidden("hidden");
  }, [hamburgerCondition]);
  // is clicked hamburger

  return (
    <div className={isHidden}>
      {!hamburgerCondition ? (
        <BackGroundMusic
          Audiodata={[
            createGameBackground,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <ProfileBackground />

      <SigninForm />

      <LogoutCompletedMessage />

      <DeleteCompletedMessage />

      <ForgetPasswordCompletedMessage />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninPlace);
