import React, { useState, useEffect, Fragment } from "react";
import "./AllMultiRooms.css";
import "./AllMultiRoomsAnimation.css";
import leftArrow from "../../../../Images/leftArrow.png";
import rightArrow from "../../../../Images/rightArrow.png";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import enterRoomSound from "../../../../Audio/enterRoomSound.mp3";
import popUpSound from "../../../../Audio/popUp.mp3";
import NoAvailableRoom from "../../NoAvailableRoom/NoAvailableRoom";
import MultiRoomDisable from "../MultiRoomDisable/MultiRoomDisable";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AllMultiRoomsContainer

// this component is recieving data from backEnd

function AllMultiRooms({
  isPlayerRoomAvailable,
  authStateCondition,
  getAllRoomsAsync,
  authRoomStateCondition,
  getRoomByIdAsync,
  isCountDownTimerZeroAsync,
}) {
  const navigate = useNavigate();
  let location = useLocation();
  let exactLocation = location.pathname.split("/").length;

  const { rooms } = authRoomStateCondition;
  const { user } = authStateCondition;

  const userId = user !== undefined && user !== null ? user._id : undefined;

  const [disableEnter, setDisableEnter] = useState(false);

  // get data when the page is opened
  useEffect(() => {
    getAllRoomsAsync();
  }, [getAllRoomsAsync, location.pathname]);
  // get data when the page is opened

  // refresh data every 5 seconds for all rooms
  useEffect(() => {
    if (exactLocation === 2) {
      const timer = setInterval(() => {
        getAllRoomsAsync();
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [getAllRoomsAsync, exactLocation]);
  // refresh data every 5 seconds for all rooms

  const [allMultiPlayerRooms, setAllMultiPlayerRooms] = useState([]);

  const [hexTitle, setHexTitle] = useState("");

  const [playOrWatch, setPlayOrWatch] = useState("");

  const [startIndex, setStartIndex] = useState(0);

  const [available, setAvailable] = useState("");

  useEffect(() => {
    const { rooms } = authRoomStateCondition;

    const multiPlayerRooms = !("message" in rooms)
      ? rooms.filter((room) => room.gameType === "multi-player")
      : null;

    if (Array.isArray(multiPlayerRooms)) {
      setAllMultiPlayerRooms(multiPlayerRooms);
    } else {
      setAllMultiPlayerRooms([]);
    }
  }, [authRoomStateCondition, location.pathname]);

  useEffect(() => {
    isPlayerRoomAvailable ? setAvailable(true) : setAvailable(false);

    setStartIndex(0);
  }, [isPlayerRoomAvailable]);

  useEffect(() => {
    // const { user } = authStateCondition;

    if (user === undefined || user === null || user === "") {
      setHexTitle("Watch");

      setPlayOrWatch("Enter the room to watch others game");
    } else {
      setHexTitle("Enter");

      setPlayOrWatch("Enter the room to play or watch others game");
    }
  }, [user]);

  const availableMultiPlayerRooms = allMultiPlayerRooms.filter(
    (rooms) => rooms.players.length % 2 !== 0 && rooms,
  );

  const changePage = (e) => {
    const roomsQuantity = !available
      ? allMultiPlayerRooms.length
      : availableMultiPlayerRooms.length;

    const roomsPerPage = 8;

    //play click
    ClickSound(popUpSound);

    if (e.target.name === "next") {
      setStartIndex((prevPage) =>
        prevPage + 1 + roomsPerPage <= roomsQuantity ? prevPage + 8 : prevPage,
      );
    } else if (e.target.name === "previous") {
      setStartIndex((prevPage) => prevPage > 0 && prevPage - 8);
    }
  };

  const handleRoomBtn = (e) => {
    const id = e.target.name;

    //play click
    ClickSound(enterRoomSound);

    const AlreadyPlayerInRoom = rooms.filter((room) =>
      room.players.some((player) => player.id === userId),
    );

    getRoomByIdAsync(id);

    isCountDownTimerZeroAsync(false);

    if (AlreadyPlayerInRoom.length === 0) {
      setDisableEnter(false);

      navigate(`/multi-player/rooms/${id}`);
    } else {
      if (AlreadyPlayerInRoom[0]._id === id) {
        setDisableEnter(false);

        navigate(`/multi-player/rooms/${id}`);
      } else {
        setDisableEnter(true);
      }
    }
  };

  return (
    <Fragment>
      <div className="multiRooms-sideArrow">
        <div className="multiRooms-sideArrow-left">
          <img
            className="multiRooms-sideArrow-img"
            src={leftArrow}
            name="previous"
            title="previous page"
            alt="left page"
            onClick={changePage}
          />
        </div>

        <div className="multiRooms-sideArrow-right">
          <img
            className="multiRooms-sideArrow-img"
            src={rightArrow}
            name="next"
            title="next page"
            alt="right page"
            onClick={changePage}
          />
        </div>
      </div>

      <MultiRoomDisable disableEnter={disableEnter} />

      {allMultiPlayerRooms.length === 0 && <NoAvailableRoom />}

      {(!available ? allMultiPlayerRooms : availableMultiPlayerRooms)
        .map((multiRoom, i) => {
          return (
            <div className="everyMultiRoom" key={i}>
              <div
                className="everyMultiRoom-numberplace"
                title={`Multi Player Room ${multiRoom.roomNumber}`}
                alt="Multi Player"
              >
                <h5 className="everyMultiRoom-number">
                  Room - {multiRoom._id.slice(-6).toUpperCase()}{" "}
                  {multiRoom.passCode === "" ? <>&#x1F513;</> : <>&#x1F512;</>}
                </h5>
              </div>
              <div className="everyMultiRoom-hex"></div>
              <div
                className={
                  multiRoom.players.length === 4
                    ? "everyMultiRoom-notAvailable"
                    : "everyMultiRoom-available"
                }
                title={
                  multiRoom.players.length === 4
                    ? "unavailable to play, enter room to watch others game"
                    : "available to play"
                }
              ></div>
              <div className="everyMultiRoom-btn-place">
                <button
                  className="everyMultiRoom-btn-place_button"
                  name={multiRoom._id}
                  title={playOrWatch}
                  onClick={handleRoomBtn}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                    ></path>
                  </svg>
                  <div className="everyMultiRoom-btn-place_button_text">
                    {hexTitle}
                  </div>
                </button>
              </div>
            </div>
          );
        })
        .reverse()
        .slice(startIndex, startIndex + 8)}
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AllMultiRooms);
