import React, { useEffect, useState } from "react";
import "./CountryCup.css";
import "./CountryCupAnimation.css";
import Temporary from "../../Temporary/Temporary";
import LoaderDots from "../../../Loading/LoaderDots";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

// parent component is - App

function Country() {
  const routeParams = useParams();

  const [eventType, setEvenetType] = useState("");

  useEffect(() => {
    if (routeParams.id === "argentina") {
      setEvenetType("Argentina");
    } else if (routeParams.id === "france") {
      setEvenetType("France");
    } else if (routeParams.id === "iran") {
      setEvenetType("Iran");
    } else {
      setEvenetType("Argentina");
    }
  }, [eventType, routeParams.id]);

  return (
    <div className="singleCountry">
      <div className="singleCountry_selected">{eventType}</div>

      <div className="singleCountry_selected_loading">
        <LoaderDots />
      </div>

      <Temporary />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Country);
