const ISCLICKEDLOGOUTWINDOW = "ISCLICKEDLOGOUTWINDOW";

const isClickedLogoutWindow = (check) => {
  return {
    type: ISCLICKEDLOGOUTWINDOW,
    payload: check,
  };
};

export const isClickedLogoutWindowAsync = (check) => (dispatch) => {
  dispatch(isClickedLogoutWindow(check));
};
