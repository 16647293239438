const initialSocialState = {
  isZero: false,
};

const countDownReducer = (state = initialSocialState, action) => {
  switch (action.type) {
    case "ISCOUNTDOWNZERO":
      return {
        ...state,
        isZero: action.payload,
      };

    default:
      return state;
  }
};

export default countDownReducer;
