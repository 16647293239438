import React, { useEffect, useState } from "react";
import "./PlayOrWatchArea.css";
import "./PlayOrWatchAreaAnimation.css";
import playOrWatchBackgroundSound from "../../../../Audio/playOrWatchBackground.mp3";
import BackGroundMusic from "../../../MusicAndSounds/BackGroundMusic";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import enterRoomSound from "../../../../Audio/enterRoomSound.mp3";
import LoaderSimple from "../../../Loading/LoaderSimple";
import PlayOrWatchSettingsAndExitBtns from "./PlayOrWatchSettingsAndExitBtns";
import Radar from "./Radar/Radar";
import LockedRoom from "./LockedRoom/LockedRoom";
import Explanation from "./Explanation/Explanation";
import usePageAccessType from "../../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - SingleRoom

function PlayOrWatchArea({
  isWatchingTheGameAsync,
  roomData,
  editRoomAsync,
  getSingleRoomCondition,
  isWatchingGameCondition,
  isCountDownTimerZeroAsync,
  hamburgerCondition,
}) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  // roomData is coming from parent component - SingleRoom
  const [
    roomNumber,
    roomId,
    userId,
    userName,
    exactLocation,
    ,
    ,
    thisRoomPlayerIds,
    ,
    thisRoom,
    user,
  ] = roomData;
  // roomData is coming from parent component - SingleRoom

  const [lockedRoomPassCode, setLockedRoomPassCode] = useState("");
  const [oops, setOops] = useState("hidden");

  // If the room is locked , show passCode, else Radar
  const [showRadar, setShowRadar] = useState(true);

  useEffect(() => {
    if (thisRoom !== undefined) {
      if (thisRoom.passCode === "") {
        setShowRadar(true);
      } else {
        setShowRadar(false);

        setOops("hidden");
      }
    } else {
      setShowRadar(true);
    }
  }, [thisRoom]);
  // If the room is locked , show passCode, else Radar

  // user options, watch or play
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (thisRoom !== undefined) {
      if (user !== null && user !== undefined && user !== "") {
        let checkIfTheGameStarted;

        if (getSingleRoomCondition.isSuccess) {
          checkIfTheGameStarted = !("message" in getSingleRoomCondition.room)
            ? getSingleRoomCondition.room.whosTurn.gameStarted
            : null;
        }

        if (
          thisRoomPlayerIds.includes(userId) ||
          checkIfTheGameStarted ||
          isWatchingGameCondition !== ""
        ) {
          setHidden(true);
        } else {
          setHidden(false);
        }
      } else {
        setHidden(true);
      }
    }
  }, [
    user,
    userId,
    thisRoom,
    thisRoomPlayerIds,
    getSingleRoomCondition,
    isWatchingGameCondition,
  ]);
  // user options, watch or play

  const handlePassCode = (passCode) => {
    setLockedRoomPassCode(passCode);
  };

  const handlePlayOrWatch = (e) => {
    //play click
    ClickSound(enterRoomSound);

    isCountDownTimerZeroAsync(false);

    const addPlayer = {
      players: {
        playerId: userId,
        playerName: userName,
      },
    };

    if (e.target.name === "watch") {
      isWatchingTheGameAsync("watch");
    } else if (e.target.name === "play") {
      if (thisRoom.passCode === "") {
        isWatchingTheGameAsync("play");

        editRoomAsync(roomId, addPlayer);
      } else {
        if (thisRoom.passCode === lockedRoomPassCode) {
          isWatchingTheGameAsync("play");

          editRoomAsync(roomId, addPlayer);
        } else {
          setOops("PlayOrWatchArea-oops");
        }
      }
    }
  };

  return !hidden ? (
    <div className="PlayOrWatchArea">
      {!hamburgerCondition ? (
        <BackGroundMusic
          Audiodata={[
            playOrWatchBackgroundSound,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <div className="PlayOrWatchArea-loading">
        <LoaderSimple />
      </div>

      <div className="PlayOrWatchArea-198">
        <div className="PlayOrWatchArea-198-bg"> 198 Runners </div>
        <div className="PlayOrWatchArea-198-fg"> 198 Runners </div>
      </div>

      <div className="PlayOrWatchArea-container">
        <div className="PlayOrWatchArea-data" title="Room Number">
          <h2>Room - {roomNumber}</h2>
        </div>

        {showRadar ? <Radar /> : <LockedRoom handlePassCode={handlePassCode} />}

        <div className={oops}>
          <h3>Oops, try again !!!</h3>
        </div>

        <div className="PlayOrWatchArea-btn-place">
          <button
            className="PlayOrWatchArea-btn-place_button"
            name="play"
            title="play"
            onClick={handlePlayOrWatch}
          >
            <svg
              className="svg-icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M852.727563 392.447107C956.997809 458.473635 956.941389 565.559517 852.727563 631.55032L281.888889 993.019655C177.618644 1059.046186 93.090909 1016.054114 93.090909 897.137364L93.090909 126.860063C93.090909 7.879206 177.675064-35.013033 281.888889 30.977769L852.727563 392.447107 852.727563 392.447107Z"
                fill="#E77000"
              />
            </svg>
            <div className="PlayOrWatchArea-btn-place_button_text">Play</div>
          </button>
          <button
            className="PlayOrWatchArea-btn-place_button"
            name="watch"
            title="watch"
            onClick={handlePlayOrWatch}
          >
            <svg
              className="svg-icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M832 96c0-17.68-14.32-32-32-32h-128c-17.68 0-32 14.32-32 32v96h192V96zM127.82 319.98C122.8 507.68 6.92 548.44 0 808v88c0 35.34 28.66 64 64 64h192c35.34 0 64-28.66 64-64V576h64V256H191.68c-35.26 0-62.9 28.74-63.86 63.98z m768.36 0c-0.96-35.24-28.6-63.98-63.86-63.98H640v320h64v320c0 35.34 28.66 64 64 64h192c35.34 0 64-28.66 64-64v-88c-6.92-259.56-122.8-300.32-127.82-488.02zM352 64h-128c-17.68 0-32 14.32-32 32v96h192V96c0-17.68-14.32-32-32-32z m96 512h128V256h-128v320z"
                fill="#E77000"
              />
            </svg>
            <div className="PlayOrWatchArea-btn-place_button_text">Watch</div>
          </button>
        </div>

        <Explanation />
      </div>

      <PlayOrWatchSettingsAndExitBtns exactLocation={exactLocation} />
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayOrWatchArea);
