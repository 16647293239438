import React, { useEffect, useState } from "react";
import "./OpponentTurnPause.css";
import "./OpponentTurnPauseAnimation.css";
import blueCircle from "../../../../../Images/blueCircle.png";
import LoaderDots from "../../../../Loading/LoaderDots";
import OpponentTurnUserPlace from "./OpponentTurnUserPlace/OpponentTurnUserPlace";

// parent component is - RoomGameNumbers

function OpponentTurnPauseSecondLayer({ thisRoomWhosTurn }) {
  const [currentScore, setCurrentScore] = useState("");

  useEffect(() => {
    if (thisRoomWhosTurn !== undefined) {
      if (thisRoomWhosTurn.currentNumber !== 0) {
        setCurrentScore(thisRoomWhosTurn.currentNumber);
      } else {
        setCurrentScore("");
      }
    } else {
      setCurrentScore("");
    }
  }, [thisRoomWhosTurn]);

  return (
    <div className="OpponentTurnPause-secondLayer">
      <div className="OpponentTurnPause-wait"></div>

      <div className="OpponentTurnPause-secondLayer-scoreBgr">
        <img
          className="OpponentTurnPause-secondLayer-scoreBgr-img"
          src={blueCircle}
          title="Total Score"
          alt="Total Score"
        />
      </div>

      <div className="OpponentTurnPause-secondLayer-score">
        <h3 className="OpponentTurnPause-secondLayer-score-title">
          {currentScore}
        </h3>
      </div>

      <div className="OpponentTurnPause-secondLayer-loading">
        <LoaderDots />
      </div>
      <OpponentTurnUserPlace />
    </div>
  );
}

export default OpponentTurnPauseSecondLayer;
