import "../style/App.css";
import "../style/Animations.css";
import { Routes, Route } from "react-router-dom";
import LeftSide from "./Advertisement/LeftSide";
import RightSide from "./Advertisement/RightSide";
import HeaderGeneral from "../components/Header/Header/HeaderGeneral";
import HeaderGame from "./Header/Header/HeaderGame";
import General from "./General/General/General";
import SigninPlace from "./Profile/Signin/SigninPlace";
import RegistrationPlace from "./Profile/Registration/RegistrationPlace";
import ForgetPasswordPlace from "./Profile/ForgetPassword/ForgetPasswordPlace";
import UserProfile from "./Profile/User/UserProfile/UserProfile";
import NavPlace from "./Navigation/NavPlace/NavPlace";
import SinglePlayerGame from "./GameArea/Single/SinglePlayerGame/SinglePlayerGame";
import MultiPlayerGame from "./GameArea/Multi/MultiPlayerGame/MultiPlayerGame";
import TournamentsAreaContainer from "./GameArea/Tournaments/TournamentsAreaContainer/TournamentsAreaContainer";
import TournamentsArea from "./GameArea/Tournaments/TournamentsArea/TournamentsArea";
import WorldCup from "./GameArea/Tournaments/WorldCup/WorldCup";
import ContinentalCup from "./GameArea/Tournaments/Continent/ContinentalCup";
import Continent from "./GameArea/Tournaments/Continent/Continent";
import CountryCup from "./GameArea/Tournaments/Country/CountryCup";
import Country from "./GameArea/Tournaments/Country/Country";
import CityCup from "./GameArea/Tournaments/City/CityCup";
import City from "./GameArea/Tournaments/City/City";
import EventCup from "./GameArea/Tournaments/Event/EventCup";
import Event from "./GameArea/Tournaments/Event/Event";
import SingleRoomContainer from "./GameArea/Single/SingleRoomContainer/SingleRoomContainer";
import SingleRoom from "./GameArea/Single/SingleRoom/SingleRoom";
import MultiRoomContainer from "./GameArea/Multi/MultiRoomContainer/MultiRoomContainer";
import MultiRoom from "./GameArea/Multi/MultiRoom/MultiRoom";
import CreateRoomContainer from "./GameArea/CreateRoomArea/CreateRoomContainer/CreateRoomContainer";
import Runners from "./General/Runners/Runners";
import FooterGeneral from "./Footer/FooterGeneral";
import { mapStateToProps, mapDispatchToProps } from "../State/Store/storeProps";
import { connect } from "react-redux";

function App() {
  return (
    <div className="App">
      <LeftSide />

      <div className="Main">
        <HeaderGeneral />

        <HeaderGame />

        <Routes>
          <Route path="/" element={<General />} />

          <Route path="/login" exact element={<SigninPlace />} />

          <Route path="/join" exact element={<RegistrationPlace />} />

          <Route
            path="/forget-password"
            exact
            element={<ForgetPasswordPlace />}
          />

          <Route path="/profile" element={<UserProfile />} />

          <Route path="/single-player" exact element={<SinglePlayerGame />}>
            <Route
              path="/single-player/rooms"
              exact
              element={<SingleRoomContainer />}
            >
              <Route
                path="/single-player/rooms/:id"
                exact
                element={<SingleRoom />}
              />
            </Route>

            <Route
              path="/single-player/create"
              exact
              element={<CreateRoomContainer />}
            />
          </Route>

          <Route path="/multi-player" exact element={<MultiPlayerGame />}>
            <Route
              path="/multi-player/rooms"
              exact
              element={<MultiRoomContainer />}
            >
              <Route
                path="/multi-player/rooms/:id"
                exact
                element={<MultiRoom />}
              />
            </Route>

            <Route
              path="/multi-player/create"
              exact
              element={<CreateRoomContainer />}
            />
          </Route>

          <Route
            path="/tournaments"
            exact
            element={<TournamentsAreaContainer />}
          >
            <Route
              path="/tournaments/quote"
              exact
              element={<TournamentsArea />}
            />
            <Route path="/tournaments/world-cup" exact element={<WorldCup />} />

            <Route
              path="/tournaments/coninental-cup"
              exact
              element={<ContinentalCup />}
            >
              <Route
                path="/tournaments/coninental-cup/:id"
                exact
                element={<Continent />}
              />
            </Route>

            <Route
              path="/tournaments/country-cup"
              exact
              element={<CountryCup />}
            >
              <Route
                path="/tournaments/country-cup/:id"
                exact
                element={<Country />}
              />
            </Route>

            <Route path="/tournaments/city-cup" exact element={<CityCup />}>
              <Route
                path="/tournaments/city-cup/:id"
                exact
                element={<City />}
              />
            </Route>

            <Route path="/tournaments/event-cup" exact element={<EventCup />}>
              <Route
                path="/tournaments/event-cup/:id"
                exact
                element={<Event />}
              />
            </Route>
          </Route>

          <Route path="/198Runners" exact element={<Runners />} />

          <Route path="*" exact element={<General />} />
        </Routes>

        <NavPlace />

        <FooterGeneral />
      </div>

      <RightSide />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
