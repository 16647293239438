import React from "react";
import "./AvatarBonusDollarBag.css";
import "./AvatarBonusDollarBagAnimation.css";
import dollars from "../../../../Images/dollars.png";

// parent component is - AvatarBonusCard

function AvatarBonusDollarBag() {
  return (
    <div className="AvatarBonusDollarBag">
      <img id="AvatarBonusDollar" src={dollars} alt="dollars" />
    </div>
  );
}

export default AvatarBonusDollarBag;
