import React, { useState, useEffect } from "react";
import checked from "../../../Images/checedBtn.png";
import errorBtn from "../../../Images/errorBtn.png";
import LoaderSimple from "../../Loading/LoaderSimple";
import "../CompletedFeatures.css";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// parent component is - RegistrationForm

function RegistrationCompletedFeatures({ authStateCondition, resetAsync }) {
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = authStateCondition;

  const [completed, setCompleted] = useState("hidden");

  const [spinner, setSpinner] = useState("hidden");

  const [checkedBtn, setCheckedBtn] = useState("hidden");

  const [checedBtnImg, setCheckedBtnImg] = useState(checked);

  const [checedBtnImgText, setCheckedBtnImgText] = useState("");

  const [text, setText] = useState("hidden");

  const [phrase, setPhrase] = useState("");

  const [login, setLogin] = useState("hidden");

  const [btnText, setBtnText] = useState("");

  const [tryAgain, setTryAgain] = useState("");

  useEffect(() => {
    if (isLoading === true) {
      setCompleted("completed_successfuly");

      setSpinner("completed_successfuly_loading");
    } else if (isSuccess === true) {
      setSpinner("hidden");

      setCheckedBtn("completed_successfuly_checked");

      setCheckedBtnImg(checked);

      setCheckedBtnImgText("successfully registered");

      setText("completed_successfuly_message");

      setPhrase("Account has been successfully created");

      setLogin("completed_successfuly_btn");

      setBtnText("Ok");

      setTryAgain("Account has been successfully created");
    } else if (isError === true) {
      setSpinner("hidden");

      setCheckedBtn("completed_successfuly_checked");

      setCheckedBtnImg(errorBtn);

      setCheckedBtnImgText("oops");

      setText("completed_successfuly_message");

      setPhrase("oops, user already exists");

      setLogin("completed_successfuly_btn");

      setBtnText("Try Again");

      setTryAgain("please, try again");
    }

    return resetAsync();
  }, [user, isError, isSuccess, isLoading, message, resetAsync, navigate]);

  const handleRegistration = (e) => {
    const btnName = e.target.name;

    btnName === "Ok" ? navigate("/") : navigate(0);
  };

  return (
    <div className={completed}>
      <div className={checkedBtn}>
        <img
          className="completed_successfuly_checked_img"
          src={checedBtnImg}
          title={checedBtnImgText}
          alt="check"
        />
      </div>

      <div className={spinner}>
        <LoaderSimple />
      </div>

      <div className={text}>{phrase}</div>

      <div className={login} title={tryAgain}>
        <button
          className="submit_BTN"
          onClick={handleRegistration}
          name={btnText}
        >
          {btnText}
        </button>
      </div>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationCompletedFeatures);
