import React, { useEffect, useState } from "react";
import "./AvatarFeatures.css";
import ClickSound from "../../../MusicAndSounds/ClickSound";
import userProfileFeaturesSound from "../../../../Audio/userProfileFeatures.mp3";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - UserProfile

function AvatarFeatures({
  isClickedLogoutWindowAsync,
  selectedClickedAvatarFeaturesAsync,
  isSelectedEditTypeAsync,
  isEditCompletedAsync,
  isEditCompletedCondition,
  logOutWindowCondition,
  deleteWindowCondition,
}) {
  // when user changed username, email or password make hidden user profile buttons
  const [hidden, setHidden] = useState("AvatarFeatures");

  useEffect(() => {
    isEditCompletedCondition === ""
      ? setHidden("AvatarFeatures")
      : setHidden("hidden");
  }, [isEditCompletedCondition]);
  // when user changed username, email or password make hidden user profile buttons

  const handleClick = (e) => {
    let feature = e.target.name;

    //play click
    ClickSound(userProfileFeaturesSound);

    isEditCompletedAsync("");

    if (feature === "coin") {
      selectedClickedAvatarFeaturesAsync("coin");

      isSelectedEditTypeAsync("");
    } else if (feature === "edit") {
      selectedClickedAvatarFeaturesAsync("edit");

      isSelectedEditTypeAsync("");
    } else if (feature === "logout") {
      isClickedLogoutWindowAsync(true);

      isSelectedEditTypeAsync("");
    } else {
      selectedClickedAvatarFeaturesAsync("coin");

      isSelectedEditTypeAsync("");
    }
  };

  return (
    <div className={hidden}>
      <button
        className={
          deleteWindowCondition || logOutWindowCondition
            ? "AvatarFeatureItems-btn-coin disable-link"
            : "AvatarFeatureItems-btn-coin"
        }
        name="coin"
        onClick={handleClick}
      >
        <div className="AvatarFeatureItems-btn-coin-sign">
          <svg version="1.1" viewBox="0 0 512 512">
            <g>
              <g>
                <path
                  d="M256,0C114.842,0,0,114.842,0,256s114.842,256,256,256s256-114.842,256-256S397.158,0,256,0z M256,467.478
			        C139.39,467.478,44.522,372.61,44.522,256S139.39,44.522,256,44.522S467.478,139.39,467.478,256S372.61,467.478,256,467.478z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M256,72.348C154.739,72.348,72.348,154.739,72.348,256S154.739,439.652,256,439.652S439.652,357.261,439.652,256
            S357.261,72.348,256,72.348z M263.805,241.239c23.794,12.576,53.413,28.239,53.413,59.283c0,27.956-18.869,51.548-44.522,58.842
            v7.94c0,9.217-7.479,16.696-16.696,16.696c-9.217,0-16.696-7.479-16.696-16.696v-7.94c-25.652-7.294-44.522-30.886-44.522-58.842
            c0-9.217,7.479-16.696,16.696-16.696s16.696,7.479,16.696,16.696c0,15.348,12.478,27.826,27.826,27.826
            s27.826-12.478,27.826-27.826c0-10.935-18.109-20.5-35.631-29.761c-23.794-12.576-53.413-28.239-53.413-59.283
            c0-27.956,18.869-51.548,44.522-58.842v-7.94c0-9.217,7.479-16.696,16.696-16.696c9.217,0,16.696,7.479,16.696,16.696v7.94
            c25.652,7.294,44.522,30.886,44.522,58.842c0,9.217-7.479,16.696-16.696,16.696s-16.696-7.479-16.696-16.696
            c0-15.348-12.478-27.826-27.826-27.826s-27.826,12.478-27.826,27.826C228.174,222.413,246.283,231.978,263.805,241.239z"
                />
              </g>
            </g>
          </svg>
        </div>

        <div className="AvatarFeatureItems-btn-coin-text">Coin</div>
      </button>

      <button
        className={
          deleteWindowCondition || logOutWindowCondition
            ? "AvatarFeatureItems-btn-edit disable-link"
            : "AvatarFeatureItems-btn-edit"
        }
        name="edit"
        onClick={handleClick}
      >
        <div className="AvatarFeatureItems-btn-edit-sign">
          <svg viewBox="0 -0.5 21 21" version="1.1">
            <g>
              <g transform="translate(-419.000000, -359.000000)">
                <g transform="translate(56.000000, 160.000000)">
                  <path d="M384,209.210475 L384,219 L363,219 L363,199.42095 L373.5,199.42095 L373.5,201.378855 L365.1,201.378855 L365.1,217.042095 L381.9,217.042095 L381.9,209.210475 L384,209.210475 Z M370.35,209.51395 L378.7731,201.64513 L380.4048,203.643172 L371.88195,212.147332 L370.35,212.147332 L370.35,209.51395 Z M368.25,214.105237 L372.7818,214.105237 L383.18415,203.64513 L378.8298,199 L368.25,208.687714 L368.25,214.105237 Z"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="AvatarFeatureItems-btn-edit-text">Edit</div>
      </button>

      <button
        className={
          deleteWindowCondition || logOutWindowCondition
            ? "AvatarFeatureItems-btn-logout disable-link"
            : "AvatarFeatureItems-btn-logout"
        }
        name="logout"
        onClick={handleClick}
      >
        <div className="AvatarFeatureItems-btn-logout-sign">
          <svg viewBox="0 0 512 512">
            <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
          </svg>
        </div>

        <div className="AvatarFeatureItems-btn-logout-text">Log out</div>
      </button>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarFeatures);
