import React from "react";
import "./MenuButtons.css";
import "./MenuButtonsAnimation.css";
import ClickSound from "../../MusicAndSounds/ClickSound";
import menuButtonsSound from "../../../Audio/menuButtons.mp3";
import { Link } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - AllButtons

function MultiPlayerButton({
  isSingleUserAsync,
  isMultiPlayerAsync,
  isTournamentAsync,
  isWorldAsync,
  isContinentalAsync,
  isCountryAsync,
  isCityAsync,
  isEventAsync,
}) {
  // clicked multi player
  const handleClick = () => {
    isSingleUserAsync(false);
    isMultiPlayerAsync(true);
    isTournamentAsync(false);
    isWorldAsync(false);
    isContinentalAsync(false);
    isCountryAsync(false);
    isCityAsync(false);
    isEventAsync(false);

    //play click
    ClickSound(menuButtonsSound);
  };
  // clicked multi player

  return (
    <div
      className="btn_multiplayer"
      title="Multiplayer | Blitz Games"
      onClick={handleClick}
    >
      <div className="btn_general">
        <Link to="/multi-player">2 vs 2 Battle</Link>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiPlayerButton);
