import React, { useEffect, useState } from "react";
import "./SingleRoom.css";
import LoaderSimple from "../../../Loading/LoaderSimple";
import PlayOrWatchArea from "../../InRoomArea/PlayOrWatchArea/PlayOrWatchArea";
import GameWatchingContainer from "../../InRoomArea/GameWatchingContainer/GameWatchingContainer";
import RoomGameNumbers from "../../InRoomArea/RoomGameNumbers/RoomGameNumbers";
import GameOver from "../../InRoomArea/GameOver/GameOver";
import { useNavigate, useLocation } from "react-router-dom";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function SingleRoom({
  isWatchingTheGameAsync,
  getRoomByIdAsync,
  getSingleRoomCondition,
  resetRoomAsync,
  authStateCondition,
}) {
  // get user data from local storage, just for show data all over single room //PlayOrWatchArea // GameWatchingContainer // RoomGameNumbers
  const { user } = authStateCondition;

  const location = useLocation();
  const exactLocation = location.pathname.split("/")[1];
  const roomId = location.pathname.split("/")[3];
  const navigate = useNavigate();

  const [loading, setLoading] = useState("hidden");

  // get user id from local storage and check
  const [userId, setuserId] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (user) {
      if (user._id.length === 24 && typeof user._id === "string") {
        setuserId(user._id);

        setUserName(user.name);
      } else {
        setuserId("");

        setUserName("");
      }
    } else {
      setuserId("");
      setUserName("");
    }
  }, [user]);
  // get user id from local storage and check
  // get user data from local storage, just for show data all over single room //PlayOrWatchArea // GameWatchingContainer // RoomGameNumbers

  // get room data when the page is opened
  useEffect(() => {
    isWatchingTheGameAsync("");

    getRoomByIdAsync(roomId);
  }, [isWatchingTheGameAsync, getRoomByIdAsync, roomId]);
  // get room data when the page is opened

  // set room title
  const [roomNumber, setRoomNumber] = useState("");
  // set room title

  useEffect(() => {
    const timer = setInterval(() => {
      getRoomByIdAsync(roomId);
    }, 5000);

    return () => clearInterval(timer);
  }, [getRoomByIdAsync, roomId]);

  const [thisRoom, setThisRoom] = useState(undefined);
  const [thisRoomPlayersCount, setThisRoomPlayersCount] = useState(undefined);
  const [thisRoomPlayers, setThisRoomPlayers] = useState(undefined);
  const [thisRoomPlayerIds, setThisRoomPlayerIds] = useState(undefined);
  const [thisRoomWhosTurn, setThisRoomWhosTurn] = useState(undefined);

  useEffect(() => {
    const { room, isError, isSuccess, isLoading, message } =
      getSingleRoomCondition;

    setRoomNumber(roomId.slice(-6).toUpperCase());

    if (room !== undefined && isSuccess && !("message" in room)) {
      setThisRoom(room);
      setThisRoomPlayersCount(room.players.length);
      setThisRoomPlayers(room.players);
      setThisRoomPlayerIds(room.players.map((player) => player.id));
      setThisRoomWhosTurn(room.whosTurn);

      setLoading("hidden");
    } else if (isError || message !== "") {
      setThisRoom(undefined);
      setThisRoomPlayersCount(undefined);
      setThisRoomPlayers(undefined);
      setThisRoomPlayerIds(undefined);
      setThisRoomWhosTurn(undefined);
      resetRoomAsync();

      setLoading("hidden");

      navigate("/single-player");
    } else if (!isSuccess && isLoading) {
      setLoading("InRoomSingle-loading");
    } else {
      setThisRoom(undefined);
      setThisRoomPlayersCount(undefined);
      setThisRoomPlayers(undefined);
      setThisRoomPlayerIds(undefined);
      setThisRoomWhosTurn(undefined);

      setLoading("InRoomSingle-loading");
    }
  }, [getSingleRoomCondition, exactLocation, navigate, resetRoomAsync, roomId]);

  const roomData = [
    roomNumber,
    roomId,
    userId,
    userName,
    exactLocation,
    thisRoomPlayersCount,
    thisRoomPlayers,
    thisRoomPlayerIds,
    thisRoomWhosTurn,
    thisRoom,
    user,
  ];

  return (
    <div className="InRoomSingle">
      {/* Backend loading */}
      <div className={loading}>
        <LoaderSimple />
      </div>
      {/* Backend loading */}

      <PlayOrWatchArea roomData={roomData} />

      <GameWatchingContainer roomData={roomData} />

      <RoomGameNumbers roomData={roomData} />

      <GameOver roomData={roomData} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleRoom);
