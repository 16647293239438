import React from "react";
import "./Radar.css";
import "./RadarAnimation.css";

// parent component is - PlayOrWatchArea

function Radar() {
  return (
    <div className="Radar">
      <div className="Radar-circle"></div>
      <div className="Radar-dot1"></div>
      <div className="Radar-dot2"></div>
      <div className="Radar-dot3"></div>
      <div className="Radar-dot4"></div>
    </div>
  );
}

export default Radar;
