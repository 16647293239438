const EXITROOM = "EXITROOM";

const isExitRoom = (check) => {
  return {
    type: EXITROOM,
    payload: check,
  };
};

export const isExitRoomAsync = (check) => (dispatch) => {
  dispatch(isExitRoom(check));
};
