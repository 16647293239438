import React, { useState, useEffect } from "react";
import "./RegistrationPlace.css";
import createGameBackground from "../../../Audio/createGameBackground.mp3";
import BackGroundMusic from "../../MusicAndSounds/BackGroundMusic";
import ProfileBackground from "../User/ProfileBackground/ProfileBackground";
import RegistrationForm from "./RegistrationForm";
import usePageAccessType from "../../usePageAccessType/usePageAccessType";
import {
  mapStateToProps,
  mapDispatchToProps,
} from "../../../State/Store/storeProps";
import { connect } from "react-redux";

// parent component is - App

function RegistrationPlace({ hamburgerCondition }) {
  // page access type
  const { isPageRefreshed, isDirectAccess, isExternalNavigation } =
    usePageAccessType();
  // page access type

  const [isHidden, setIsHidden] = useState("RegistrationPlace");

  //is clicked hamburger
  useEffect(() => {
    !hamburgerCondition
      ? setIsHidden("RegistrationPlace")
      : setIsHidden("hidden");
  }, [hamburgerCondition]);
  //is clicked hamburger

  return (
    <div className={isHidden}>
      {!hamburgerCondition ? (
        <BackGroundMusic
          Audiodata={[
            createGameBackground,
            isPageRefreshed,
            isDirectAccess,
            isExternalNavigation,
          ]}
        />
      ) : null}

      <ProfileBackground />

      <RegistrationForm />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPlace);
